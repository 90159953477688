import React, {
  FC, useState, ChangeEvent, MutableRefObject, useMemo, useEffect,
} from 'react';
import {
  Select, MenuItem, TextField, InputAdornment,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import useStyles from './styles';

interface IProps {
  keyItem: string;
  inputItem: string;
  handleInputChange: (value: string, key: string) => void;
  selectItem: string;
  handleSelectChange: (value: string, key: string) => void;
  handleClear: (key: string) => void;
  menuItems: {key: string; value: string}[];
  disabled?: boolean;
  size?: 'lg' | 'xs' | 'md';
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  defaultLabel?: string;
}

const SelectInput: FC<IProps> = ({
  keyItem, menuItems, handleInputChange, inputItem, selectItem, handleSelectChange, handleClear, disabled, size = 'xs', inputRef,
  defaultLabel = 'Select',
}) => {
  const classes = useStyles();

  const [state, setState] = useState(!!inputItem);
  const valueItem = useMemo(() => menuItems.find((item) => item.key === selectItem)?.value || '', [menuItems, selectItem]);

  useEffect(() => {
    if (valueItem) {
      setState(true);
    } else {
      setState(false);
    }
  }, [valueItem]);

  return (
    <>
      {
      !state && (
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={selectItem}
          onChange={({ target: { value } }) => onChangeSelect(value as string)}
          displayEmpty
          className={clsx(classes.select, { [classes.selectLG]: size === 'lg', [classes.selectMD]: size === 'md' })}
          disabled={disabled}
        >
          <MenuItem className={clsx(classes.item, { [classes.itemLG]: size === 'lg', [classes.itemMD]: size === 'md' })} value="">{defaultLabel}</MenuItem>
          {
            menuItems.map((item) => (
              <MenuItem className={clsx(classes.item, { [classes.itemLG]: size === 'lg', [classes.itemMD]: size === 'md' })} key={item.key} value={item.key}>{item.value}</MenuItem>
            ))
          }
        </Select>
      )
    }
      {
      !!state && (
        <TextField
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CancelIcon className={classes.icon} onClick={handleClick} />
              </InputAdornment>
            ),
            startAdornment: (
              <div className={classes.text}>{valueItem}</div>
            ),
          }}
          value={inputItem}
          className={clsx(classes.input, { [classes.inputLG]: size === 'lg', [classes.inputMD]: size === 'md' })}
          variant="filled"
          onChange={onChangeInput}
          disabled={disabled}
          inputRef={inputRef}
        />
      )
    }
    </>
  );

  function handleClick() {
    setState(false);
    handleClear(keyItem);
  }

  function onChangeSelect(value: string) {
    handleSelectChange(value, keyItem);
    if (value) {
      setState(true);
    }
  }

  function onChangeInput({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    handleInputChange(value, keyItem);
  }
};

export default SelectInput;
