/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC, useContext, useState,
} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { IconButton } from '@/ui';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { mdiPlusCircle as AddIcon } from '@mdi/js';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import {
  Field, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import {
  todoTaskType, todoTaskPriority,
} from '@/utils/lists';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextField as TextFieldComponent } from 'formik-material-ui';
import { UserContext } from '@/providers/UserProvider';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { useLocation } from 'react-router';
import TextField from '@material-ui/core/TextField';
import { PropsI, RowI } from './types';
import DatePickerRsuite from '../DatePickerRsuite/DatePickerRsuite';
import useStyles from './styles';

export interface IValues extends RowI {
  name: string;
  parentId: number;
  email: string;
  type: string;
  state: string;
  priority: string;
  page: string;
  dueDate: Date;
}

const PopupTodo: FC<PropsI> = ({
  isOpen, onClose, title, children, loading,
  actionsComponent, className, users, onSubmit, activeTask, setActiveTask,
  isAllTasks, setIsAllTasks, notifyPageItems, parentItems, handleSetPriority,
}) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [advancedOptions, setAdvancedOptions] = useState<boolean>(false);
  const [openAddTask, setOpenAddTask] = useState<boolean>(false);
  const [taskPriority, setTaskPriority] = useState<string | null>(null);
  const usersList = users ? users.map((item) => (
    { key: item.email, name: item.email }
  )).sort() : [];
  const initialData = {
    name: '',
    email: user?.email || '',
    parentId: 0,
    type: 'objective',
    state: 'open',
    priority: '4',
    page: location.pathname,
    dueDate: undefined as unknown as Date,
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{ paper: className }}
      maxWidth="md"
      PaperProps={{
        style: {
          maxHeight: '90%',
        },
      }}
    >
      {!!title && (
        <DialogTitle id="customized-dialog-title" disableTypography className={clsx(classes.dialogTitle, classes.root)}>
          <Typography variant="h6">{title}</Typography>
          <Box className={classes.containerButton} onClick={() => setOpenAddTask(!openAddTask)}>
            <IconButton path={AddIcon} title="Add task" />
          </Box>
          <Typography component="div" className={classes.headerFilter}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>All</Grid>
              <Grid item>
                <Switch
                  key="switch"
                  size="small"
                  checked={activeTask}
                  onChange={() => setActiveTask(!activeTask)}
                />
              </Grid>
              <Grid item>Opened</Grid>
            </Grid>
          </Typography>
          <Typography component="div" className={classes.headerFilter}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>My</Grid>
              <Grid item>
                <Switch
                  key="switch"
                  size="small"
                  checked={isAllTasks}
                  onChange={() => setIsAllTasks(!isAllTasks)}
                />
              </Grid>
              <Grid item>All</Grid>
            </Grid>
          </Typography>
          {isAllTasks && (
            <TextField
              autoComplete="off"
              size="small"
              select
              variant="filled"
              label="Group By:"
              value={taskPriority}
              onChange={(e) => {
                setTaskPriority(e.target.value);
                handleSetPriority(e.target.value);
              }}
              className={classes.select}
            >
              <MenuItem key="task-priority" value="">Priority</MenuItem>
              <MenuItem key="task-email" value="email">User</MenuItem>
              <MenuItem key="task-state" value="state">Status</MenuItem>
            </TextField>
          )}
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <Collapse in={openAddTask} className={clsx({ [classes.openedAdd]: (openAddTask) })}>
        <Formik
          initialValues={{ ...initialData }}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {
            (props) => handleRender(props)
          }
        </Formik>
      </Collapse>
      <DialogContent dividers className={classes.root}>
        {children}
      </DialogContent>
      {!!actionsComponent && (
        <DialogActions className={classes.actions}>
          {actionsComponent}
        </DialogActions>
      )}
    </Dialog>
  );

  function handleRender({
    isSubmitting,
    submitForm,
    values,
    setFieldValue,
  }: FormikProps<IValues>) {
    return (
      <>
        <Form>
          <section className={classes.addTaskContainer}>
            <section className={classes.containerGridFull}>
              <Field
                component={TextFieldComponent}
                id="name"
                required
                type="textarea"
                label="Description"
                name="name"
                variant="filled"
                fullWidth
                margin="dense"
                multiline
                rows={2}
              />
            </section>
            <section className={classes.containerGrid}>
              <Field
                component={TextFieldComponent}
                id="email"
                name="email"
                select
                SelectProps={{ id: 'email-select' }}
                variant="filled"
                type="text"
                margin="dense"
                label="User"
                className={classes.selectComponent}
              >
                {usersList.map((item) => (
                  <MenuItem key={item?.key} value={item?.key || ''}>{item?.name || ''}</MenuItem>
                ))}
              </Field>
              <div>
                <Field
                  component={TextFieldComponent}
                  id="priority"
                  name="priority"
                  select
                  SelectProps={{ id: 'priority-select' }}
                  variant="filled"
                  type="text"
                  margin="dense"
                  label="Priority"
                  className={classes.selectComponentWithDate}
                >
                  {todoTaskPriority.map((item) => (
                    <MenuItem key={item?.key} value={item?.key || ''}>{item?.name || ''}</MenuItem>
                  ))}
                </Field>
                <FormControl margin="dense" className={classes.datePicker}>
                  <DatePickerRsuite
                    size="l"
                    isHidden
                    value={values.dueDate}
                    onChange={(date) => setFieldValue('dueDate', date)}
                  />
                </FormControl>
              </div>
            </section>
            {advancedOptions && (
              <section className={classes.containerGrid}>
                <Field
                  component={TextFieldComponent}
                  id="type"
                  name="type"
                  select
                  SelectProps={{ id: 'type-select' }}
                  variant="filled"
                  type="text"
                  margin="dense"
                  label="Type"
                  className={classes.selectComponent}
                >
                  {todoTaskType.map((item) => (
                    <MenuItem key={item?.key} value={item?.key || ''}>{item?.name || ''}</MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextFieldComponent}
                  id="page"
                  name="page"
                  select
                  SelectProps={{ id: 'todo-page-select' }}
                  variant="filled"
                  type="text"
                  margin="dense"
                  label="Page"
                  className={classes.selectComponent}
                >
                  {notifyPageItems && notifyPageItems.map((item) => (
                    <MenuItem key={item?.key} value={item?.key || ''}>{item?.name || ''}</MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextFieldComponent}
                  id="parentId"
                  name="parentId"
                  select
                  SelectProps={{ id: 'todo-parentId-select' }}
                  variant="filled"
                  type="text"
                  margin="dense"
                  label="Parent Task"
                  className={classes.selectComponent}
                >
                  {parentItems && parentItems.map((item) => (
                    <MenuItem key={item?.key} value={item?.key || ''}>{item?.name || ''}</MenuItem>
                  ))}
                </Field>
              </section>
            )}
            <DialogActions>
              <Button
                autoFocus
                onClick={toggleAdvanced}
                color="secondary"
                className={clsx({ [classes.active]: advancedOptions })}
              >
                Advanced Options
              </Button>
              <Button
                disabled={loading}
                autoFocus
                onClick={() => setOpenAddTask(!openAddTask)}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={loading}
                autoFocus
                onClick={submitForm}
                color="secondary"
              >
                Save
              </Button>
            </DialogActions>
          </section>
        </Form>
      </>
    );
  }

  function toggleAdvanced() {
    setAdvancedOptions((prevValue) => !prevValue);
  }

  function handleValidate(values: Record<string, string | boolean | number | undefined | string[] | any>) {
    const errors: Partial<IValues> = {};
    return errors;
  }

  async function handleSubmit(values: IValues, helpers: FormikHelpers<IValues>) {
    helpers.setSubmitting(true);
    const { success, message } = await onSubmit(values);
    helpers.setSubmitting(false);

    if (!success) {
      console.error(message);
    } else {
      setOpenAddTask(false);
    }
  }
};

export default PopupTodo;
