import { GET_LOCAL_CACHE } from '../query/common';
import getGloOptions from './options';

const cacheLocal = () => ({
  query: GET_LOCAL_CACHE,
  variables: {},
  data: {
    getGloOptions: getGloOptions(),
    getNotifications: [],
  },
});

export default cacheLocal;
