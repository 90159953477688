/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  NotificationE, IconButton, Popup, PopupTodo,
} from '@/ui';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { SelectProps } from '@/theme/base';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import { todoTaskPriority, todoTaskType } from '@/utils/lists';
import { RowI } from '@/components/TableUniversal/types';
import { Loading } from '@/components/Loading';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_TODO, UPDATE_TODO, ADD_TODO, DELETE_TODO, GET_GLO_OPTIONS, EDIT_GLO_OPTIONS,
} from '@/gql/query/common';
import get from 'lodash/get';
import format from 'date-fns/format';
import {
  ApolloCache, InMemoryCache, useApolloClient,
} from '@apollo/client';
import { ADD_NOTIFICATION } from '@/gql/query/notification';
import { Filter } from '@devexpress/dx-react-grid';
import { UserContext } from '@/providers/UserProvider';
import { isRole } from '@/config/user';
import { ADV, PUB } from '@/constants/role';
import Button from '@material-ui/core/Button';
import { TypeOperationEnum } from '@/components/Filter/types';
import { IMenu } from '@/app/Layout/types';
import { mdiFlagVariant as FlagIcon, mdiIncognito as GhostIcon, mdiMenuRight as ArrowRight } from '@mdi/js';
import IconUI from '@mdi/react';
import clsx from 'clsx';
import { Avatar } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { GhostContext } from '@/providers/GhostProvider';
import { uniqBy } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  additionalFinishedTaskMenu,
  finishedTaskMenu, getPriorityColor, getStatusColor, getTaskNextState, TaskMenuList,
} from './utils';
import DatePickerRsuite from '../../ui/DatePickerRsuite/DatePickerRsuite';
import useStyles from './styles';

export interface IState {
  key: string;
  name: string;
}

interface ITodoParams {
  filters: Filter[];
  filterType: string;
}

export interface IRows {
  id: number;
  parentId: number;
  page: string;
  name: string;
  type: string;
  email: string;
  nickName: string;
  state: string;
  priority: string;
  dueDate: string;
  startDate: string;
  finishDate: string;
}

export interface IPriority {
  email: string;
  nickName: string;
  state: string;
  stateName: string;
}

export interface IUsers {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IEditPopupRow {
  isOpen: boolean;
  onClose: () => void;
  totalActive: (value: number) => void;
  menuItems: IMenu[];
}

interface IPopupConfirm {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (value: boolean) => void;
}

export interface PageListI extends Record<string, string | number | boolean> {
  key: string;
  name: string;
}

const TaskNotification: FC<IEditPopupRow> = ({
  isOpen, onClose, totalActive, menuItems,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { user } = useContext(UserContext);
  const { isGhostMode } = useContext(GhostContext);
  const [addNotification] = useMutation(ADD_NOTIFICATION);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [activeTask, setActiveTask] = useState<boolean>(true);
  const [isAllTasks, setIsAllTasks] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<IRows | undefined>(undefined);
  const [openModule, setOpenModule] = useState<number | null>(null);
  const [openPriorityModule, setOpenPriorityModule] = useState<string | null>(null);
  const [openChildModule, setOpenChildModule] = useState<number | null>(null);
  const [taskPriority, setTaskPriority] = useState<string | null>(null);
  const notifyPageItems: PageListI[] = getPagesList(menuItems) || [{}];
  const { todo: todoOptions } = get(useQuery(GET_GLO_OPTIONS), 'data.getGloOptions');
  const { filters, filterType }: ITodoParams = todoOptions;
  const { data: todo, loading } = useQuery(GET_TODO, {
    variables: {
      filter: filters,
      filterType,
      userEmail: user?.email,
    },
    skip: (isRole(ADV) || isRole(PUB)) && !user?.email,
    pollInterval: 2 * 60 * 1000, // 2 min
    // notifyOnNetworkStatusChange: true,
  });
  const [editGloOptions] = useMutation(EDIT_GLO_OPTIONS);
  const users = get(todo, 'todo.payload.nodes.users') || [];
  const todoRows = get(todo, 'todo.payload.nodes.todo') || [];
  const priority = useMemo(() => {
    if (taskPriority) {
      const rows = uniqBy(todoRows, (obj: any) => obj[taskPriority]).map((item: any) => ({
        state: item.state,
        stateName: TaskMenuList.find((menu) => menu.key === item.state)?.name || item.state,
        email: item.email,
        nickName: item.nickName,
      }));
      if (activeTask && taskPriority !== 'email') {
        return rows.filter((item) => item.state !== 'completed');
      }

      return rows;
    }
    return [];
  }, [taskPriority, activeTask, todoRows]);

  const usersList = usersTodoList(users);
  const sortedRows = sortedTodoRows(todoRows);
  const filteredActiveTask = sortedRows.filter((item) => item.state !== 'completed');
  const filteredRows = activeTask
    ? sortedRows.filter((item) => item.state !== 'completed')
    : sortedRows;
  const objTypeTask = filteredRows.filter((item) => item.parentId === 0 && item.type === 'objective');
  const nonObjTypeTask = filteredRows.filter((item) => item.parentId === 0 && item.type !== 'objective');
  const filteredMainRows = objTypeTask.concat(nonObjTypeTask);
  const filteredChildRows = filteredRows.filter((item) => item.parentId > 0);
  const parentItems: PageListI[] = getParentList(filteredMainRows) || [{}];

  useEffect(() => {
    if (filteredActiveTask) {
      totalActive(filteredActiveTask.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredActiveTask]);

  const [addTask, { loading: mutationAddLoading }] = useMutation(
    ADD_TODO, {
      update: addTaskData,
      // refetchQueries: refetch(),
    },
  );
  const [updateTask, { loading: mutationEditLoading }] = useMutation(
    UPDATE_TODO, {
      update: updateTaskData,
      // refetchQueries: refetch(),
    },
  );
  const [deleteTask, { loading: mutationLoading }] = useMutation(
    DELETE_TODO, {
      update: deleteTaskData,
      // refetchQueries: refetch(),
    },
  );

  return (
    <PopupTodo
      isOpen={isOpen}
      onClose={onClose}
      title="To Do List"
      users={users}
      onSubmit={handleAddTask}
      loading={mutationAddLoading}
      activeTask={activeTask}
      setActiveTask={setActiveTask}
      isAllTasks={isAllTasks}
      handleSetPriority={setTaskPriority}
      setIsAllTasks={setTasks}
      notifyPageItems={notifyPageItems}
      parentItems={parentItems}
      className={classes.positionNotification}
    >
      <Rows />
    </PopupTodo>
  );

  function Rows() {
    return (
      <>
        {filteredMainRows.length ? (
          <>
            {isAllTasks ? (
              <>
                {taskPriority === 'email' && (
                  <>
                    {priority.map((item: IPriority) => (
                      <>
                        <section key={`menu-${item.email}-priority`} className={classes.priorityContainer}>
                          {!isGhostMode ? (
                            <Tooltip title={item.email}>
                              <Avatar className={clsx(classes.avatar, classes.avatarPriority)}>{item.nickName}</Avatar>
                            </Tooltip>
                          ) : (
                            <IconButton path={GhostIcon} title="User" />
                          )}
                          <Link
                            className={classes.textButton}
                            component="button"
                            variant="body2"
                            onClick={() => {
                              setOpenPriority(openPriorityModule === item.email ? null : item.email);
                            }}
                          >
                            {item.email}
                          </Link>
                        </section>
                        <Collapse in={openPriorityModule === item.email}>
                          <section className={classes.byUserContainer}>
                            <Tasks />
                          </section>
                        </Collapse>
                      </>
                    ))}
                  </>
                )}
                {taskPriority === 'state' && (
                  <>
                    {priority.map((item: IPriority) => (
                      <>
                        <section key={`menu-${item.state}-priority`} className={classes.priorityContainer}>
                          <Tooltip title={item.stateName.charAt(0) + item.stateName.charAt(1)}>
                            <Avatar className={clsx(classes.avatar, classes.avatarPriority)}>
                              {item.stateName.charAt(0) + item.stateName.charAt(1)}
                            </Avatar>
                          </Tooltip>
                          <Link
                            className={clsx(classes.textButton, classes.textState)}
                            component="button"
                            variant="body2"
                            onClick={() => {
                              setOpenPriority(openPriorityModule === item.state ? null : item.state);
                            }}
                          >
                            {item.stateName}
                          </Link>
                        </section>
                        <Collapse in={openPriorityModule === item.state}>
                          <section className={classes.byUserContainer}>
                            <Tasks />
                          </section>
                        </Collapse>
                      </>
                    ))}
                  </>
                )}
                {!taskPriority && (
                  <Tasks />
                )}
              </>
            ) : (
              <Tasks />
            )}
            <PopupConfirm
              isOpen={openDelete}
              onClose={() => setOpenDelete(false)}
              onSubmit={submitForm}
            />
          </>
        ) : (
          <>
            {loading ? (
              <div className={classes.taskLoading}>
                <Loading size={25} state={loading} className={classes.transparentBg} />
              </div>
            ) : (
              <Typography variant="h5" className={classes.emptyList}>Task list is Empty</Typography>
            )}
          </>
        )}
      </>
    );
  }

  function Tasks() {
    const rows = openPriorityModule && taskPriority
      ? filteredMainRows.filter((item) => item[taskPriority] === openPriorityModule)
      : filteredMainRows;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [anchorElId, setAnchorElId] = useState<string | null>(null);
    const [anchorElType, setAnchorElType] = useState<string>('');
    const [advancedOptions, setAdvancedOptions] = useState<boolean>(false);
    const taskStatusList = anchorElType === 'objective' ? additionalFinishedTaskMenu : finishedTaskMenu;

    return (
      <section className={classes.taskContainer}>
        {rows.length ? (
          <>
            {rows.map((item: IRows) => (
              <section key={item.id}>
                <div className={classes.taskItem}>
                  <div className={classes.taskContent}>
                    <Typography component="div">
                      <Link
                        className={classes.textButton}
                        component="button"
                        variant="body2"
                        onClick={() => {
                          setOpenMain(openModule === item.id ? null : item.id);
                          setActiveRow(item);
                        }}
                      >
                        <IconUI
                          path={FlagIcon}
                          size={0.7}
                          style={{ color: getPriorityColor(item.priority) }}
                        />
                        {' '}
                        {item.priority === '1' && (
                          <>!</>
                        )}
                        {item.type === 'objective' ? (
                          <span className={classes.titleTaskType}>
                            {`*[${getTaskTypeName(item.type)}] `}
                          </span>
                        ) : (
                          <span>
                            {`[${getTaskTypeName(item.type)}] `}
                          </span>
                        )}
                        {setTaskName(item)}
                      </Link>
                    </Typography>
                    <div className={classes.taskMeta}>
                      <div>
                        {item.state === 'started' && (
                          <div className={classes.itemDate}>
                            {`Started: ${getStateDate(item.startDate)}`}
                          </div>
                        )}
                        {item.state === 'finished' && (
                          <div className={classes.itemDate}>
                            {`Finished: ${getStateDate(item.finishDate)}`}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <section style={{ display: isAllTasks && taskPriority !== '1' ? 'flex' : 'block' }}>
                    {
                      isAllTasks && taskPriority !== 'email' && (
                        <div>
                          {!isGhostMode ? (
                            <Tooltip title={item.email}>
                              <Avatar className={classes.avatar}>{item.nickName}</Avatar>
                            </Tooltip>
                          ) : (
                            <IconButton path={GhostIcon} title="User" />
                          )}
                        </div>
                      )
                    }
                    <div className={classes.containerTaskAction}>
                      <div className={classes.containerButton}>
                        <Loading size={20} state={mutationEditLoading || mutationLoading} />
                        <div>
                          <Button
                            // color="secondary"
                            // variant="contained"
                            disableElevation
                            className={clsx(classes.button, { [classes.finishedButton]: (item.state === 'started') })}
                            style={{
                              backgroundColor: item.type === 'objective' ? getStatusColor(item.state) : '',
                              color: item.type === 'objective' ? '#fff' : '',
                            }}
                            disabled={mutationEditLoading || mutationLoading}
                            endIcon={item.state !== 'open' ? <KeyboardArrowDownIcon /> : ''}
                            value={item.id}
                            onClick={(e) => {
                              if (['requested', 'open'].includes(item.state)) {
                                setTaskState(getTaskNextState(item.state), item.id);
                              } else {
                                setAnchorEl(e.currentTarget);
                                setAnchorElId(e.currentTarget.value);
                                setAnchorElType(item.type);
                              }
                            }}
                          >
                            {getTaskNextState(item.state).name}
                          </Button>
                        </div>
                      </div>
                      <div
                        className={
                          clsx(classes.itemDueDate, { [classes.pastDate]: (checkPastDate(item.dueDate)) })
                        }
                      >
                        {format(new Date(item.dueDate), 'MM-dd-yyyy')}
                      </div>
                    </div>
                  </section>
                </div>
                <Collapse in={openModule === item.id}>
                  {filteredChildRows.map((childItem: IRows) => (childItem.parentId === item.id && (
                    <section key={childItem.id} className={classes.subTaskItem}>
                      <div className={classes.taskItem}>
                        <div className={classes.taskContent}>
                          <IconUI
                            path={ArrowRight}
                            size={1.5}
                            className={classes.childTaskIcon}
                          />
                          <Typography component="div">
                            <Link
                              className={classes.textButton}
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setOpenChild(
                                  openChildModule === childItem.id ? null : childItem.id,
                                  childItem,
                                );
                              }}
                            >
                              <IconUI
                                path={FlagIcon}
                                size={0.7}
                                style={{ color: getPriorityColor(item.priority) }}
                              />
                              {' '}
                              {childItem.priority === '1' && (
                                <>!</>
                              )}
                              {childItem.type === 'objective' ? (
                                <span className={classes.titleTaskType}>
                                  {`*[${getTaskTypeName(childItem.type)}] `}
                                </span>
                              ) : (
                                <span>
                                  {`[${getTaskTypeName(childItem.type)}] `}
                                </span>
                              )}
                              {setTaskName(childItem)}
                            </Link>
                          </Typography>
                          <div className={classes.taskMeta}>
                            <div>
                              {childItem.state === 'started' && (
                                <div className={classes.itemDate}>
                                  {`Started: ${getStateDate(childItem.startDate)}`}
                                </div>
                              )}
                              {childItem.state === 'finished' && (
                                <div className={classes.itemDate}>
                                  {`Finished: ${getStateDate(childItem.finishDate)}`}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <section style={{ display: isAllTasks && taskPriority !== '1' ? 'flex' : 'block' }}>
                          {
                            isAllTasks && taskPriority === '0' && (
                              <div>
                                {!isGhostMode ? (
                                  <Tooltip title={childItem.email}>
                                    <Avatar className={classes.avatar}>{childItem.nickName}</Avatar>
                                  </Tooltip>
                                ) : (
                                  <IconButton path={GhostIcon} title="User" />
                                )}
                              </div>
                            )
                          }
                          <div className={classes.containerTaskAction}>
                            <div className={classes.containerButton}>
                              <Loading size={20} state={mutationEditLoading || mutationLoading} />
                              <div>
                                <Button
                                  // color="secondary"
                                  // variant="contained"
                                  disableElevation
                                  className={clsx(classes.button, { [classes.finishedButton]: (childItem.state === 'started') })}
                                  style={{
                                    backgroundColor: childItem.type === 'objective' ? getStatusColor(item.state) : '',
                                    color: childItem.type === 'objective' ? '#fff' : '',
                                  }}
                                  disabled={mutationEditLoading || mutationLoading}
                                  endIcon={childItem.state !== 'open' ? <KeyboardArrowDownIcon /> : ''}
                                  value={childItem.id}
                                  onClick={(e) => {
                                    if (['requested', 'open'].includes(childItem.state)) {
                                      setTaskState(getTaskNextState(childItem.state), childItem.id);
                                    } else {
                                      setAnchorEl(e.currentTarget);
                                      setAnchorElId(e.currentTarget.value);
                                      setAnchorElType(childItem.type);
                                    }
                                  }}
                                >
                                  {getTaskNextState(childItem.state).name}
                                </Button>
                              </div>
                            </div>
                            <div
                              className={
                                clsx(classes.itemDueDate, { [classes.pastDate]: (checkPastDate(childItem.dueDate)) })
                              }
                            >
                              {format(new Date(childItem.dueDate), 'MM-dd-yyyy')}
                            </div>
                          </div>
                        </section>
                      </div>
                      <Collapse in={openChildModule === childItem.id}>
                        {activeRow && (
                          <div className={classes.editTaskContainer}>
                            <section className={classes.editTaskContainerFull}>
                              <TextField
                                label="Description"
                                name="name"
                                variant="filled"
                                defaultValue={activeRow.name}
                                margin="dense"
                                multiline
                                rows={2}
                                onBlur={(e) => updateActiveRow(e)}
                              />
                            </section>
                            <section className={classes.editTaskContainerGrid}>
                              <Autocomplete
                                key={`${activeRow.id}-todo-user`}
                                options={usersList}
                                getOptionLabel={(option) => (option && option.name ? option.name : '')}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="User"
                                    name="email"
                                    variant="filled"
                                    size="small"
                                    margin="dense"
                                    SelectProps={SelectProps}
                                  />
                                )}
                                onChange={(_, value) => updateActiveRowSelect(value, 'email')}
                                value={(usersList).find((element) => element.key === activeRow.email)}
                              />
                              <div>
                                <Autocomplete
                                  key={`${activeRow.id}-todo-priority`}
                                  options={todoTaskPriority}
                                  className={classes.selectComponentWithDate}
                                  getOptionLabel={(option) => (option.name || '')}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Priority"
                                      name="priority"
                                      variant="filled"
                                      size="small"
                                      margin="dense"
                                      SelectProps={SelectProps}
                                    />
                                  )}
                                  onChange={(_, value) => updateActiveRowSelect(value, 'priority')}
                                  value={(todoTaskPriority).find(
                                    (element) => element.key === activeRow.priority.toLowerCase(),
                                  )}
                                />
                                <FormControl margin="dense">
                                  <DatePickerRsuite
                                    size="lTask"
                                    isHidden
                                    value={item.dueDate ? new Date(activeRow.dueDate) : undefined as unknown as Date}
                                    onChange={(e) => updateDateActiveRow(e)}
                                  />
                                </FormControl>
                              </div>
                              {advancedOptions && (
                                <>
                                  <Autocomplete
                                    key={`${activeRow.id}-todo-type`}
                                    options={todoTaskType}
                                    getOptionLabel={(option) => (option.name || '')}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Type"
                                        name="type"
                                        variant="filled"
                                        size="small"
                                        margin="dense"
                                        SelectProps={SelectProps}
                                      />
                                    )}
                                    onChange={(_, value) => updateActiveRowSelect(value, 'type')}
                                    value={
                                      (todoTaskType).find((element) => element.key === activeRow.type.toLowerCase())
                                    }
                                  />
                                  <Autocomplete
                                    key={`${activeRow.id}-todo-parentId`}
                                    options={parentItems}
                                    getOptionLabel={(option) => (option.name || '')}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Parent Task"
                                        name="parentId"
                                        variant="filled"
                                        size="small"
                                        margin="dense"
                                        SelectProps={SelectProps}
                                      />
                                    )}
                                    onChange={(_, value) => updateActiveRowSelect(value, 'parentId')}
                                    value={(parentItems).find(
                                      (element) => element.key === String(activeRow.parentId),
                                    )}
                                  />
                                  <Autocomplete
                                    key={`${activeRow.id}-todo-page`}
                                    options={notifyPageItems}
                                    getOptionLabel={(option) => (option.name || '')}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Page"
                                        name="page"
                                        variant="filled"
                                        size="small"
                                        margin="dense"
                                        SelectProps={SelectProps}
                                      />
                                    )}
                                    onChange={(_, value) => updateActiveRowSelect(value, 'page')}
                                    value={(notifyPageItems).find(
                                      (element) => element.key === activeRow.page,
                                    )}
                                  />
                                </>
                              )}
                            </section>
                            <section className={classes.editTaskContainerButton}>
                              <div className={classes.leftButtons}>
                                <section className={classes.containerButton}>
                                  <Button
                                    color="secondary"
                                    onClick={handleDelete}
                                    disabled={mutationEditLoading || mutationLoading}
                                  >
                                    Delete
                                  </Button>
                                </section>
                              </div>
                              <div className={classes.rightButtons}>
                                <section className={classes.containerButton}>
                                  <Button
                                    autoFocus
                                    onClick={toggleAdvanced}
                                    color="secondary"
                                    className={clsx(classes.advancedButton, { [classes.active]: advancedOptions })}
                                  >
                                    Advanced Options
                                  </Button>
                                  <Button
                                    color="secondary"
                                    onClick={handleCancel}
                                    disabled={mutationEditLoading || mutationLoading}
                                  >
                                    Cancel
                                  </Button>
                                </section>
                                <section className={classes.containerButton}>
                                  <Loading size={20} state={mutationEditLoading || mutationLoading} />
                                  <Button
                                    color="secondary"
                                    onClick={saveTask}
                                    disabled={mutationEditLoading || mutationLoading}
                                  >
                                    Save
                                  </Button>
                                </section>
                              </div>
                            </section>
                          </div>
                        )}
                      </Collapse>
                    </section>
                  )))}
                  {activeRow && !openChildModule && (
                    <div className={classes.editTaskContainer}>
                      <section className={classes.editTaskContainerFull}>
                        <TextField
                          label="Description"
                          name="name"
                          variant="filled"
                          defaultValue={activeRow.name}
                          margin="dense"
                          multiline
                          rows={2}
                          onBlur={(e) => updateActiveRow(e)}
                        />
                      </section>
                      <section className={classes.editTaskContainerGrid}>
                        <Autocomplete
                          key={`${activeRow.id}-todo-user`}
                          options={usersList}
                          getOptionLabel={(option) => (option && option.name ? option.name : '')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="User"
                              name="email"
                              variant="filled"
                              size="small"
                              margin="dense"
                              SelectProps={SelectProps}
                            />
                          )}
                          onChange={(_, value) => updateActiveRowSelect(value, 'email')}
                          value={(usersList).find((element) => element.key === activeRow.email)}
                        />
                        <div>
                          <Autocomplete
                            key={`${activeRow.id}-todo-priority`}
                            options={todoTaskPriority}
                            className={classes.selectComponentWithDate}
                            getOptionLabel={(option) => (option.name || '')}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Priority"
                                name="priority"
                                variant="filled"
                                size="small"
                                margin="dense"
                                // className={classes.select}
                                SelectProps={SelectProps}
                              />
                            )}
                            onChange={(_, value) => updateActiveRowSelect(value, 'priority')}
                            value={(todoTaskPriority).find(
                              (element) => element.key === activeRow.priority.toLowerCase(),
                            )}
                          />
                          <FormControl margin="dense">
                            <DatePickerRsuite
                              size="lTask"
                              isHidden
                              value={item.dueDate ? new Date(activeRow.dueDate) : undefined as unknown as Date}
                              onChange={(e) => updateDateActiveRow(e)}
                            />
                          </FormControl>
                        </div>
                        {advancedOptions && (
                          <>
                            <Autocomplete
                              key={`${activeRow.id}-todo-type`}
                              options={todoTaskType}
                              getOptionLabel={(option) => (option.name || '')}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type"
                                  name="type"
                                  variant="filled"
                                  size="small"
                                  margin="dense"
                                  SelectProps={SelectProps}
                                />
                              )}
                              onChange={(_, value) => updateActiveRowSelect(value, 'type')}
                              value={(todoTaskType).find((element) => element.key === activeRow.type.toLowerCase())}
                            />
                            <Autocomplete
                              key={`${activeRow.id}-todo-parentId`}
                              options={parentItems}
                              getOptionLabel={(option) => (option.name || '')}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Parent Task"
                                  name="parentId"
                                  variant="filled"
                                  size="small"
                                  margin="dense"
                                  SelectProps={SelectProps}
                                />
                              )}
                              disabled={disableParent(activeRow.id)}
                              onChange={(_, value) => updateActiveRowSelect(value, 'parentId')}
                              value={(parentItems).find(
                                (element) => element.key === String(activeRow.parentId),
                              )}
                            />
                            <Autocomplete
                              key={`${activeRow.id}-todo-page`}
                              options={notifyPageItems}
                              getOptionLabel={(option) => (option.name || '')}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Page"
                                  name="page"
                                  variant="filled"
                                  size="small"
                                  margin="dense"
                                  SelectProps={SelectProps}
                                />
                              )}
                              onChange={(_, value) => updateActiveRowSelect(value, 'page')}
                              value={(notifyPageItems).find(
                                (element) => element.key === activeRow.page,
                              )}
                            />
                          </>
                        )}
                      </section>
                      <section className={classes.editTaskContainerButton}>
                        <div className={classes.leftButtons}>
                          <section className={classes.containerButton}>
                            <Button
                              color="secondary"
                              onClick={handleDelete}
                              disabled={mutationEditLoading || mutationLoading}
                            >
                              Delete
                            </Button>
                          </section>
                        </div>
                        <div className={classes.rightButtons}>
                          <section className={classes.containerButton}>
                            <Button
                              autoFocus
                              onClick={toggleAdvanced}
                              color="secondary"
                              className={clsx(classes.advancedButton, { [classes.active]: advancedOptions })}
                            >
                              Advanced Options
                            </Button>
                            <Button
                              color="secondary"
                              onClick={handleCancel}
                              disabled={mutationEditLoading || mutationLoading}
                            >
                              Cancel
                            </Button>
                          </section>
                          <section className={classes.containerButton}>
                            <Loading size={20} state={mutationEditLoading || mutationLoading} />
                            <Button
                              color="secondary"
                              onClick={saveTask}
                              disabled={mutationEditLoading || mutationLoading}
                            >
                              Save
                            </Button>
                          </section>
                        </div>
                      </section>
                    </div>
                  )}
                </Collapse>
              </section>
            ))}
            <Menu
              id="todo-status-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              {
                taskStatusList.map((menu) => (
                  <MenuItem
                    key={`${menu.key}-task`}
                    value={menu.key}
                    onClick={() => {
                      setTaskState(getTaskNextState(menu.key), Number(anchorElId));
                    }}
                  >
                    {menu.name}
                  </MenuItem>
                ))
              }
            </Menu>
          </>
        ) : (
          <Typography variant="h5" className={classes.emptyList}>Task list is Empty</Typography>
        )}
      </section>
    );

    function toggleAdvanced() {
      setAdvancedOptions((prevValue) => !prevValue);
    }
  }

  function checkPastDate(dueDate: string) {
    const date = format(new Date(dueDate), 'MM/dd/yyyy');
    const currentDate = format(new Date(), 'MM/dd/yyyy');
    const diffDate = new Date(currentDate).getTime() - new Date(date).getTime();
    return diffDate > 0;
  }

  function setTaskName(item: IRows) {
    if (item.page !== '') {
      const menuName = notifyPageItems.filter((menu) => menu.key === item.page);
      if (menuName.length) {
        return `[${menuName[0].name}] ${item.name}`;
      }
    }

    return item.name;
  }

  function disableParent(id: number) {
    return !!filteredChildRows.find((item) => item.parentId === id);
  }

  function getPagesList(menuItems: IMenu[]) {
    const pageItems: PageListI[] = [{ key: '', name: 'None' }];
    menuItems.forEach((item: IMenu) => {
      if (['/dashboard'].includes(item.key)) {
        const newItem = {
          key: item.key,
          name: item.name,
        };
        pageItems.push(newItem);
      }
      const subItems = item.items;
      if (Array.isArray(subItems)) {
        subItems.forEach((subItem) => {
          const newSubItem = {
            key: subItem.key,
            name: `${item.name} - ${subItem.name}`,
          };
          pageItems.push(newSubItem);
        });
      }
    });

    return pageItems;
  }

  function getParentList(getParentList: IRows[]) {
    const parentItems: PageListI[] = [{ key: '0', name: 'None' }];
    getParentList.forEach((item: IRows) => {
      const newItem = {
        key: String(item.id),
        name: String(item.name),
      };
      parentItems.push(newItem);
    });

    return parentItems;
  }

  function submitForm() {
    if (activeRow) {
      handleDeleteTask(activeRow.id);
    }
  }

  function handleDelete() {
    setOpenDelete(true);
  }

  function setOpenPriority(value: string | null) {
    setOpenPriorityModule(value);
  }

  function setOpenMain(id: number | null) {
    setOpenModule(id);
    setOpenChildModule(null);
  }

  function setOpenChild(id: number | null, childItem: IRows) {
    if (!id) {
      const mainItem = filteredMainRows.filter((item) => item.id === childItem.parentId);
      if (mainItem) {
        setActiveRow(mainItem[0]);
      }
    } else {
      setActiveRow(childItem);
    }
    setOpenChildModule(id);
  }

  function handleCancel() {
    setActiveRow(undefined);
    setOpenModule(null);
    setOpenChildModule(null);
  }

  function getTaskTypeName(type: string) {
    return todoTaskType.filter((item) => item.key === type)[0].name;
  }

  function setTaskState(state: IState, id: number) {
    const nextState = state.key.toLowerCase();
    const newList = filteredRows.map((item) => (item.id === id ? { ...item, state: nextState } : item));
    const updActiveRow = newList.filter((item) => item.id === id);

    handleUpdateTask(updActiveRow[0]);
  }

  function updateActiveRow(e: any) {
    const itemValue = e.target.value;
    const itemName = e.target.name;
    const updItem = { ...activeRow, [itemName]: itemValue };

    if (updItem) {
      setActiveRow(updItem as IRows);
    }
  }

  function updateActiveRowSelect(value: any, itemName: string) {
    if (value) {
      const itemValue = value.key;
      const updItem = { ...activeRow, [itemName]: itemValue };
      if (updItem) {
        setActiveRow(updItem as IRows);
      }
    }
  }

  function updateDateActiveRow(e: any) {
    const updItem = { ...activeRow, dueDate: e };
    if (updItem) {
      setActiveRow(updItem as IRows);
    }
  }

  function saveTask() {
    if (activeRow) {
      handleUpdateTask(activeRow);
    }
  }

  async function handleAddTask(values: RowI) {
    const node = values as any;
    addTask({ variables: { node: getAddTask(node) } });

    return { success: true, message: 'Setting have been Saved!' };
  }

  async function handleUpdateTask(values: IRows) {
    const node = values as any;
    const id = get(values, 'id');
    updateTask({ variables: { id, node: getUpdateTask(node) } });

    return { success: true, message: 'Setting have been Saved!' };
  }

  async function handleDeleteTask(id: number) {
    deleteTask({ variables: { id } });

    return { success: true, message: 'Setting have been Saved!' };
  }

  function getAddTask(node: Record<string, string | number>) {
    const date = formatUpdDate(node.dueDate);

    return {
      name: String(node.name || ''),
      parentId: String(node.parentId || '0'),
      page: String(node.page || ''),
      email: String(node.email || ''),
      type: String(node.type || ''),
      state: String(node.state || 'requested'),
      priority: String(node.priority || '4'),
      dueDate: date,
    };
  }

  function getUpdateTask(node: Record<string, string | number>) {
    const date = formatUpdDate(node.dueDate);

    return {
      name: String(node.name || ''),
      parentId: String(node.parentId || '0'),
      page: String(node.page || ''),
      email: String(node.email || ''),
      type: String(node.type || ''),
      state: String(node.state || ''),
      priority: String(node.priority || '4'),
      dueDate: date,
    };
  }

  async function addTaskData(cache: ApolloCache<InMemoryCache>, data: any) {
    const response = get(data, 'data.addTodo') || 0;

    if (response.success) {
      try {
        const todo = client.readQuery({
          query: GET_TODO,
          variables: {
            filter: filters,
            filterType,
            userEmail: user?.email,
          },
        });
        const node = get(response, 'payload.node');
        const nodes = [{
          ...todo.todo.payload.nodes.todo[0],
          ...node,
        }].concat(todo.todo.payload.nodes.todo);

        await cache.writeQuery({
          query: GET_TODO,
          data: {
            todo: {
              payload: {
                ...todo.todo.payload,
                nodes,
              },
              __typename: 'TodoRequest',
            },
          },
          variables: {
            filter: filters,
            filterType,
            userEmail: user?.email,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }

    addNotification({
      variables: {
        message: response.message,
        status: true,
        type: response.success ? NotificationE.Success : NotificationE.Error,
      },
    });
  }

  async function updateTaskData(cache: ApolloCache<InMemoryCache>, data: any) {
    const response = get(data, 'data.updateTodo') || 0;

    if (response.success) {
      setOpenModule(null);
      setOpenChildModule(null);
      setActiveRow(undefined);

      if (response.success) {
        const todo = client.readQuery({
          query: GET_TODO,
          variables: {
            filter: filters,
            filterType,
            userEmail: user?.email,
          },
        });
        const id = get(response, 'payload.node.id');
        const node = get(response, 'payload.node');

        const nodes = todo.todo.payload.nodes.todo.map((item: any) => {
          if (item.id === id) {
            return { ...item, ...node };
          }
          return item;
        });

        await cache.writeQuery({
          query: GET_TODO,
          data: {
            todo: {
              payload: {
                nodes: {
                  todo: {
                    ...todo.todo.payload.nodes.todo,
                    nodes,
                  },
                },
              },
              __typename: 'TodoRequest',
            },
          },
          variables: {
            filter: filters,
            filterType,
            userEmail: user?.email,
          },
        });
      }
    }

    addNotification({
      variables: {
        message: response.message,
        status: true,
        type: response.success ? NotificationE.Success : NotificationE.Error,
      },
    });
  }

  async function deleteTaskData(cache: ApolloCache<InMemoryCache>, data: any) {
    const response = get(data, 'data.deleteTodo') || 0;

    if (response.success) {
      setOpenModule(null);
      setOpenChildModule(null);
      setActiveRow(undefined);

      if (response.success) {
        const todo = client.readQuery({
          query: GET_TODO,
          variables: {
            filter: filters,
            filterType,
            userEmail: user?.email,
          },
        });
        const id = get(response, 'payload.id');

        await cache.writeQuery({
          query: GET_TODO,
          data: {
            todo: {
              payload: {
                ...todo.todo.payload,
                nodes: todo.todo.payload.nodes.todo.filter((item: any) => item.id !== id),
              },
              __typename: 'TodoRequest',
            },
          },
          variables: {
            filter: filters,
            filterType,
            userEmail: user?.email,
          },
        });
      }
    }

    addNotification({
      variables: {
        message: response.message,
        status: true,
        type: response.success ? NotificationE.Success : NotificationE.Error,
      },
    });
  }

  function usersTodoList(users: IUsers[]) {
    return users ? users.map((item) => (
      { key: item.email, name: item.email }
    )).sort() : [];
  }

  function sortedTodoRows(rows: IRows[]) {
    return rows ? rows.map((item: IRows) => (
      {
        id: item.id,
        parentId: item.parentId,
        page: item.page,
        name: item.name,
        type: item.type,
        email: item.email,
        nickName: item.nickName,
        state: item.state,
        priority: item.priority,
        dueDate: item.dueDate,
        startDate: item.startDate,
        finishDate: item.finishDate,
      }
    )).sort((a: any, b: any) => (Number(a.dueDate) > Number(b.dueDate) ? 1 : -1)) : [];
  }

  // function refetch() {
  //   return [{ query: GET_TODO, variables: { filter: filters, filterType, userEmail: user?.email } }];
  // }

  function formatUpdDate(date: any) {
    const newDate = date ? format(new Date(date), 'MM/dd/yyyy') : format(new Date(), 'MM/dd/yyyy');
    const currentDate = new Date();
    const time = currentDate.toLocaleTimeString();
    return `${newDate} ${time}`;
  }

  function getStateDate(date: any) {
    if (date === '0000-00-00 00:00:00' || date === '0000-00-00') {
      return format(new Date(), 'MM-dd-yyyy');
    }

    return format(new Date(date), 'MM-dd-yyyy');
  }

  function setTasks(isAllTasks: boolean) {
    setIsAllTasks(isAllTasks);
    editGloOptions({
      variables: {
        type: 'todo.filters',
        options: [{ columnName: 'isAllTasks', value: isAllTasks ? '1' : '0', operation: TypeOperationEnum.EQ }],
      },
    });
  }
};

const PopupConfirm: FC<IPopupConfirm> = ({
  isOpen, onClose, onSubmit,
}) => (
  <Popup
    isOpen={isOpen}
    onClose={() => onClose(false)}
    title="Delete Task"
    actionsComponent={(
      <>
        <Button autoFocus onClick={() => onClose(false)} color="primary">
          Disagree
        </Button>
        <Button
          autoFocus
          color="secondary"
          onClick={() => {
            onClose(false); onSubmit(true);
          }}
        >
          Agree
        </Button>
      </>
      )}
  >
    <Typography gutterBottom>
      <span>Are you sure you confirm the delete task?</span>
    </Typography>
  </Popup>
);

export default TaskNotification;
