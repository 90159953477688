const configHiddenGhostMode = {
  advertisers: {
    hidden: ['advertiser', 'publisher', 'portal'],
    show: ['feedId', 'publisherId'],
  },
  publishers: {
    hidden: ['publisher', 'gloPublisher', 'gloPubFeed', 'campaign', 'gloPubFeedUrl'],
    show: ['publisherId', 'pubFeedId', 'campaignId'],
  },
  import: {
    hidden: ['name', 'trader'],
    show: ['id', 'traderId'],
  },
  export: {
    hidden: ['name', 'trader'],
    show: ['id', 'traderId'],
  },
  logs: {
    hidden: ['advertiser', 'gloSrc', 'gloGroup'],
    show: ['advertiserId', 'gloSrcId', 'gloGroupId'],
  },
  campaigns: {
    hidden: ['name', 'parentName', 'feedName', 'filters', 'groups', 'edit'],
    show: ['parentId', 'feedId'],
  },
  tqm: {
    hidden: ['name', 'advTitle', 'feedTitle', 'pubTitle', 'createdName', 'edit'],
    show: ['advertiserId', 'feedId', 'publisherId'],
  },
  tqf: {
    hidden: ['name', 'createdName', 'edit'],
  },
  volume: {
    hidden: ['name', 'createdName', 'edit'],
  },
  protectionRecoup: {
    hidden: ['name', 'createdName', 'edit'],
  },
  geoFence: {
    hidden: ['name', 'createdName', 'edit'],
  },
  cro: {
    hidden: ['name', 'createdName', 'edit'],
  },
};

export default configHiddenGhostMode;
