import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  innerLoading: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 501,
  },
  taskContainer: {
    display: 'grid',
  },
  taskContent: {
    display: 'block',
    position: 'relative',
  },
  subTaskItem: {
    marginLeft: 20,
  },
  taskItem: {
    fontSize: 16,
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: '460px auto',
    alignItems: 'center',
    padding: '5px 0',
  },
  taskMeta: {
    fontSize: 16,
    display: 'grid',
    gridGap: 20,
    gridTemplateColumns: '300px 300px',
    padding: '5px 0',
  },
  headerContainer: {
    position: 'relative',
    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.info.main}`,

  },
  header: {
    position: 'relative',
    margin: '0 auto',
  },
  editTaskContainerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '16px',
  },
  editTaskContainerFull: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  editTaskContainerButton: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginTop: 10,
  },
  editTaskContainer: {
    gridGap: '20px',
    border: '1px solid rgb(5, 173, 120)',
    padding: '10px',
    margin: '10px 0',
    // '& > div > div': {
    //   gridGap: '20px',
    //   border: '1px solid rgb(5, 173, 120)',
    //   padding: '10px',
    //   margin: '10px 0',
    // },
  },
  labelDatePicker: {
    padding: 0,
    transform: 'translate(12px, 7px) scale(0.75)',
    zIndex: 9,
  },
  select: {
    width: '240px',
    marginTop: theme.spacing(2),
  },
  textButton: {
    fontSize: 16,
    textAlign: 'left',
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },
  textState: {
    textTransform: 'capitalize',
  },
  emptyList: {
    textAlign: 'center',
    padding: 20,
  },
  taskLoading: {
    position: 'relative',
    padding: 50,
  },
  transparentBg: {
    background: 'transparent',
  },
  formControlLabel: {
    paddingLeft: theme.spacing(1),
  },
  leftButtons: {
    textAlign: 'left',
  },
  rightButtons: {
    textAlign: 'right',
  },
  containerButton: {
    position: 'relative',
    width: 'fit-content',
    display: 'inline-block',
    margin: '0 5px',
  },
  positionNotification: {
    position: 'fixed',
    top: 50,
    right: 10,
    m: 0,
    width: 700,
  },
  button: {
    fontSize: '12px',
    width: '136px',
    color: '#FFF',
    height: '33px',
    backgroundColor: '#05AD78',
    '&:hover': {
      backgroundColor: '#05AD78',
      opacity: 0.8,
    },
  },
  finishedButton: {
    color: '#FFF',
    backgroundColor: '#FF5722',
    '&:hover': {
      backgroundColor: '#FF5722',
      opacity: 0.8,
    },
  },
  itemDate: {
    display: 'block',
    fontSize: 14,
  },
  itemDueDate: {
    marginTop: 7,
    textAlign: 'center',
    fontSize: 14,
  },
  titleTaskType: {
    fontWeight: 700,
  },
  pastDate: {
    color: '#FF6859FF',
  },
  avatar: {
    fontSize: 16,
    textTransform: 'uppercase',
    color: '#FFF',
    backgroundColor: '#1EB980',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarPriority: {
    marginRight: 10,
  },
  containerTaskAction: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  containerActions: {
    display: 'block',
  },
  childTaskIcon: {
    position: 'absolute',
    top: -5,
    left: -35,
  },
  priorityContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: 5,
  },
  byUserContainer: {
    marginLeft: 20,
  },
  itemMenu: {
    fontSize: '12px',
    color: theme.palette.info.contrastText,
    '&:hover': {
      color: '#1EB980',
    },
  },
  advancedButton: {
    marginRight: 'auto',
  },
  active: {
    background: theme.palette.text.hint,
  },
  selectComponentWithDate: {
    display: 'inline-block',
    width: 'calc(100% - 40px)',
  },
}));

export default useStyles;
