/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Formik, Form, FormikHelpers, FormikProps, Field,
} from 'formik';
import { TextField } from 'formik-material-ui';
import DialogActions from '@material-ui/core/DialogActions';
import { Popup } from '@/ui';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Loading } from '@/components/Loading';
import { INotifyPopupRow, RowI } from './types';
import useStyles from './styles';

export interface IValues extends RowI {
  type: string;
  subject: string;
  body: string;
}

interface IPopupConfirm {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (() => Promise<void>) & (() => Promise<any>);
}
const PopupConfirm: FC<IPopupConfirm> = ({
  isOpen, onClose, onSubmit, loading,
}) => (
  <Popup
    isOpen={isOpen}
    onClose={() => onClose(false)}
    title="Send Notification"
    actionsComponent={(
      <>
        <Button autoFocus onClick={() => onClose(false)} color="primary">
          Disagree
        </Button>
        <Button autoFocus color="secondary" onClick={onSubmit}>
          Agree
        </Button>
      </>
    )}
  >
    <Typography gutterBottom>
      <span>Are you sure you confirm the send notification?</span>
    </Typography>
    <Loading size={20} state={loading} />
  </Popup>
);

const PopupNotify: FC<INotifyPopupRow> = ({
  isOpen,
  onClose,
  loading,
  onSubmit,
}) => {
  const classes = useStyles();
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const notifyType = [
    { key: 'advertisers', name: 'Advertisers' },
    { key: 'publishers', name: 'Publishers' },
    { key: 'adv_feeds', name: 'Adv Feeds' },
    { key: 'pub_feeds', name: 'Pub Feeds' },
  ];
  const initialData = {
    type: 'advertisers',
    subject: '',
    body: '',
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title="Send notification"
    >
      <Formik
        initialValues={{ ...initialData }}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        {
          (props) => handleRender(props)
        }
      </Formik>
    </Popup>
  );

  function handleRender({
    isSubmitting,
    submitForm,
  }: FormikProps<IValues>) {
    return (
      <>
        <Form>
          <section className={classes.containerNotify}>
            <Field
              component={TextField}
              id="notify-all-type"
              name="type"
              select
              SelectProps={{ id: 'notify-all-type-select' }}
              variant="filled"
              type="text"
              margin="dense"
              label="Type"
            >
              {notifyType.map((item) => (
                <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
              ))}
            </Field>
            <Field
              component={TextField}
              id="subject"
              type="text"
              label="Subject"
              name="subject"
              variant="filled"
              fullWidth
              margin="dense"
            />
            <Field
              component={TextField}
              id="notify-all-body"
              type="textarea"
              label="Message"
              name="body"
              fullWidth
              margin="dense"
              multiline
              rows={5}
              variant="filled"
            />
          </section>
          <DialogActions>
            <Button
              autoFocus
              onClick={onClose}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting}
              autoFocus
              onClick={() => setIsOpenConfirm(true)}
              color="secondary"
            >
              Send
            </Button>
          </DialogActions>
        </Form>
        <PopupConfirm
          isOpen={isOpenConfirm}
          onClose={setIsOpenConfirm}
          loading={loading}
          onSubmit={submitForm}
        />
      </>
    );
  }

  function handleValidate(values: Record<string, string | boolean | number | undefined | string[] | any>) {
    const errors: Partial<Record<string, string>> = {};
    const requiredFields = ['subject', 'body'];

    requiredFields.forEach((field) => {
      if (!values[field] && values[field] === '') {
        errors[field] = 'Required';
        setIsOpenConfirm(false);
      }
    });

    return errors;
  }

  async function handleSubmit(values: IValues, helpers: FormikHelpers<IValues>) {
    helpers.setSubmitting(true);

    const { success, message } = await onSubmit({ ...values });
    helpers.setSubmitting(false);

    if (!success) {
      console.error(message);
    } else {
      setIsOpenConfirm(false);
      onClose();
    }
  }
};

export default PopupNotify;
