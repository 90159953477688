import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  containerIcon: {
    color: '#fff',
    opacity: 0.87,
  },
  icon: {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  iconMenu: {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
    opacity: 0.87,
    marginRight: theme.spacing(2),
  },
  menuItem: {
    width: '170px',
  },
  text: {
    fontSize: '13px',
    opacity: 0.87,
  },
  iconImage: {
    width: '30px',
    height: '30px',
    borderRadius: '24px',
  },
}));

export default useStyles;
