import { isRole } from '@/config/user';
import { ADMIN, TRADE, ADV } from '@/constants/role';
import { TypeNumberEnum } from '@/utils/number/types';
import {
  DataEventsEnum, DataReportsEnum, DataAdvertisersEnum, DataPublishersEnum,
  DataJobsEnum, TypeStatisticsEnum, DataCampaignsEnum, DataTqmEnum, DataImportEnum, DataExportEnum,
} from './types';

// eslint-disable-next-line no-nested-ternary
export const listDataEvents = () => ((isRole(ADMIN) || isRole(TRADE)) ? [
  { key: DataEventsEnum.COST_CPC, value: 'Cost CPC' },
  { key: DataEventsEnum.REV, value: 'Revenue' },
  { key: DataEventsEnum.SPEND_CPA, value: 'Spend CPA' },
  { key: DataEventsEnum.SPEND_CPC, value: 'Spend CPC' },
  { key: DataEventsEnum.COUNT, value: 'Clicks Count' },
] : (isRole(ADV) ? [
  { key: DataEventsEnum.SPEND_CPC, value: 'Spend CPC' },
  { key: DataEventsEnum.COUNT, value: 'Clicks Count' },
] : [
  { key: DataEventsEnum.COST_CPC, value: 'Cost CPC' },
  { key: DataEventsEnum.COUNT, value: 'Clicks Count' },
]));

// eslint-disable-next-line no-nested-ternary
export const listDataReports = () => ((isRole(ADMIN) || isRole(TRADE)) ? [
  { key: DataReportsEnum.CLICKS, value: 'Clicks' },
  { key: DataReportsEnum.REVENUE, value: 'Revenue' },
  { key: DataReportsEnum.SPEND_CPC, value: 'Spend CPC' },
  { key: DataReportsEnum.AVG_CPC, value: 'Avg CPC' },
  { key: DataReportsEnum.CR_P, value: 'CR%' },
  { key: DataReportsEnum.TQF_CR, value: 'Intent CR' },
  { key: DataReportsEnum.ROI, value: 'ROI' },
  { key: DataReportsEnum.POI_P, value: 'ROI%' },
] : (isRole(ADV) ? [
  { key: DataReportsEnum.SPEND_CPC, value: 'Rev' },
  { key: DataReportsEnum.CLICKS, value: 'Clicks' },
  { key: DataReportsEnum.CR_P, value: 'CR%' },
] : [
  { key: DataReportsEnum.EARN, value: 'Cost' },
  { key: DataReportsEnum.CLICKS_PUB, value: 'Clicks' },
  { key: DataReportsEnum.CR_P, value: 'CR%' },
]));

export const listDataAdvertisers = [
  { key: DataAdvertisersEnum.REVENUE, value: 'Revenue' },
  { key: DataAdvertisersEnum.DELTA_REV_P, value: 'Δ Rev%' },
];

export const listDataPublishers = [
  { key: DataPublishersEnum.COST, value: 'Cost' },
  { key: DataPublishersEnum.DELTA_COST_P, value: 'Δ Cost%' },
];

export const listDataJobs = () => ((isRole(ADMIN) || isRole(TRADE)) ? [
  { key: DataJobsEnum.CPA, value: 'CPA' },
  { key: DataJobsEnum.CPC, value: 'CPC' },
  { key: DataJobsEnum.NET_CPC, value: 'Net CPC' },
  { key: DataJobsEnum.COUNT, value: 'Job Count' },
] : [
  { key: DataJobsEnum.CPA, value: 'CPA' },
  { key: DataJobsEnum.CPC, value: 'CPC' },
  { key: DataJobsEnum.COUNT, value: 'Job Count' },
]);

export const listDataCampaigns = [
  { key: DataCampaignsEnum.COUNT, value: 'Campaigns Count' },
  { key: DataCampaignsEnum.COUNT_JOBS, value: 'Count # of jobs by Campaign' },
];

export const listDataTqm = [
  { key: DataTqmEnum.COUNT, value: 'Intent Count' },
  { key: DataTqmEnum.TQF_CR, value: 'Intent CR' },
];

export const listDataImport = [
  { key: DataImportEnum.MARGIN, value: 'Base Margin%' },
  { key: DataImportEnum.JOBS_COUNT, value: 'Job Count by Import' },
  { key: DataImportEnum.BUDGET, value: 'Budget/IO' },
  { key: DataImportEnum.YREVENUE, value: 'YRev' },
  { key: DataImportEnum.MTD_REVENUE, value: 'MTD' },
  { key: DataImportEnum.EOM, value: 'Projected' },
  { key: DataImportEnum.YROI, value: 'ROI%' },
  { key: DataImportEnum.CR, value: 'CR%' },
  { key: DataImportEnum.AVG_CPC, value: 'Avg CPC' },
];

export const listDataExport = [
  { key: DataExportEnum.JOBS_COUNT, value: '# of jobs by Export' },
  { key: DataExportEnum.MIN_CPC_NORM, value: 'Min CPC Normalizer' },
  { key: DataExportEnum.MAX_CPC, value: 'Max CPC Norm' },
  { key: DataExportEnum.MIN_CPC, value: 'Min CPC Filter' },
];

// eslint-disable-next-line no-nested-ternary
export const listTypeStatistics = () => ((isRole(ADMIN) || isRole(TRADE)) ? [
  { key: TypeStatisticsEnum.EVENTS, value: 'Events' },
  { key: TypeStatisticsEnum.REPORTS, value: 'Reports' },
  { key: TypeStatisticsEnum.ADVERTISERS, value: 'Advertisers' },
  { key: TypeStatisticsEnum.PUBLISHERS, value: 'Publishers' },
  { key: TypeStatisticsEnum.JOBS, value: 'Jobs' },
  { key: TypeStatisticsEnum.CAMPAIGNS, value: 'Campaigns' },
  { key: TypeStatisticsEnum.TQM, value: 'TQM' },
  { key: TypeStatisticsEnum.IMPORT, value: 'Import' },
  { key: TypeStatisticsEnum.EXPORT, value: 'Export' },
] : (isRole(ADV) ? [
  { key: TypeStatisticsEnum.EVENTS, value: 'Events' },
  { key: TypeStatisticsEnum.REPORTS, value: 'Reports' },
  { key: TypeStatisticsEnum.ADVERTISERS, value: 'Advertisers' },
  { key: TypeStatisticsEnum.JOBS, value: 'Jobs' },
] : [
  { key: TypeStatisticsEnum.EVENTS, value: 'Events' },
  { key: TypeStatisticsEnum.REPORTS, value: 'Reports' },
  { key: TypeStatisticsEnum.PUBLISHERS, value: 'Publishers' },
]));

export const Config = (type: string) => {
  switch (type) {
    case 'event':
      return {
        [DataEventsEnum.COST_CPC]: TypeNumberEnum.CURRENCY,
        [DataEventsEnum.REV]: TypeNumberEnum.CURRENCY,
        [DataEventsEnum.SPEND_CPA]: TypeNumberEnum.CURRENCY,
        [DataEventsEnum.SPEND_CPC]: TypeNumberEnum.CURRENCY,
        [DataEventsEnum.COUNT]: TypeNumberEnum.NUMBER,
      };
    case 'report':
      return {
        [DataReportsEnum.CLICKS]: TypeNumberEnum.NUMBER,
        [DataReportsEnum.CLICKS_PUB]: TypeNumberEnum.NUMBER,
        [DataReportsEnum.REVENUE]: TypeNumberEnum.CURRENCY,
        [DataReportsEnum.SPEND_CPC]: TypeNumberEnum.CURRENCY,
        [DataReportsEnum.AVG_CPC]: TypeNumberEnum.CURRENCY,
        [DataReportsEnum.CR_P]: TypeNumberEnum.PERCENT,
        [DataReportsEnum.TQF_CR]: TypeNumberEnum.CURRENCY,
        [DataReportsEnum.ROI]: TypeNumberEnum.CURRENCY,
        [DataReportsEnum.POI_P]: TypeNumberEnum.PERCENT,
      };
    case 'advertiser':
      return {
        [DataAdvertisersEnum.REVENUE]: TypeNumberEnum.CURRENCY,
        [DataAdvertisersEnum.DELTA_REV_P]: TypeNumberEnum.PERCENT,
      };
    case 'publisher':
      return {
        [DataPublishersEnum.COST]: TypeNumberEnum.CURRENCY,
        [DataPublishersEnum.DELTA_COST_P]: TypeNumberEnum.PERCENT,
      };
    case 'jobs':
      return {
        [DataJobsEnum.CPA]: TypeNumberEnum.CURRENCY,
        [DataJobsEnum.CPC]: TypeNumberEnum.CURRENCY,
        [DataJobsEnum.NET_CPC]: TypeNumberEnum.CURRENCY,
        [DataJobsEnum.COUNT]: TypeNumberEnum.NUMBER,
      };
    case 'campaign':
      return {
        [DataCampaignsEnum.COUNT]: TypeNumberEnum.NUMBER,
        [DataCampaignsEnum.COUNT_JOBS]: TypeNumberEnum.NUMBER,
      };
    case 'tqm':
      return {
        [DataTqmEnum.COUNT]: TypeNumberEnum.NUMBER,
        [DataTqmEnum.TQF_CR]: TypeNumberEnum.NUMBER,
      };
    case 'import':
      return {
        [DataImportEnum.MARGIN]: TypeNumberEnum.PERCENT,
        [DataImportEnum.JOBS_COUNT]: TypeNumberEnum.NUMBER,
        [DataImportEnum.BUDGET]: TypeNumberEnum.NUMBER,
        [DataImportEnum.YREVENUE]: TypeNumberEnum.CURRENCY,
        [DataImportEnum.MTD_REVENUE]: TypeNumberEnum.CURRENCY,
        [DataImportEnum.EOM]: TypeNumberEnum.CURRENCY,
        [DataImportEnum.YROI]: TypeNumberEnum.PERCENT,
        [DataImportEnum.CR]: TypeNumberEnum.PERCENT,
        [DataImportEnum.AVG_CPC]: TypeNumberEnum.CURRENCY,
      };
    case 'export':
      return {
        [DataExportEnum.JOBS_COUNT]: TypeNumberEnum.NUMBER,
        [DataExportEnum.MIN_CPC_NORM]: TypeNumberEnum.NUMBER,
        [DataExportEnum.MAX_CPC]: TypeNumberEnum.NUMBER,
        [DataExportEnum.MIN_CPC]: TypeNumberEnum.NUMBER,
      };
    default:
      return {};
  }
};
