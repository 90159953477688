/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC, useContext, useMemo, useState,
} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButtonUI from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logoIcon from '@/theme/icons/logo.png';
import logoIcon2 from '@/theme/icons/realJobs.png';
import { RoleChange } from '@/components';
import { UserContext } from '@/providers/UserProvider';
import { PanelPerformance } from '@/app/Performance/PanelPerformance';
import { useQuery } from '@apollo/react-hooks';
import * as ROUTES from '@/constants/routes';
import get from 'lodash/get';
import { GET_GLO_OPTIONS } from '@/gql/query/common';
import { useHistory, useLocation } from 'react-router';
import Link from '@material-ui/core/Link';
import {
  ADMIN, TRADE,
} from '@/constants/role';
import { isRole } from '@/config/user';
import Box from '@material-ui/core/Box';
import { SETTINGS_PROFILE } from '@/constants/routes';
import WidjetCardStatistics from '@/components/CardStatistics/WidjetCardStatistics/WidjetCardStatistics';
import clsx from 'clsx';
import { TypeStatisticsEnum } from '@/components/CardStatistics/types';
import { mdiBellOutline as NotificationIcon, mdiEmailSend as EmailIcon } from '@mdi/js';
import { IconButton } from '@/ui';
import Badge from '@material-ui/core/Badge';
import { REALJOBS } from '@/constants/domains';
import { NotifyAll } from '@/components/NotifyAll';
import useStyles from './styles';
import { Profile } from './Profile';
import TaskNotification from '../../../components/TaskNotification/TaskNotification';

interface IProps {
  handleDrawer: () => void;
  menuItems: any;
}

const Header: FC<IProps> = ({ handleDrawer, menuItems }) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const options = get(useQuery(GET_GLO_OPTIONS), 'data.getGloOptions');
  const [activeTask, setActiveTask] = useState<number>(0);
  const [openNotification, setOpenNotification] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const showWidgets = false;
  const {
    filterByDate, compareType, dateCompare, isRealtime,
  } = useMemo(() => {
    if ([ROUTES.TRADE_MATRIX, ROUTES.PERFORMANCE_MATRIX].includes(location.pathname)) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.matrix;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.PERFORMANCE_REPORTS, ROUTES.PERFORMANCE_LOGS].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.performance.reports;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.TRAFFIC_INTENT].includes(location.pathname)
        && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.traffic.intent;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.TRAFFIC_GEO_FENCE].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.traffic.geoFence;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.TRAFFIC_VOLUME].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.traffic.volume;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.TRAFFIC_CRO].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.traffic.cro;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.TRAFFIC_PROTECTION_RECOUP].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.traffic.protectionRecoup;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.TRAFFIC_CPAC_CONVERT].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.traffic.cpacConvert;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    if ([ROUTES.TRAFFIC_ALL].includes(location.pathname)
        && (isRole(ADMIN) || isRole(TRADE))) {
      const {
        filterByDate, compareType, dateCompare, isRealtime,
      } = options.performance.tqm;
      return {
        filterByDate, compareType, dateCompare, isRealtime,
      };
    }
    const {
      filterByDate, compareType, dateCompare, isRealtime,
    } = options.performance;
    return {
      filterByDate, compareType, dateCompare, isRealtime,
    };
  }, [location.pathname, options.matrix, options.performance, options.traffic]);

  const type = useMemo(() => {
    if (location.pathname === ROUTES.TRADE_MATRIX || location.pathname === ROUTES.PERFORMANCE_MATRIX) {
      return 'matrix';
    }
    if ([ROUTES.PERFORMANCE_REPORTS, ROUTES.PERFORMANCE_LOGS].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      return 'performance.reports';
    }
    if ([ROUTES.TRAFFIC_INTENT].includes(location.pathname)
        && (isRole(ADMIN) || isRole(TRADE))) {
      return 'traffic.intent';
    }
    if ([ROUTES.TRAFFIC_GEO_FENCE].includes(location.pathname)
        && (isRole(ADMIN) || isRole(TRADE))) {
      return 'traffic.geoFence';
    }
    if ([ROUTES.TRAFFIC_VOLUME].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      return 'traffic.volume';
    }
    if ([ROUTES.TRAFFIC_CRO].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      return 'traffic.cro';
    }
    if ([ROUTES.TRAFFIC_PROTECTION_RECOUP].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      return 'traffic.protectionRecoup';
    }
    if ([ROUTES.TRAFFIC_CPAC_CONVERT].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      return 'traffic.cpacConvert';
    }
    if ([ROUTES.TRAFFIC_ALL].includes(location.pathname)
      && (isRole(ADMIN) || isRole(TRADE))) {
      return 'performance.tqm';
    }
    return 'performance';
  }, [location.pathname]);

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar className={classes.containerToolbar}>
        <section className={classes.containerElHeader}>
          <Typography className={classes.toolbar} onClick={handleDrawer}>
            <IconButtonUI
              color="inherit"
              className={classes.containerIcon}
            >
              <MenuIcon className={classes.icon} />
            </IconButtonUI>
          </Typography>
          {REALJOBS.includes(window.location.hostname) ? (
            <section className={classes.containerLogo}>
              <section className={classes.containerImageLogo}>
                <img src={logoIcon2} alt="Logo" className={classes.imageLogo} />
              </section>
            </section>
          ) : (
            <Link href="https://cmp.jobs/" target="_blank" rel="noreferrer">
              <section className={classes.containerLogo}>
                <section className={classes.containerImageLogo}>
                  <img src={logoIcon} alt="Logo" className={classes.imageLogo} />
                </section>
              </section>
            </Link>
          )}
        </section>
        <section className={clsx(classes.containerElHeader, classes.leftEl)}>
          {(isRole(ADMIN) || isRole(TRADE)) && showWidgets && (
            <section className={classes.containerWidjet}>
              <WidjetCardStatistics
                type={process.env.REACT_APP_EFC ? undefined : TypeStatisticsEnum.REPORTS}
                filterByDate={filterByDate}
                isCompare={!!dateCompare}
                compareDate={dateCompare}
                path="performance"
                id="header"
              />
            </section>
          )}
          <Typography variant="h6" className={classes.title}>
            <Box className={classes.nameCompany} onClick={() => history.push(SETTINGS_PROFILE)}>
              { !process.env.REACT_APP_EFC && (user ? (user.username || user.email) : '').toUpperCase() }
            </Box>
          </Typography>
          { !process.env.REACT_APP_EFC && (<section className={classes.containerRoleChange}><RoleChange /></section>)}
          <section className={classes.containerPanel}>
            <PanelPerformance
              filterByDate={filterByDate}
              compareType={compareType}
              initDateCompare={dateCompare}
              type={type}
              isRealtime={isRealtime}
            />
          </section>
          {(isRole(ADMIN) || isRole(TRADE)) && (
            <>
              <IconButton path={EmailIcon} onClick={handleNotify} title="Notify all" />
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                color="error"
                badgeContent={activeTask}
                classes={{ badge: classes.customBadge }}
              >
                <IconButton path={NotificationIcon} onClick={handleNotification} title="Todo tasks" />
              </Badge>
              <TaskNotification
                isOpen={openNotification}
                onClose={() => setOpenNotification(false)}
                totalActive={(value) => setActiveTask(value)}
                menuItems={menuItems}
              />
              <NotifyAll
                isOpen={openNotify}
                onClose={() => setOpenNotify(false)}
              />
            </>
          )}
          { process.env.REACT_APP_EFC && (<Profile />)}
        </section>
      </Toolbar>
    </AppBar>
  );

  function handleNotification() {
    setOpenNotification(!openNotification);
  }

  function handleNotify() {
    setOpenNotify(!openNotify);
  }
};

export default Header;
