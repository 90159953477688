import { ICatalog } from '@/gql/types/dynamicServer';
import { Filter } from '@devexpress/dx-react-grid';

export enum TypeOperationEnum {
  EQ = 'eq',
  CN = 'cn',
  GE = 'ge',
  LE = 'le',
  GT = 'gt',
  LT = 'lt',
  NE = 'ne',
  NULL = 'undefined',
}

export interface IFilterAdditional {
  operation?: TypeOperationEnum;
  catalog?: string;
  customCatalog?: {key: string; value: string}[]; // TODO: TypeOperationEnum
}

export interface IFilterConfig {
  columnName: string;
  title: string;
  filteringType: TypeFilterEnum;
  filteringAdditional: IFilterAdditional;
  isCannotRemove?: boolean;
  category?: CategoryFilterEnum;
  line?: boolean;
  dependency?: string;
  dependencyValue?: string;
  inShowMore?: boolean;
  groupName?: string;
}

export interface IFilters {
  columnName: string;
  title: string;
  value: string;
  operation: TypeOperationEnum;
  additional: IFilterAdditional;
  type: TypeFilterEnum;
  category?: CategoryFilterEnum;
  line?: boolean;
  dependency?: string;
  dependencyValue?: string;
  inShowMore?: boolean;
  groupName?: string;
}

export interface ICustomOpenButton {
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void);
}

export interface IBackButton {
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void);
}

export interface IProps {
  handleApply: (filters: IFilters[], filterType: string) => void;
  handleReset: () => void;
  filterUnion?: FilterUnionEnum;
  filterConfig: IFilterConfig[];
  filterList: Filter[];
  focusColumn?: string;
  setFocusColumn: (focusColumn: string | undefined) => void;
  CustomOpenButton?: React.FC<ICustomOpenButton>;
  isShowAdditional?: boolean;
  isShowButtons?: boolean;
  isShowMore?: boolean;
  title?: string;
  catalogsAddional?: Record<string, ICatalog[]>;
  disabledFields?: string[];
  filterInvisibility?: string[];
}

export enum TypeFilterEnum {
  SELECT = 'select',
  SELECTINPUT = 'selectinput',
  SELECTFILTER = 'selectfilter',
  CHECKBOX = 'checkbox',
  INPUT = 'input',
  BOOLEAN = 'boolean',
  DATERANGE = 'dateRange',
  AUTOCOMPLETE = 'autocomplete',
  MULTISELECT = 'multiSelect',
  MULTIAUTOCOMPLETE = 'multiAutocomplete',
}

export enum CategoryFilterEnum {
  GROUP = 'group',
  FILTER = 'filter',
}

export enum FilterUnionEnum {
  AND = 'and',
  OR = 'or',
}
