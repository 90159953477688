import editGloOptions from './options';
import {
  addNotification,
  editNotification,
  deleteNotification,
} from './notification';

const resolversLocal = {
  Mutation: {
    editGloOptions,

    addNotification,
    editNotification,
    deleteNotification,
  },
};

export default resolversLocal;
