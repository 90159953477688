/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC, useState, useMemo, useEffect,
} from 'react';
import { DateRangePicker } from 'rsuite';
// TODO: you need to use the library
import './rsuite-dark.css'; // 'rsuite/dist/styles/rsuite-default.css';
import { ValueType } from 'rsuite/lib/DateRangePicker/DateRangePicker';
import { format as fnsFormat } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/Today';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getLastNDays, getLastWeek, getYesterday } from '@/utils/date';
import { PropsI } from './types';
import useStyles from './styles';

const DateRangePickerRsuite: FC<PropsI> = ({
  value, onChange, placement = 'bottomEnd', size = 'lg', disabled = false, isOpen, setIsOpen,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dateValue = useMemo(() => (value[0] && value[1] ? value : undefined), [value]);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.containerDatePicker} onClick={handleOpen}>
        <DateRangePicker
          size="lg"
          placeholder="MM dd - MM dd, `yy"
          renderValue={format}
          className={clsx({ [classes.datePicker]: size === 'lg', [classes.datePickerXS]: size === 'xs', [classes.datePickerMD]: size === 'md' })}
          open={open}
          value={dateValue}
          onChange={onChange}
          placement={placement}
          onOk={handleOk}
          disabled={disabled}
          ranges={[{
            label: 'Today',
            value: [new Date(), new Date()],
          }, {
            label: 'Yesterday',
            value: [getYesterday(new Date()), getYesterday(new Date())],
          }, {
            label: 'Last 7 days',
            value: [getLastWeek(new Date()), getYesterday(new Date())],
          }, {
            label: 'Last 30 days',
            value: [getLastNDays(new Date(), 30), getYesterday(new Date())],
          }, {
            label: 'Last 90 days',
            value: [getLastNDays(new Date(), 90), getYesterday(new Date())],
          }]}
        />
        <IconButton size="small" className={clsx({ [classes.iconDatePicker]: ['lg', 'md'].includes(size), [classes.iconDatePickerXS]: size === 'xs' })}>
          <TodayIcon className={clsx({ [classes.iconMD]: size === 'md' })} />
        </IconButton>
      </div>
    </ClickAwayListener>
  );

  function format(value: ValueType) {
    if (value[0] && value[1]) {
      return `${fnsFormat(value[0], 'MMMM dd')} - ${fnsFormat(value[1], 'MMMM dd, `yy')}`;
    }
    return '';
  }

  function handleClose() {
    setOpen(false);
    if (typeof setIsOpen === 'function') {
      setIsOpen(false);
    }
  }

  function handleOpen() {
    if (disabled) {
      return;
    }
    setOpen(true);
  }

  function handleOk(value: ValueType) {
    setTimeout(() => {
      handleClose();
    }, 0);
  }
};

export default DateRangePickerRsuite;
