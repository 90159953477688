export enum TypeNumberEnum {
    NUMBER = 'number',
    CURRENCY = 'currency',
    PERCENT = 'percent',
}

export interface INumberObj {
    value: number;
    type: TypeNumberEnum;
    toString: () => string;
}
