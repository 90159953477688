import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    padding: theme.spacing(2),
    display: 'grid',
  },
  saveWrap: {
    textAlign: 'right',
  },
  select: {
    width: 225,
    marginBottom: theme.spacing(2),
  },
  selectM: {
    width: 145,
    marginBottom: theme.spacing(2),
    '& > div': {
      borderTopRightRadius: 0,
    },
  },
  selectS: {
    width: 80,
    marginBottom: theme.spacing(2),
    '& > div': {
      borderTopLeftRadius: 0,
    },
  },
}));

export default useStyles;
