import React, { useState, FC, createContext } from 'react';
import { STORAGE_KEY_GHOST_MODE } from '@/constants/storage';
import { getItem } from '@/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const GhostContext = createContext({ isGhostMode: false, setIsGhostMode: (ghostMode: boolean): void => {} });

const GhostProvider: FC = ({ children }) => {
  const initIsGhostMode = process.env.REACT_APP_EFC ? getItem(STORAGE_KEY_GHOST_MODE) === 'true' || false : false;
  const [isGhostMode, setIsGhostMode] = useState<boolean>(initIsGhostMode);

  return (
    <GhostContext.Provider value={{ isGhostMode, setIsGhostMode }}>
      {children}
    </GhostContext.Provider>
  );
};

export default GhostProvider;
