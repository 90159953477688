/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
} from 'react';
import { ApolloCache, InMemoryCache, useMutation } from '@apollo/client';
import { SEND_NOTIFY_ALL } from '@/gql/query/common';
import get from 'lodash/get';
import { NotificationE } from '@/ui';
import { ADD_NOTIFICATION } from '@/gql/query/notification';
import { RowI, ParamsMutationResponseI } from './PopupNotify/types';
import { PopupNotify } from './PopupNotify';

export interface IEditPopupRow {
  isOpen: boolean;
  onClose: () => void;
}

const NotifyAll: FC<IEditPopupRow> = ({
  isOpen, onClose,
}) => {
  const [addNotification] = useMutation(ADD_NOTIFICATION);
  const [sendNotify, { loading: mutationLoading }] = useMutation(SEND_NOTIFY_ALL, { update: sendNotifyData });

  return (
    <PopupNotify isOpen={isOpen} onClose={onClose} loading={mutationLoading} onSubmit={handleMutation} />
  );

  async function handleMutation(variables: RowI): Promise<ParamsMutationResponseI> {
    const node = variables as any;
    sendNotify({ variables: { node: getMessage(node) } });

    return { success: true, message: 'Setting have been Saved!' };
  }

  function getMessage(node: Record<string, string | number | Record<string, string>>) {
    return {
      type: node.type,
      subject: node.subject,
      body: node.body,
    };
  }

  async function sendNotifyData(cache: ApolloCache<InMemoryCache>, data: any) {
    const response = get(data, 'data.notifyAll') || 0;

    addNotification({
      variables: {
        message: response.message,
        status: true,
        type: response.success ? NotificationE.Success : NotificationE.Error,
      },
    });
  }
};

export default NotifyAll;
