export enum RoleE {
    ADMIN = 'admin',
    TRADE = 'trade',
    ADV = 'adv',
    PUB = 'pub',
}

export interface IUser {
    email: string;
    role: RoleE | null;
    roles: RoleE[];
    name: string;
    username: string;
    picture: string;
    config: {
        summaryColumns: string[];
        reportingFilter?: string[];
        widgetReconciliation?: string[];
    } | null;
}

export enum ThemeEnum {
    DARK = 'darkTheme',
    LIGHT = 'lightTheme',
}

export type IUserProvider = IUser | null;
