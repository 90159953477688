export interface PropsI {
  type?: NotificationE;
  isOpen?: boolean;
  onClose?: () => void;
  message?: string;
  delay?: number;
}

export enum NotificationE {
  Error = 'error',
  Success = 'success'
}

export enum NotificationMessageE {
  Error = 'Server side error'
}

// TODO: через динамик сделать
export type Maybe<T> = T | null;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: object;
  AWSDateTime: string;
};

export interface INotification {
  id: Scalars['ID'];
  message: Scalars['String'];
  status: Scalars['Boolean'];
  type: NotificationE;
}

export type IMutationAddNotificationArgs = {
  message: Scalars['String'];
  type: NotificationE;
  status: Scalars['Boolean'];
};

export type IMutationEditNotificationArgs = {
  id: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type IMutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};
