import { TypeDateE, DateCompareE } from './types';

export const listDates = [
  { key: TypeDateE.TODAY, value: 'Today' },
  { key: TypeDateE.YESTERDAY, value: 'Yesterday' },
  { key: TypeDateE.WEEK, value: 'Week' },
  { key: TypeDateE.LAST_WEEK, value: 'Last week' },
  { key: TypeDateE.LAST_MOUNTH, value: 'Last month' },
];

export const listMatrixDates = [
  { key: TypeDateE.TODAY, value: 'Today' },
  { key: TypeDateE.YESTERDAY, value: 'Yesterday' },
  { key: TypeDateE.LAST_7_DAYS, value: 'Last 7 days' },
  { key: TypeDateE.LAST_30_DAYS, value: 'Last 30 days' },
  { key: TypeDateE.LAST_90_DAYS, value: 'Last 90 days' },
];

export const listCompare = [
  { key: DateCompareE.DAY, value: 'Day Before' },
  { key: DateCompareE.WEEK, value: 'Prior week' },
  { key: DateCompareE.MONTH_DOW, value: 'Prior Month, DOW' },
  { key: DateCompareE.MONTH, value: 'Prior month, Date' },
  { key: DateCompareE.CUSTOM, value: 'Custom' },
];

export const listCompareRT = [
  { key: DateCompareE.DAY, value: 'Yesterday RT' },
  { key: DateCompareE.WEEK, value: 'Last Week RT' },
  { key: DateCompareE.MONTH_DOW, value: 'Last Month RT' },
  { key: DateCompareE.CUSTOM, value: 'Custom' },
];
