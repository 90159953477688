/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import IconButtonUI from '@material-ui/core/IconButton';
import IconUI from '@mdi/react';
import { mdiPin as PinIcon } from '@mdi/js';
import useStyles from './styles';

const SidebarItem: FC<any> = ({
  depthStep = 8, depth = 0, item, open, path, ...rest
}) => {
  const classes = useStyles();
  const isActive = path.includes(item.key);
  const [collapsed, setCollapsed] = React.useState(!isActive);
  const {
    name, items, Icon, onClick: onClickProp,
  } = item;
  const isChildActive = isActive && Array.isArray(items) ? items.filter((r) => path.includes(r.key)).length > 0 : false;
  const [isChildPrev, setIsChildPrev] = React.useState(isChildActive);

  useEffect(() => {
    if (!open && !collapsed) {
      setCollapsed(true);
    }
    if (collapsed && open && isChildActive && isChildActive !== isChildPrev) {
      setCollapsed(false);
      setIsChildPrev(isChildActive);
    }
    if (!isChildActive) {
      setIsChildPrev(false);
    }
  }, [collapsed, open, isChildActive, isChildPrev, isActive, item]);

  function toggleCollapse() {
    if (collapsed && open && !isChildActive) {
      setIsChildPrev(true);
    }
    setCollapsed((prevValue) => !prevValue);
  }

  function onClickLink(e: any) {
    e.preventDefault();
  }

  function onClick(e: any) {
    if (onClickProp && Array.isArray(items) && collapsed) {
      onClickProp(e, item);
    } else if (onClickProp && !Array.isArray(items)) {
      onClickProp(e, item);
    }
    if (Array.isArray(items)) {
      toggleCollapse();
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandLessIcon
        className={clsx(
          classes.sidebarItemExpandArrow,
          classes.sidebarItemExpandArrowExpanded,
        )}
      />
    ) : (
      <ExpandMoreIcon className={classes.sidebarItemExpandArrow} />
    );
  }

  return (
    <div>
      <ListItem
        className={clsx(
          classes.containerListItem,
          classes.sidebarItem,
        )}
        onClick={onClick}
        button
        dense
        {...rest}
      >
        <a
          href={item.key}
          onClick={onClickLink}
          style={{ paddingLeft: (depth * depthStep) }}
          className={clsx(
            classes.sidebarItemContent,
            classes.sidebarItemLink, {
              [classes.sidebarItemNotActive]: !path.includes(item.key),
              [classes.sidebarItemActive]: path.includes(item.key),
              [classes.sidebarItemInactiveLinks]: item.inactive,
            },
          )}
        >
          { Icon }
          <div
            className={clsx(classes.sidebarItemText,
              { [classes.sidebarItemTextNone]: !open })}
          >
            <div className={classes.sidebarItem}>
              <div>{name}</div>
              {expandIcon}
            </div>
          </div>
        </a>
      </ListItem>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem) => (
              <React.Fragment key={`${subItem.key}-SidebarItem`}>
                <SidebarItem
                  depth={subItem.depth}
                  depthStep={depthStep}
                  item={subItem}
                  open={open}
                  path={path}
                />
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </div>
  );
};

const Sidebar: FC<any> = ({
  items, setOpen, setOpenM, open, openM, depthStep, path,
}) => {
  const classes = useStyles();
  let timeoutLeave: ReturnType<typeof setTimeout>;

  function handleMouseLeave() {
    timeoutLeave = setTimeout(() => {
      sidebarMouseLeave();
    }, 100);
  }

  function handlePinEnter() {
    if (timeoutLeave) {
      clearTimeout(timeoutLeave);
    }
  }

  function sidebarMouseEnter() {
    if (!open && !openM) {
      setOpen(true);
    }
  }

  function sidebarMouseLeave() {
    if (open && !openM) {
      setOpen(false);
    }
  }

  function handleDrawer() {
    setOpenM(!openM);
  }

  return (
    <div>
      <IconButtonUI
        onClick={handleDrawer}
        color="inherit"
        className={classes.containerPin}
        size="small"
        onMouseEnter={handlePinEnter}
        onMouseLeave={sidebarMouseLeave}
      >
        <IconUI
          path={PinIcon}
          size={0.7}
          className={clsx(classes.pinIcon, { [classes.attach]: openM })}
        />
      </IconButtonUI>
      <List
        disablePadding
        dense
        className={classes.containerList}
        onMouseEnter={sidebarMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {items.map((sidebarItem: any) => (
          <React.Fragment key={`${sidebarItem.key}-Sidebar`}>
            <SidebarItem
              depthStep={depthStep}
              depth={items.depth}
              item={sidebarItem}
              open={open}
              path={path}
            />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default Sidebar;
