import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    height: '40px',
    '& > *': {
      marginRight: '12px',
    },
  },
  containerOverflow: {
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
}));

export default useStyles;
