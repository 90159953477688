export const STORAGE_KEY_TOKEN = 'GLO-TOKEN';
export const STORAGE_KEY_ROLE = 'GLO-ROLE';
export const STORAGE_KEY_THEME = 'GLO-THEME';
export const STORAGE_KEY_GHOST_MODE = 'GLO-GHOST-MODE';
export const STORAGE_KEY_CONFIG_TABLE = 'GLO-CONFIG-TABLE';
export const STORAGE_KEY_CONFIG_CHART = 'GLO-CONFIG-CHART';
export const STORAGE_KEY_COPY_CAMPAIGNS = 'GLO-COPY-CAMPAIGNS';
export const STORAGE_KEY_COPY_VALUES = 'GLO-COPY-VALUES';
export const STORAGE_KEY_CHART_JOBS = 'GLO-CONFIG-CHART-JOBS-1';
export const STORAGE_KEY_CHART_RECONCILIATION = 'GLO-CONFIG-CHART-RECONCILIATION';
export const STORAGE_KEY_CHART_EVENTS = 'GLO-CONFIG-CHART-EVENTS';
export const STORAGE_KEY_CHART_PUB_EVENTS = 'GLO-CONFIG-CHART-PUB-EVENTS';
export const STORAGE_KEY_CHART_REPORTS = 'GLO-CONFIG-CHART-REPORTS';
export const STORAGE_KEY_CHART_MATRIX = 'GLO-CONFIG-CHART-MATRIX';
export const STORAGE_KEY_CHART_CAMPAIGN_EDIT = 'GLO-CONFIG-CHART-CAMPAIGN-EDIT';
export const STORAGE_KEY_CHART_PUB_REPORTS = 'GLO-CONFIG-CHART-PUB-REPORTS';
export const STORAGE_KEY_CONFIG_CHART_PP = 'GLO-CONFIG-CHART-PP';
export const STORAGE_KEY_CONFIG_CHART_DASHBOARD = 'GLO-CONFIG-CHART-DASHBOARD';
export const STORAGE_KEY_CONFIG_STATISTICS = 'GLO-CONFIG-STATISTICS-4';
export const STORAGE_KEY_IS_AUTH = 'GLO-IS-AUTH';
export const STORAGE_KEY_HIDDEN_TABLE = 'GLO-HIDDEN-TABLE-1';
export const STORAGE_KEY_CHECKBOXS = 'GLO-CHECKBOXS';
export const STORAGE_KEY_CHECKBOXES_MATRIX = 'GLO-CHECKBOXES-MATRIX';
export const STORAGE_KEY_CHECKBOXES_CAMPAIGN = 'GLO-CHECKBOXES-CAMPAIGN';
export const STORAGE_KEY_DISPLAY = 'GLO-DISPLAY';
export const STORAGE_KEY_BREAKDOWN_BY = 'GLO-BREAKDOWN-BY';
export const STORAGE_KEY_FILTERS_PANEL = 'GLO-FILTERS-PANEL';
export const STORAGE_KEY_CHECKBOXS_PP = 'GLO-CHECKBOXS-PP';
export const STORAGE_KEY_HISTORY_LIST = 'GLO-CONFIG-HISTORY-LIST';
export const STORAGE_KEY_RETURN_PATH = 'GLO-RETURN-PATH';
export const STORAGE_KEY_MATRIX_VIEW = 'GLO-MATRIX-VIEW';
export const STORAGE_KEY_MATRIX_HIGHLIGHT = 'GLO-MATRIX-HIGHLIGHT';
export const STORAGE_KEY_REPORT_SETTINGS = 'GLO-REPORT-SETTINGS';
