import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Box from '@material-ui/core/Box';
import { getRole } from '@/config/user';
import clsx from 'clsx';
import compact from 'lodash/compact';
import useStyles from './styles';
import config from '../config';

const Navigation: FC<{className?: string}> = ({ className }) => {
  const classes = useStyles();
  const history = useHistory();

  const key = compact(history.location.pathname.split('/'))[0];
  const configTabs = config(getRole());
  const tabs = useMemo(() => {
    const tab = configTabs.find((item) => item.key === key)?.items || [];
    return tab.map((item) => ({
      ...item,
      route: item.items && item.items.length ? item.items[0].key : item.key,
    }));
  }, [configTabs, key]);

  return (
    <section className={clsx(classes.container, className || classes.dafaultBackground)}>
      {tabs.map((item, index) => (
        <Box
          key={item.key}
          className={clsx(classes.navigate, { [classes.active]: history.location.pathname.includes(item.key) })}
          onClick={() => history.push(item.route)}
        >
          <section>{item.name}</section>
          {
            tabs.length - 1 > index && (<NavigateNextIcon fontSize="small" />)
          }
        </Box>
      ))}
    </section>
  );
};

export default Navigation;
