/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
  FC, useState, useEffect, useCallback,
} from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Formik, Form, FormikHelpers, FormikProps, Field,
} from 'formik';
import {
  TextField, CheckboxWithLabel, Switch,
} from 'formik-material-ui';

import DialogActions from '@material-ui/core/DialogActions';
import { Popup } from '@/ui';
import {
  ColumnExtensionI, RowI, TypeFieldEnum,
} from '@/components/TableUniversal/types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ICatalog } from '@/gql/types/dynamicServer';
import FormikAutocomplete from '@/ui/FormikAutocomplete/FormikAutocomplete';
import FormikAutocompleteWithButton from '@/ui/FormikAutocompleteWithButton/FormikAutocompleteWithButton';
import { Link } from '@material-ui/core';
import { getItem, saveItem } from '@/utils';
import { STORAGE_KEY_COPY_VALUES } from '@/constants/storage';
import { PropsI } from './types';
import useStyles from '../styles';

const PopupRow: FC<PropsI> = ({
  isOpen, onClose, onSubmit, row, fields, title,
  catalogs, onOpenClone = () => {}, isClone, isCopyValues,
}) => {
  const [initialData, setInitialData] = useState({});
  const memoLoadData = useCallback(loadData, [row]);
  const classes = useStyles();
  const [copied, setCopied] = useState<string>(getItem(STORAGE_KEY_COPY_VALUES) || '');
  const [copyText, setCopyText] = useState<string>('Copy');

  useEffect(() => {
    memoLoadData(row as RowI);
  }, [memoLoadData, row]);
  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title={title}
    >
      <Formik
        initialValues={initialData}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        {
          (props) => handleRender(props)
        }
      </Formik>
    </Popup>
  );

  function handleRender({
    isSubmitting,
    submitForm,
    setFieldValue,
    values,
  }: FormikProps<RowI>) {
    return (
      <Form>
        <section className={classes.containerAddRow}>
          {fields.map((field) => (
            getFieldByType(field, values, setFieldValue)
          ))}
        </section>
        <DialogActions>
          <Button
            autoFocus
            onClick={onClose}
            color="secondary"
          >
            Cancel
          </Button>
          {
            isClone && (
              <Button
                disabled={isSubmitting}
                autoFocus
                onClick={onOpenClone}
                color="secondary"
              >
                Clone
              </Button>
            )
          }
          <Button
            disabled={isSubmitting}
            autoFocus
            onClick={submitForm}
            color="secondary"
          >
            Save
          </Button>
        </DialogActions>
      </Form>
    );
  }

  function getFieldByType(field: ColumnExtensionI, values: RowI, setFieldValue: any) {
    switch (field.type) {
      case TypeFieldEnum.BOOLEAN:
        return (
          <div key={field.columnName}>
            <Field
              component={CheckboxWithLabel}
              id={field.columnName}
              type="checkbox"
              required={field.required}
              name={field.columnName}
              Label={{ label: field.title }}
            />
          </div>
        );
      case TypeFieldEnum.SELECT:
        const selectList = field.additional && catalogs && Array.isArray(catalogs[field.additional])
          ? catalogs[field.additional]
          : [];

        return (
          <div key={field.columnName}>
            <Field
              component={TextField}
              id={field.columnName}
              name={field.columnName}
              required={field.required}
              select
              SelectProps={{ id: `${field.columnName}-select` }}
              variant="filled"
              type="text"
              margin="dense"
              label={field.title}
              className={classes.component}
            >
              {selectList.map((item: ICatalog) => (
                <MenuItem key={item?.key} value={item?.name || ''}>{item?.name || ''}</MenuItem>
              ))}
            </Field>
          </div>
        );
      case TypeFieldEnum.SELECT_KEY:
        const selectKeyList = field.additional && catalogs && Array.isArray(catalogs[field.additional])
          ? catalogs[field.additional]
          : [];

        return (
          <div key={field.columnName}>
            <Field
              component={TextField}
              id={field.columnName}
              name={field.columnName}
              required={field.required}
              select
              SelectProps={{ id: `${field.columnName}-select` }}
              variant="filled"
              type="text"
              margin="dense"
              label={field.title}
              className={classes.component}
            >
              {selectKeyList.map((item: ICatalog) => (
                <MenuItem key={item?.key} value={item?.key || ''}>{item?.name || ''}</MenuItem>
              ))}
            </Field>
          </div>
        );
      case TypeFieldEnum.AUTOCOMPLETE:
        const autoList = field.additional && catalogs && Array.isArray(catalogs[field.additional])
          ? catalogs[field.additional]
          : [];

        return (
          <div key={field.columnName}>
            <Field
              name={field.columnName}
              component={FormikAutocomplete}
              options={autoList}
              textFieldProps={{
                fullWidth: true,
                margin: 'dense',
                variant: 'filled',
                required: field.required,
                label: field.title,
              }}
              id={field.columnName}
              required={field.required}
            />
          </div>
        );
      case TypeFieldEnum.MULTISELECT:
        const multiSelectList = field.additional && catalogs && Array.isArray(catalogs[field.additional])
          ? catalogs[field.additional]
          : [];

        return (
          <div key={field.columnName}>
            <Field
              component={TextField}
              id={field.columnName}
              name={field.columnName}
              required={field.required}
              select
              multiple
              inputProps={{ name: field.columnName, id: field.columnName }}
              SelectProps={{ id: `${field.columnName}-select`, multiple: true }}
              variant="filled"
              type="text"
              margin="dense"
              label={field.title}
              className={classes.component}
            >
              {multiSelectList.map((item: ICatalog) => (
                <MenuItem key={item?.key} value={item?.name || ''}>{item?.name || ''}</MenuItem>
              ))}
            </Field>
          </div>
        );
      case TypeFieldEnum.MULTIAUTOCOMPLETE:
        const multiAutoList = field.additional && catalogs && Array.isArray(catalogs[field.additional])
          ? catalogs[field.additional]
          : [];
        return (
          <div key={field.columnName}>
            <Field
              name={field.columnName}
              component={FormikAutocomplete}
              options={multiAutoList}
              textFieldProps={{
                margin: 'dense',
                variant: 'filled',
                required: field.required,
                label: field.title,
              }}
              id={field.columnName}
              required={field.required}
              className={classes.component}
              multiple
              limitTags={1}
            />
          </div>
        );
      case TypeFieldEnum.MULTIAUTOCOMPLETEKEY:
        const multiAutoListKey = field.additional && catalogs && Array.isArray(catalogs[field.additional])
          ? catalogs[field.additional]
          : [];

        const jatList = catalogs && Array.isArray(catalogs.jatRating) ? catalogs.jatRating : [];
        const selectedJatRating = jatList.filter((item) => item?.key === row?.jatRating)[0]?.jatRating;
        const jatRating = field.additional && ['groupsCampaigns'].includes(field.additional) ? selectedJatRating : -1;
        const copiedEL = copied ? JSON.parse(copied) : [];
        return (
          <div key={field.columnName}>
            <Field
              name={field.columnName}
              component={FormikAutocompleteWithButton}
              options={multiAutoListKey}
              textFieldProps={{
                margin: 'dense',
                variant: 'filled',
                required: field.required,
                label: field.title,
              }}
              id={field.columnName}
              required={field.required}
              className={classes.component}
              jatRating={jatRating}
              multiple
              limitTags={1}
            />
            {isCopyValues && (
              <div className={classes.editAction}>
                <Link
                  className={classes.editLink}
                  onClick={
                  () => {
                    copyValues(values, field.columnName);
                  }
                }
                >
                  {copyText}
                </Link>
                {copiedEL[field.columnName] && (
                  <Link
                    className={classes.editLink}
                    onClick={
                      () => {
                        setFieldValue(field.columnName, copiedEL[field.columnName] ? copiedEL[field.columnName] : []);
                      }
                    }
                  >
                    Paste
                  </Link>
                )}
              </div>
            )}
          </div>
        );
      case TypeFieldEnum.SWITCH:
        return (
          <section className={classes.containerSwitch}>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Field
                    component={Switch}
                    id={field.columnName}
                    name={field.columnName}
                    type="checkbox"
                  />
                </Grid>
                <Grid item>{field.title}</Grid>
              </Grid>
            </Typography>
          </section>
        );
      default:
        return (
          <div key={field.columnName}>
            <Field
              component={TextField}
              id={field.columnName}
              required={field.required}
              type={field.type}
              label={field.title}
              name={field.columnName}
              margin="dense"
              variant="filled"
              className={classes.component}
            />
          </div>
        );
    }
  }

  function copyValues(values: RowI, field: string) {
    saveItem(STORAGE_KEY_COPY_VALUES, JSON.stringify({ [field]: values[field] }));
    setCopied(JSON.stringify({ [field]: values[field] }));
    setCopyText('Copied');
    setTimeout(() => {
      setCopyText('Copy');
    }, 1000);
  }

  function loadData(row: RowI) {
    const init = {};

    fields.forEach((field: ColumnExtensionI) => {
      const getValue = (type: string, value: number | string | boolean | void | object) => {
        switch (type) {
          case TypeFieldEnum.SELECT:
            return value === null || value === undefined ? '' : value;
          case TypeFieldEnum.MULTISELECT:
            return value === null || value === undefined ? [] : (value as string).split(', ');
          case TypeFieldEnum.MULTIAUTOCOMPLETE:
            const catalog = field.additional && catalogs && Array.isArray(catalogs[field.additional])
              ? catalogs[field.additional]
              : [];
            return value === null || value === undefined ? [] : (value as string).split(', ')
              .map((item) => catalog.find((el: ICatalog) => el.name === item))
              .filter((item) => item);
          case TypeFieldEnum.MULTIAUTOCOMPLETEKEY:
            const catalogKey = field.additional && catalogs && Array.isArray(catalogs[field.additional])
              ? catalogs[field.additional]
              : [];
            return value === null || value === undefined ? [] : (value as string).split(', ')
              .map((item) => catalogKey.find((el: ICatalog) => el.name?.split('/')[0].trim() === item))
              .filter((item) => item);
          default: return value === null ? undefined : value;
        }
      };

      init[field.columnName] = getValue(field.type, row ? row[field.columnName] : undefined);
    });

    setInitialData(init);
  }

  async function handleSubmit(values: RowI, helpers: FormikHelpers<RowI>) {
    helpers.setSubmitting(true);
    const { success, message } = await onSubmit(values);
    helpers.setSubmitting(false);

    if (!success) {
      console.error(message);
    } else {
      onClose();
    }
  }

  function handleValidate(values: Record<string, string | undefined>) {
    const errors: Partial<RowI> = {};
    const valList = [undefined, ''];
    fields.forEach((field: ColumnExtensionI) => {
      const value = values[field.columnName];

      if (field.required && valList.includes(value)) {
        errors[field.columnName] = 'Required';
      }
    });

    return errors;
  }
};

export default PopupRow;
