import { Filter } from '@devexpress/dx-react-grid';

export enum TypeStatisticsEnum {
    EVENTS = 'events',
    REPORTS = 'reports',
    ADVERTISERS = 'advertisers',
    PUBLISHERS = 'publishers',
    JOBS = 'jobs',
    CAMPAIGNS = 'campaigns',
    TQM = 'tqm',
    IMPORT = 'import',
    EXPORT = 'export',
}

export enum DataEventsEnum {
    COST_CPC = 'costCpc',
    REV = 'rev',
    SPEND_CPA = 'spendCpa',
    SPEND_CPC = 'spendCpc',
    COUNT = 'type',
}

export enum DataReportsEnum {
    CLICKS = 'events',
    CLICKS_PUB = 'eventsPub',
    REVENUE = 'rev',
    SPEND_CPC = 'cpc',
    AVG_CPC = 'avgCpc',
    CR_P = 'cr',
    TQF_CR = 'tqfCr',
    ROI = 'roi',
    POI_P = 'roiPercent',
    EARN = 'cost',
}

export enum DataAdvertisersEnum {
    REVENUE = 'revenue',
    DELTA_REV_P = 'deltaRevP',
}

export enum DataPublishersEnum {
    COST = 'cost',
    DELTA_COST_P = 'deltaCostP',
}

export enum DataJobsEnum {
    CPA = 'cpa',
    CPC = 'cpc',
    NET_CPC = 'netCostCpc',
    COUNT = 'count'
}

export enum DataCampaignsEnum {
    COUNT = 'id',
    COUNT_JOBS = 'jobsCount',
}

export enum DataTqmEnum {
    COUNT = 'id',
    TQF_CR = 'cr',
}

export enum DataImportEnum {
    MARGIN = 'margin',
    JOBS_COUNT = 'jobsCount',
    BUDGET = 'budget',
    YREVENUE = 'yrevenue',
    MTD_REVENUE = 'mtdRevenue',
    EOM = 'eom',
    YROI = 'yroi',
    CR = 'cr',
    AVG_CPC = 'avgCpc',
}

export enum DataExportEnum {
    JOBS_COUNT = 'jobsCount',
    MIN_CPC_NORM = 'minCpcNorm',
    MAX_CPC = 'maxCpc',
    MIN_CPC = 'minCpc',
}

export enum TypeDisplayEnum {
    DEFAULT = 'default',
    HIGHLIGHN_NEGATIVE = 'highlightNegative',
    HIGHLIGHN_NIL = 'highlightNil',
}

export type TypeStatistics = DataEventsEnum | DataReportsEnum | DataAdvertisersEnum | DataPublishersEnum |
    DataJobsEnum | DataCampaignsEnum | DataTqmEnum | DataImportEnum | DataExportEnum;

export interface IFields {
    id?: string;
    field?: TypeStatistics;
    type?: TypeStatisticsEnum;
}

export interface ITemplate {
    loading: boolean;
    title: string;
    statistics: string | JSX.Element;
    listData: {
      key: TypeStatistics;
      value: string;
    }[];
    fields: IFields;
    setFields: (fields: IFields) => void;
    isRemove?: boolean;
    handleRemove?: (id: string) => void;
    handleEdit?: (id: string, fields: IFields) => void;
    color?: string;
}

export interface IStatistics {
    id: string;
    filterByDate: Filter[];
    fields: IFields;
    field?: TypeStatistics;
    setFields: (fields: IFields) => void;
    isRemove?: boolean;
    handleRemove?: (id: string) => void;
    handleEdit?: (id: string, fields: IFields) => void;
    isCompare?: boolean;
    compareDate?: string;
    color?: string;
}

export interface IProps {
    filterByDate: Filter[];
    type: TypeStatisticsEnum;
    isRemove?: boolean;
    handleRemove?: (id: string) => void;
    handleEdit?: (id: string, fields: IFields) => void;
    field?: TypeStatistics;
    id: string;
    isCompare?: boolean;
    compareDate?: string;
    color?: string;
}
