import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconButtonUI from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { mdiIncognito as GhostIcon } from '@mdi/js';
import { useAuth0 } from '@auth0/auth0-react';
import {
  SUPPORT_TICKET, SETTINGS_PROFILE, SETTINGS_SETTINGS, SIGN_IN,
} from '@/constants/routes';
import { IconButton } from '@/ui/IconButton';
import { GhostContext } from '@/providers/GhostProvider';
import { UserContext } from '@/providers/UserProvider';
import useStyles from './styles';

const Profile: FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const { isGhostMode } = useContext(GhostContext);
  const { logout } = useAuth0();
  const { user } = useContext(UserContext);

  return (
    <>
      {
        !isGhostMode ? (
          <IconButtonUI
            color="inherit"
            className={classes.containerIcon}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {
              user?.picture ? (
                <img src={user.picture} alt="Avatar" className={classes.iconImage} />
              ) : (
                <AccountCircleIcon className={classes.icon} />
              )
            }
          </IconButtonUI>
        ) : (
          <IconButton path={GhostIcon} onClick={(e) => setAnchorEl(e.currentTarget)} />
        )
      }
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <MenuItem className={classes.menuItem} onClick={() => onClose()}>
          <NotificationsIcon className={classes.iconMenu} />
          <div className={classes.text}>Notification</div>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => onClose()}>
          <PersonAddIcon className={classes.iconMenu} />
          <div className={classes.text}>Invite</div>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleRoute(SUPPORT_TICKET)}>
          <ContactSupportIcon className={classes.iconMenu} />
          <div className={classes.text}>Support</div>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleRoute(SETTINGS_PROFILE)}>
          <PersonIcon className={classes.iconMenu} />
          <div className={classes.text}>Profile</div>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleRoute(SETTINGS_SETTINGS)}>
          <SettingsIcon className={classes.iconMenu} />
          <div className={classes.text}>Settings</div>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleSignOut()}>
          <PowerSettingsNewIcon className={classes.iconMenu} />
          <div className={classes.text}>Logout</div>
        </MenuItem>
      </Menu>
    </>
  );

  function onClose() {
    setAnchorEl(null);
  }

  function handleRoute(route: string) {
    history.push(route);
    onClose();
  }

  async function handleSignOut() {
    logout({ returnTo: window.location.origin + SIGN_IN });
  }
};

export default Profile;
