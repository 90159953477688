/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC, useState, useEffect,
} from 'react';
import { DatePicker } from 'rsuite';
// TODO: you need to use the library
import './rsuite-dark.css'; // 'rsuite/dist/styles/rsuite-default.css';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/Today';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { PropsI } from './types';
import useStyles from './styles';

const DatePickerRsuite: FC<PropsI> = ({
  value, onChange, placement = 'bottomEnd', size = 'lg', disabled = false, isOpen, setIsOpen, isHidden = false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.containerDatePicker} onClick={handleOpen}>
        <DatePicker
          size="lg"
          placeholder="MM dd, `yy"
          format="MMMM DD, `YY"
          className={clsx({
            [classes.datePicker]: size === 'lg',
            [classes.datePickerL]: size === 'l',
            [classes.datePickerLTask]: size === 'lTask',
            [classes.datePickerXS]: size === 'xs',
            [classes.datePickerMD]: size === 'md',
            [classes.datePickerHidden]: isHidden,
          })}
          open={open}
          value={value}
          onChange={onChange}
          placement={placement}
          onOk={handleOk}
          disabled={disabled}
        />
        <IconButton
          size="small"
          className={clsx({
            [classes.iconDatePicker]: ['lg', 'md', 'l'].includes(size) && !isHidden,
            [classes.iconDatePickerXS]: size === 'xs' && !isHidden,
            [classes.iconDatePickerLeft]: isHidden,
          })}
        >
          <TodayIcon className={clsx({ [classes.iconMD]: size === 'md' })} />
        </IconButton>
      </div>
    </ClickAwayListener>
  );

  function handleClose() {
    setOpen(false);
    if (typeof setIsOpen === 'function') {
      setIsOpen(false);
    }
  }

  function handleOpen() {
    if (disabled) {
      return;
    }
    setOpen(true);
  }

  function handleOk() {
    setTimeout(() => {
      handleClose();
    }, 0);
  }
};

export default DatePickerRsuite;
