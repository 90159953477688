/* eslint-disable no-nested-ternary */
import { IMatrix } from '@/gql/types/dynamicServer';
import {
  getBgMatrixColor, getMatrixColor, getStepMax, getStepMin,
} from '@/utils/common';
import uniq from 'lodash/uniq';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import { TypeNumberEnum } from '@/utils/number/types';
import {
  colorBrightGreen,
  colorDarkerGreen, colorDecrease,
  colorIncrease, colorLightGreen,
  colorPink, colorYellow,
} from '@/config/color';

interface IData extends Record<
  string, string | null | undefined | number | boolean | Record<string, string | null | undefined | number | string[]>
> {
  header: string;
  totals: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jobs: any;
  data: {};
  state: boolean;
  // campaign: string | null | undefined;
}

const coloreFieldTotals = ['rev', 'roi'];
const defaultColumns = [{ name: 'header', title: ' ' }, { name: 'totals', title: 'Totals' }, { name: 'jobs', title: 'Jobs#' }];
// const additionalColumns = [{ name: 'campaign', title: ' ' }];
const defaultTotalRow = (title = 'Totals') => ({
  header: title, totals: '-', jobs: '-', state: true, campaign: title,
});

export const getReports = (data: IMatrix[], isSwap: boolean) => data
  .filter((item) => !!item.subgrouped && !!item.grouped)
  .map((item) => (!isSwap ? {
    ...item,
    grouped: item.grouped,
    subgrouped: item.subgrouped,
  } : {
    ...item,
    grouped: item.subgrouped,
    subgrouped: item.grouped,
  }));

export const defaultSort = (a: Record<string, string>, b: Record<string, string>) => {
  if (a.title.trim().toLowerCase() < b.title.trim().toLowerCase()) { return -1; }
  if (a.title.trim().toLowerCase() > b.title.trim().toLowerCase()) { return 1; }
  return 0;
};

export const getColumns = (
  keyGroup: string[], listHideColumns: string[], rowToSort?: Record<string, string | number>, direction?: string,
) => {
  const columns = keyGroup
    .map((item) => ({ name: item, title: item }))
    .filter((item) => !listHideColumns.includes(item.name) && !!item.name);

  const sortColumns = rowToSort
    ? (direction === 'asc'
      ? columns.sort((a, b) => Number(rowToSort[a.name]) - Number(rowToSort[b.name]))
      : columns.sort((a, b) => -(Number(rowToSort[a.name]) - Number(rowToSort[b.name]))))
    : columns.sort(defaultSort);

  // return defaultColumns.concat(sortColumns).concat(additionalColumns);
  return defaultColumns.concat(sortColumns);
};

export const sortRows = (
  rows: IData[], columnName: string, direction: string,
) => (direction === 'asc'
  ? rows.sort((a, b) => Number(a[columnName]) - Number(b[columnName]))
  : rows.sort((a, b) => -(Number(a[columnName]) - Number(b[columnName]))));

export const getKeyByField = (
  data: IMatrix[], field: string,
): string[] => uniq(data.map((item) => item[field]).filter((item) => !!item));

export const getTotal = (keyGroup: string[], data: IMatrix[], field: string, group: string) => {
  const totalRow = keyGroup.reduce((obj, key) => {
    const count = data
      .filter((item) => item[group] === key)
      .reduce((previousValue, currentValue) => previousValue + Number(currentValue[field]), 0)
      .toFixed(2);

    return { ...obj, [(key as string)]: count };
  }, {});
  return totalRow;
};

export const getTotalRow = (
  keyGroup: string[], data: IMatrix[], field: string[], fieldType: TypeNumberEnum | undefined, title?: string,
) => {
  const totalRow = keyGroup.reduce((obj, key) => {
    const countFields = field.reduce((prev, cur) => {
      const count = data
        .filter((item) => item.grouped === key)
        .reduce((previousValue, currentValue) => previousValue + Number(currentValue[cur]), 0)
        .toFixed(2);
      return prev ? `${prev} | ${count}` : count;
    }, '');
    return { ...obj, [(key as string)]: countFields };
  }, {});
  return { ...defaultTotalRow(title), ...totalRow, data: { fieldType, ...totalRow } };
};

export const getJoRow = (
  keyGroup: string[], data: IMatrix[], fieldType: TypeNumberEnum | undefined, title?: string,
) => {
  const jobRow = keyGroup.reduce((obj, key) => {
    const job = data.find((item) => item.grouped === key)?.countGroup;
    return { ...obj, [(key as string)]: job };
  }, {});
  return { ...defaultTotalRow(title), ...jobRow, data: { fieldType, ...jobRow } };
};

export const getTotalColumn = (data: IMatrix[], subgrouped: string, field: string[]) => {
  const fieldRev = field.includes('rev');
  const totalFields = field.filter((item) => (fieldRev ? item === 'rev' : true)).reduce((prev, cur) => {
    if (cur === 'avgCr') {
      const count = data.filter((itemSum) => subgrouped === itemSum.subgrouped && Number(itemSum.cr) > 0).length;
      const crTotal = data
        .filter((itemSum) => subgrouped === itemSum.subgrouped)
        .reduce((previousValue, currentValue) => previousValue + Number(currentValue.cr), 0);
      const total = (Number(count) > 0 ? (crTotal / count) : crTotal).toFixed(2);
      return prev ? `${prev} | ${total}` : (fieldRev ? `$${total}` : total);
    }
    const total = data
      .filter((itemSum) => subgrouped === itemSum.subgrouped)
      .reduce((previousValue, currentValue) => previousValue + Number(currentValue[cur]), 0)
      .toFixed(2);
    return prev ? `${prev} | ${total}` : (fieldRev ? `$${total}` : total);
  }, '');
  return totalFields;
};

export const getTotalTooltipColumn = (data: IMatrix[], subgrouped: string, field: string[]) => {
  const result = {};
  field.forEach((f) => {
    result[f] = getTotalColumn(data, subgrouped, [f]).replace('$', '');
  });
  return result;
};

export const getReportsFilterMinData = (
  data: IMatrix[],
  field: string,
  minData = 0,
  hiddenRows: string[],
  filterByX: string,
  filterByY: string,
) => {
  let filteredData = data;

  if (hiddenRows.length) {
    filteredData = filteredData.filter((item) => Number(item.rev) >= 0
      && item.subgrouped
      && !hiddenRows.includes(item.subgrouped));
  }

  if (filterByX.length) {
    filteredData = filteredData.filter((item) => item.subgrouped
      && item.subgrouped.toLowerCase().includes(filterByX.toLowerCase()));
  }

  if (filterByY.length) {
    filteredData = filteredData.filter((item) => item.grouped
      && item.grouped.toLowerCase().includes(filterByY.toLowerCase()));
  }

  const totalRow = getTotal(getKeyByField(filteredData, 'grouped'), filteredData, field, 'grouped');
  const totalColumn = getTotal(getKeyByField(filteredData, 'subgrouped'), filteredData, field, 'subgrouped');

  const grouped: string[] = [];
  Object.keys(totalRow).forEach((item) => {
    if (Number(totalRow[item]) >= minData) {
      grouped.push(item);
    }
  });

  const subgrouped: string[] = [];
  Object.keys(totalColumn).forEach((item) => {
    if (Number(totalColumn[item]) >= minData) {
      subgrouped.push(item);
    }
  });

  return filteredData.filter(
    (item) => grouped.includes(item.grouped as string) && subgrouped.includes(item.subgrouped as string),
  );
};

export const getColorConfig = (data: IMatrix[], fields: string[]) => fields.map((field) => {
  const minArray = minBy(data, (item) => item[field]) || {};
  const minItem = minArray[field] || 0;

  const maxArray = maxBy(data, (item) => item[field]) || {};
  const maxItem = maxArray[field] || 0;

  const stepMax = getStepMax(maxItem, fields.length !== 1);
  const stepMin = getStepMin(minItem, fields.length !== 1);

  return { max: stepMax, min: stepMin };
});

export const getAdditionalData = (
  keyGroup: string[], row: IMatrix[], fields: string[], header: string,
  stepColorConfig: {max: number[]; min: number[]}[], fieldType: TypeNumberEnum | undefined,
) => keyGroup.reduce((obj, keyGroupItem) => {
  const dataCell = row.find((itemCol) => keyGroupItem === itemCol.grouped);
  let color;
  if (fields.length === 1 && fields[0] === 'rev' && dataCell && dataCell.roi && dataCell.roi < 0) {
    color = 'rgb(255, 104, 89)';
  } else if (fields.length > 1) {
    color = fields.map((field, index) => getMatrixColor(
      dataCell ? dataCell.isActive : undefined,
      dataCell ? dataCell.uid : undefined,
      dataCell ? dataCell[field] : 0,
      stepColorConfig[index],
      fields.length !== 1,
      field,
    ));
  }
  const bgColor = fields.map((field, index) => getBgMatrixColor(
    dataCell ? dataCell.isActive : undefined,
    dataCell ? dataCell.uid : undefined,
    dataCell ? dataCell[field] : 0,
    stepColorConfig[index],
    fields.length !== 1,
  ));
  return {
    ...obj,
    header,
    fieldType,
    [(keyGroupItem as string)]: dataCell
      ? { ...dataCell, color, bgColor }
      : 0,
  };
}, {});

export const getRows = (keyGroup: string[], row: IMatrix[], field: string[]) => keyGroup.reduce((obj, keyGroupItem) => {
  const valueFields = field.reduce((prev, cur) => {
    const value = row.find((itemCol) => keyGroupItem === itemCol.grouped)?.[cur] || 0;
    return prev ? `${prev} | ${value}` : value;
  }, '');

  return { ...obj, [(keyGroupItem as string)]: valueFields };
}, {});

export const getColorTotals = (roi: number, fields: string[]) => fields.map((item) => (coloreFieldTotals.includes(item) ? (roi < 0 ? colorDecrease : '') : ''));

export const getCustomColor = (value: number) => {
  if (value <= 1) {
    if (value <= 1 && value > 0.5) {
      return colorYellow;
    } if (value <= 0.5 && value > 0) {
      return colorPink;
    }
    return colorDecrease;
  }
  if (value > 1) {
    if (value > 1 && value <= 2) {
      return colorLightGreen;
    } if (value > 2 && value <= 5) {
      return colorDarkerGreen;
    }
    return colorIncrease;
  }
  return '';
};

export function getRoiColor(value: number) {
  if (value > 0) {
    return colorIncrease;
  } if (value < 0) {
    return colorDecrease;
  }
  return '';
}

export function getRoiPercentColor(value: number) {
  if (value > 75) {
    return colorIncrease;
  }
  if (value >= 45 && value < 75) {
    return colorBrightGreen;
  }
  if (value >= 25 && value < 45) {
    return colorLightGreen;
  }
  if (value >= 10 && value < 25) {
    return colorYellow;
  }
  if (value < 10) {
    return colorDecrease;
  }

  return '';
}

export function getTqfRoiPercentPColor(value: number) {
  if (value < 0) {
    return colorDecrease;
  }
  if (value > 0 && value < 10) {
    return colorYellow;
  }
  if (value >= 10 && value < 20) {
    return colorIncrease;
  }
  if (value >= 20 && value < 30) {
    return colorDarkerGreen;
  }
  if (value >= 30 && value < 50) {
    return colorLightGreen;
  }
  if (value >= 50) {
    return colorBrightGreen;
  }

  return '';
}

export function getPerformanceColor(value: number) {
  if (value >= 25) {
    return colorBrightGreen;
  }
  if (value >= 15 && value < 25) {
    return colorIncrease;
  }
  if (value >= 7 && value < 15) {
    return colorLightGreen;
  }
  if (value >= 2 && value < 7) {
    return colorYellow;
  }
  if (value < 2) {
    return colorDecrease;
  }

  return '';
}

export function getVolumeColor(value: number) {
  if (value > 10000) {
    return colorIncrease;
  }
  if (value >= 5000 && value < 10000) {
    return colorBrightGreen;
  }
  if (value >= 1000 && value < 5000) {
    return colorLightGreen;
  }
  if (value >= 250 && value < 1000) {
    return colorYellow;
  }
  if (value < 250) {
    return colorDecrease;
  }

  return '';
}
