/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unused-prop-types */
import React, {
  FC,
} from 'react';
import {
  IntegratedGrouping, GroupingState, SummaryState,
  IntegratedSummary, IntegratedSorting, SortingState, FilteringState, IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid, TableHeaderRow, TableGroupRow, TableSummaryRow, TableColumnVisibility, ColumnChooser, Toolbar,
  TableColumnResizing, DragDropProvider, TableColumnReordering, VirtualTable, TableFilterRow, TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import { IStandartGroupedTable } from '../../types';

const StandardGroupedTable: FC<IStandartGroupedTable> = ({
  rows, columns, columnWidths, handleColumnWidthsChange, columnOrder,
  setColumnOrder, TotalCellComponent, handleHiddenColumns,
  handleCellComponent, HandleRowComponent, grouping,
  HeaderCellComponent, SummaryItems, groupSummaryItems,
  handleContentComponent, handleSummaryItemComponent,
  params, handleChangeSorting, columnsExtension, hiddenColumns, leftFixedColumns,
  isEdit, isFilter, isColumnsFilter = false, CustomToolbar,
}) => {
  return (
    <Grid rows={rows} columns={columns}>
      <SortingState
        sorting={params?.sort}
        onSortingChange={handleChangeSorting}
        columnExtensions={columnsExtension}
      />
      <IntegratedSorting />
      <GroupingState grouping={grouping} />
      <SummaryState groupItems={groupSummaryItems} totalItems={SummaryItems} />
      <IntegratedGrouping />
      <IntegratedSummary />
      <FilteringState defaultFilters={[]} />
      <IntegratedFiltering />
      <DragDropProvider />
      <VirtualTable rowComponent={HandleRowComponent} cellComponent={handleCellComponent} height="auto" />
      <TableColumnResizing
        resizingMode="widget"
        columnWidths={columnWidths}
        onColumnWidthsChange={handleColumnWidthsChange}
      />
      <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
      <TableHeaderRow cellComponent={HeaderCellComponent} showSortingControls />
      {
        isColumnsFilter && (<TableFilterRow />)
      }
      <TableSummaryRow totalCellComponent={TotalCellComponent} />
      <TableColumnVisibility hiddenColumnNames={hiddenColumns} onHiddenColumnNamesChange={handleHiddenColumns} />
      <Toolbar />
      {
        (isEdit || isFilter) && (
          <CustomToolbar />
        )
      }
      <ColumnChooser />
      <TableGroupRow
        showColumnsWhenGrouped
        contentComponent={handleContentComponent}
        summaryItemComponent={handleSummaryItemComponent}
      />
      <TableFixedColumns leftColumns={leftFixedColumns} />
    </Grid>
  );
};

export default StandardGroupedTable;
