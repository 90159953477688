export enum DataChartE {
    JOBS = 'jobs',
    EVENTS = 'events'
}

export enum TypeChartEnum {
    PIE = 'pie',
    LINE = 'line',
    NUMBER = 'number',
    COLUMN = 'column',
    MAP = 'map'
}

export type FieldChart = FieldJobEnum | FieldEventEnum;

export enum FieldJobEnum {
    PARTNER_EMLOYER = 'partnerEmployer',
    PARTNER_CPC = 'partnerCpc',
    PARTNER_ID = 'partnerId',
    STATE_PREFIX = 'statePrefix',
    ADVERTISER_ID = 'advertiserId',
}

export enum FieldEventEnum {
    GLO_SRC = 'gloSrc',
    EVENT_TYPE = 'eventType',
    PARTNER_EMPLOYER = 'partnerEmployer',
    ADVERTISER = 'advertiser',
}

export interface IFields {
    id: string;
    data: DataChartE;
    type: TypeChartEnum;
    field: FieldChart;
}

export interface IPopoverFields {
    id: string;
    data: DataChartE;
    type: TypeChartEnum | '';
    field: FieldChart | '';
}

export interface IProps {
    index: number;
    handleRemove: (index: number) => void;
    handleEdit: (index: number, fields: IFields) => void;
    fieldsChart: IFields;
}
