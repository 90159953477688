import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    padding: theme.spacing(2),
    display: 'grid',
  },
  saveWrap: {
    textAlign: 'right',
  },
  select: {
    width: 225,
    marginBottom: theme.spacing(2),
  },
  selectM: {
    width: 145,
    marginBottom: theme.spacing(2),
    '& > div': {
      borderTopRightRadius: 0,
    },
  },
  selectS: {
    width: 80,
    marginBottom: theme.spacing(2),
    '& > div': {
      borderTopLeftRadius: 0,
    },
  },
  card: {
    width: '36px',
    height: '36px',
    minWidth: '36px',
    background: theme.palette.background.default,
    position: 'relative',
  },
  cardSecondary: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    background: '#616161',
    position: 'relative',
  },
  containerButton: {
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  icon: {
    fontSize: '1rem',
  },
}));

export default useStyles;
