/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Filter,
  Sorting,
  TableColumnWidthInfo,
  SummaryItem,
  SummaryType,
} from '@devexpress/dx-react-grid';
import { ICatalogs, ICatalogsCampaigns, ICatalogsEvents } from '@/gql/types/dynamicServer';
import {
  TableHeaderRow,
  PagingPanel,
  TableSummaryRow,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { ApolloQueryResult } from '@apollo/client';
import { IFilterAdditional, TypeFilterEnum } from '../Filter/types';

export interface Group {
  group?: string;
  subgroup?: string;
}

export interface ParamsMutationI {
  node: Record<string, number | string | boolean | Record<string, number | string | boolean>>
    | void
    | Record<string, number | string | boolean | Record<string, number | string | boolean>>[];
  type?: ActionsEnum;
}

export interface ParamsMutationResponseI {
  success: boolean;
  message: string;
}

export enum TypeTableEnum {
  STANDARD = 'standardTable',
  GROUPED = 'groupedTable',
  STANDARD_GROUPED = 'standardGroupedTable',
  CUSTOM_GROUPED = 'customGroupedTable',
}

export enum ActionFilterEnum {
  RESET = 'reset',
  OPEN = 'open',
}

export interface PropsI {
  nameTable: string;
  type: TypeTableEnum;
  basedOnType?: string;
  columnsExtension: ColumnExtensionI[];
  isLoading: boolean;
  isActiveExpand?: boolean;
  onChangeParams?: (props: ParamsQueryI) => void;
  params?: ParamsQueryI;
  resetChecked?: boolean;
  rows: RowI[];
  totalCount: number;
  onMutation?: (variables: ParamsMutationI) => Promise<ParamsMutationResponseI>;
  isEdit?: boolean;
  hideAddButton?: boolean;
  onHandle?: (row: Record<string, number | string | boolean>) => string;
  onAction?: Function; // (event: React.ChangeEvent<{}>) => void;
  customCss?: string;
  customCellCss?: string;
  handleFilter?: (itemFilter: Filter | undefined, isReset?: boolean) => void;
  filters?: Filter[];
  catalogs?: ICatalogs & ICatalogsEvents & ICatalogsCampaigns;
  summaryRow?: Record<string, number>;
  isPaging?: boolean;
  isColumnsFilter?: boolean;
  isGroup?: boolean;
  loadingCss?: string;
  isRealtime?: boolean;
  group?: string;
  subgroup?: string;
  grouping?: any;
  groupSummaryItems?: any;
  defaultSort?: Sorting[];
  AddPopupRow?: React.FC<IAddPopupRow>;
  EditPopupRow?: React.FC<IEditPopupRow>;
  CustomAddButton?: React.FC<ICustomAddButton>;
  titleAddRow?: string;
  titleEditRow?: string;
  refetch?: (variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>;
  isClone?: boolean;
  onDelete?: boolean;
  isCopyValues?: boolean;
  isVisible?: boolean;
  columnsVisibility?: string[];
  columnsUnvisibility?: string[];
  columnsSiteAdminInvisibility?: string[];
}

export interface ICustomAddButton {
  handlePopup(action: string): () => void;
}

export interface IAddPopupRow {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: RowI) => Promise<ParamsMutationResponseI>;
  title?: string;
  basedOnType?: string;
}

export interface CampsList {
  key: string;
  name: string;
}

export interface IEditExportPopupRow {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: RowI) => Promise<ParamsMutationResponseI>;
  row?: RowI;
  title?: string;
  campaigns?: CampsList[];
}

export interface IEditPopupRow {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: RowI) => Promise<ParamsMutationResponseI>;
  row?: RowI;
  hideName?: boolean;
  title?: string;
  basedOnType?: string;
}

export interface RowI {
  [key: string]: number | string | boolean | void | object | Date;
}

export interface ColumnExtensionI {
  columnName: string;
  addingEnabled?: boolean;
  editingEnabled?: boolean;
  filteringEnabled?: boolean;
  hidden: boolean;
  required?: boolean;
  sortingEnabled: boolean;
  title: string;
  customTitle?: string;
  type: TypeFieldEnum;
  width: number;
  widthPercent?: number;
  additional?: string;
  fixed?: boolean;
  summary?: boolean;
  filteringTableEnabled?: boolean;
  filteringType?: TypeFilterEnum;
  filteringAdditional?: IFilterAdditional;
  dependency?: string;
  dependencyValue?: string;
  summaryType?: string;
  group?: boolean;
}

export enum TypeFieldEnum {
  DATE = 'date',
  DATE_SHORT = 'dateShort',
  DATERANGE = 'dateRange',
  TEXT = 'text',
  TEXT_FEED_STATUS = 'textFeedStatus',
  TEXT_WEBSITE_STATUS = 'textWebsiteStatus',
  HTML_TEXT = 'htmlText',
  HTML_DATE_DECREASE = 'htmlDateDecreaseText',
  BOOLEAN = 'boolean',
  PERCENT = 'percent',
  PERCENT_ORANGE = 'percentOrange',
  PERCENT_WITH_BORDER = 'percentWithBorder',
  CURRENCY = 'currency',
  EDR_CURRENCY = 'edrCurrency',
  ROI_CURRENCY = 'roiCurrency',
  ROI_PERCENT_LINK = 'roiPercentLink',
  TQF_ROI_PERCENT = 'tqfRoiPercent',
  PERFORMANCE_PERCENT = 'performancePercent',
  PERFORMANCE_PERCENT_LINK = 'performancePercentLink',
  EDR_DELTA_CURRENCY = 'edrDeltaCurrency',
  CURRENCY_WITH_BORDER = 'currencyWithBorder',
  CURRENCY_CPAC_COLOR = 'currencyCpacColor',
  NUMBER = 'number',
  NUMBER_WITH_BORDER = 'numberWithBorder',
  SELECT = 'select',
  SELECT_JAT_RATING = 'selectJatRating',
  SELECT_KEY = 'selectKey',
  AUTOCOMPLETE = 'autocomplete',
  MULTISELECT = 'multiSelect',
  MULTIAUTOCOMPLETE = 'multiAutocomplete',
  MULTIAUTOCOMPLETEKEY = 'multiAutocompleteKey',
  SWITCH = 'switch',
  LINK = 'link',
  SHOW_IN = 'showIn',
  SHOW_IN_WITH_ICON = 'showInWithIcon',
  SHOW_IN_DECREASING = 'showInDecreasing',
  DELETE = 'delete',
  EDIT = 'edit',
  CELL_EDIT = 'cellEdit',
  CELL_MULTI_EDIT = 'cellMultiEdit',
  CELL_EDIT_DECREASING = 'cellEditDecreasing',
  CUSTOM_CELL_EDIT = 'customCellEdit',
  CUSTOM_CELL_EDIT_DECREASING = 'customCellEditDecreasing',
  COLOR_SHORT = 'colorShort',
  COLOR_SHORT_CURRENCY = 'colorShortCurrency',
  GRADIENT_ARROW = 'gradientArrow',
  COMPARE_GROUP = 'compareGroup',
  COMPARE_GROUP_BG = 'compareGroupBg',
  COMPARE_GROUP_BG_PERCENT = 'compareGroupBgPercent',
  DELTA_COMPARE_GROUP_BG_PERCENT = 'deltaCompareGroupBgPercent',
  COMPARE_WITH_PERCENT = 'compareWithPercent',
  COMPARE_WITH_PERCENT_CR = 'compareWithPercentCR',
  COMPARE_BG_WITH_PERCENT_CR = 'compareBgWithPercentCR',
  COMPARE_WITH_PERCENT_BID = 'compareWithPercentBid',
  COMPARE_WITH_CURRENCY = 'compareWithCurrency',
  COMPARE_WITH_CURRENCY_BORDER = 'compareWithPercentBorder',
  COMPARE_WITH_CURRENCY_GRADIENT = 'compareWithCurrencyGradient',
  COMPARE_WITH_CURRENCY_DECREASE = 'compareWithCurrencyDecrease',
  COMPARE_BG_WITH_CURRENCY = 'compareBgWithCurrency',
  COMPARE_BG_WITH_PERCENT = 'compareBgWithPercent',
  DELTA_COMPARE_BG_WITH_CURRENCY = 'deltaCompareBgWithCurrency',
  DELTA_COMPARE_BG_WITH_PERCENT = 'deltaCompareBgWithPercent',
  PERCENT_INTENT_RELATED_CR_REPORT = 'percentIntentRelatedCrReport',
  PERCENT_ROI_REPORT = 'percentRoiReport',
  BORDER = 'border',
  BORDER_DECREASING_DATE = 'borderDecreasingDate',
  DECREASING_DATE = 'decreasingDate',
  STAR = 'star',
  BAR = 'bar',
  QUALITY_STAR = 'qualityStar',
  VOLUME_LINK = 'volumeLink',
  VOLUME_BAR = 'volumeBar',
  GRADIENT_PERCENT = 'gradientPercent',
  GRADIENT_CURRENCY = 'gradientCurrency',
  INCREASING_DECREASING_CURRENCY = 'increasingDecreasingCurrency',
  INCREASING_DECREASING_CURRENCY_MTD_BG = 'increasingDecreasingCurrencyMtdBg',
  INCREASING_DECREASING_PERCENT = 'increasingDecreasingPercent',
  CONTENT = 'content',
  LINEAR = 'linear',
  LINEAR_DAY_TARGET = 'linearDayTarget',
  CELL_CHECKBOX = 'cellCheckbox',
  CHECKBOX = 'checkbox',
  TEXT_LINK = 'textLink',
  TEXT_LINK2 = 'textLink2',
  TEXT_NA_COLORED = 'textNaColored',
  BUTTON = 'button',
  BUTTON_TEXT = 'buttonText',
  BUTTON_OR_STRING = 'buttonOrString',
  BUTTON_OR_EMPTY = 'buttonOrEmpty',
  TOOLTIP_HISTORY = 'tooltipHistory',
  TOOLTIP_FRAUD_SCORE = 'tooltipFraudScore',
  FULL_BID = 'fullBid',
}

export interface ColumnI {
  name: string;
  title: string;
}

export enum ActionsEnum {
  ADD = 'ADD',
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
  MULTI_UPDATE = 'MULTI_UPDATE',
}

export interface ParamsQueryI {
  skip?: number;
  take?: number;
  sort?: Sorting[];
  filter?: Filter[];
  group?: Group;
}

export interface IStandardTable {
  rows: RowI[];
  columns: ColumnI[];
  SummaryItems: SummaryItem[];
  summaryCalculator: (type: SummaryType, rows: Array<any>, getValue: (row: any) => any) => any;
  params?: ParamsQueryI;
  handleChangeSorting(sort: Sorting[]): void;
  columnsExtension: ColumnExtensionI[];
  currentPage: number;
  handleChangePage(page: number): void;
  handlePageSizeChange(pageSize: number): void;
  totalCount: number;
  handleCellComponent(props: Table.DataCellProps & {
    className?: string;
  }): JSX.Element;
  HandleRowComponent: (props: Table.DataRowProps) => JSX.Element;
  columnOrder: string[];
  setColumnOrder: (columnOrder: string[]) => void;
  hiddenColumns: string[];
  handleHiddenColumns(hiddenColumnNames: string[]): void;
  columnWidths: TableColumnWidthInfo[];
  handleColumnWidthsChange(nextColumnWidths: Array<TableColumnWidthInfo>): void;
  HeaderCellComponent: (props: TableHeaderRow.CellProps & {
      className?: string;
  }) => JSX.Element;
  CustomToolbar: () => JSX.Element;
  TotalCellComponent: (props: TableSummaryRow.CellProps & {
      children?: any;
  }) => JSX.Element;
  leftFixedColumns: (string | symbol)[];
  pageSizes: number[];
  PagingContainer: (props: PagingPanel.ContainerProps) => JSX.Element;
  isPaging: boolean;
  isEdit: boolean;
  isFilter: boolean;
  isColumnsFilter?: boolean;
  isVisible: boolean;
}

export interface IGroupedTable {
  rows: RowI[];
  columns: ColumnI[];
  isActiveExpand?: boolean;
  columnWidths: TableColumnWidthInfo[];
  handleColumnWidthsChange(nextColumnWidths: Array<TableColumnWidthInfo>): void;
  columnOrder: string[];
  setColumnOrder: (columnOrder: string[]) => void;
  TotalCellComponent: (props: TableSummaryRow.CellProps & {
    children?: any;
  }) => JSX.Element;
  hiddenColumns: string[];
  handleHiddenColumns(hiddenColumnNames: string[]): void;
  handleCellComponent(props: Table.DataCellProps & {
    className?: string;
  }): JSX.Element;
  HandleRowComponent: (props: Table.DataRowProps) => JSX.Element;
  HeaderCellComponent: (props: TableHeaderRow.CellProps & {
    className?: string;
  }) => JSX.Element;
  SummaryItems: SummaryItem[];
  defaultSort: Sorting[];
  handleSummaryItemComponent(props: TableSummaryRow.ItemProps): JSX.Element;
  handleContentComponent({ row }: { row: any }): JSX.Element;
  group: string;
  subgroup: string;
  grouping: any;
  isGroup: boolean;
  isColumnsFilter?: boolean;
  groupSummaryItems: any;
}

export interface IStandartGroupedTable {
  rows: RowI[];
  columns: ColumnI[];
  columnWidths: TableColumnWidthInfo[];
  handleColumnWidthsChange(nextColumnWidths: Array<TableColumnWidthInfo>): void;
  columnOrder: string[];
  setColumnOrder: (columnOrder: string[]) => void;
  TotalCellComponent: (props: TableSummaryRow.CellProps & {
    children?: any;
  }) => JSX.Element;
  hiddenColumns: string[];
  leftFixedColumns: (string | symbol)[];
  handleHiddenColumns(hiddenColumnNames: string[]): void;
  handleCellComponent(props: Table.DataCellProps & {
    className?: string;
  }): JSX.Element;
  HandleRowComponent: (props: Table.DataRowProps) => JSX.Element;
  HeaderCellComponent: (props: TableHeaderRow.CellProps & {
    className?: string;
  }) => JSX.Element;
  SummaryItems: SummaryItem[];
  handleSummaryItemComponent(props: TableSummaryRow.ItemProps): JSX.Element;
  handleContentComponent({ row }: { row: any }): JSX.Element;
  grouping: any;
  groupSummaryItems: any;
  handleChangeSorting(sort: Sorting[]): void;
  columnsExtension: ColumnExtensionI[];
  params?: ParamsQueryI;
  CustomToolbar: () => JSX.Element;
  isEdit: boolean;
  isFilter: boolean;
  isColumnsFilter?: boolean;
}

export interface ICustomGroupedTable {
  rows: RowI[];
  columns: ColumnI[];
  groupSummaryItems: any;
  SummaryItems: SummaryItem[];
  HandleRowComponent: (props: Table.DataRowProps) => JSX.Element;
  handleCellComponent(props: Table.DataCellProps & {
    className?: string;
  }): JSX.Element;
  columnWidths: TableColumnWidthInfo[];
  handleColumnWidthsChange(nextColumnWidths: Array<TableColumnWidthInfo>): void;
  columnOrder: string[];
  setColumnOrder: (columnOrder: string[]) => void;
  HeaderCellComponent: (props: TableHeaderRow.CellProps & {
    className?: string;
  }) => JSX.Element;
  TotalCellComponent: (props: TableSummaryRow.CellProps & {
    children?: any;
  }) => JSX.Element;
  hiddenColumns: string[];
  handleHiddenColumns(hiddenColumnNames: string[]): void;
  CustomToolbar: () => JSX.Element;
  handleSummaryItemComponent(props: TableSummaryRow.ItemProps): JSX.Element;
  handleContentComponent({ row }: { row: any }): JSX.Element;
  isEdit: boolean;
  isColumnsFilter?: boolean;
}
