export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const JOBS_FEEDS = '/jobs/feeds';
export const JOBS_JOBS = '/jobs/jobs';
export const JOBS_USER_CAMPAIGNS = '/jobs/camps';
export const PERFORMANCE_OVERVIEW = '/performance/overview';
export const PERFORMANCE_TRENDS = '/performance/trends';
export const PERFORMANCE_MATRIX = '/performance/matrix';
export const PERFORMANCE_REPORTS = '/performance/reports';
export const PERFORMANCE_LOGS = '/performance/logs';
export const PERFORMANCE_ALERTS = '/performance/alerts';
export const PERFORMANCE_RECONCILIATION = '/performance/reconciliation';
export const PERFORMANCE_RECONCILIATION_ADVERTISERS = '/performance/reconciliation/advertisers';
export const PERFORMANCE_RECONCILIATION_PUBLISHERS = '/performance/reconciliation/publishers';
export const TRAFFIC_INTENT = '/traffic/intent';
export const TRAFFIC_GEO_FENCE = '/traffic/geofence';
export const TRAFFIC_VOLUME = '/traffic/volume';
export const TRAFFIC_CRO = '/traffic/cro';
export const TRAFFIC_PROTECTION_RECOUP = '/traffic/protection-recoup';
export const TRAFFIC_CPAC_CONVERT = '/traffic/cpac-convert';
export const TRAFFIC_ALL = '/traffic/all';
export const TRADE_BUY = '/trade/buy';
export const TRADE_SELL = '/trade/sell';
export const TRADE_LOGS = '/trade/logs';
export const SETTINGS_PROFILE = '/settings/profile';
export const SETTINGS_SETTINGS = '/settings/settings';
export const SETTINGS_SMART_CONTRACTS = '/settings/smart-contracts';
export const SETTINGS_ALERTS = '/settings/alerts';
export const SIGN_IN = '/sign-in';
export const SUPPORT_CONTACT = '/support/contact';
export const SUPPORT_TICKET = '/support/ticket';
export const TRADE_MATRIX = '/trade/matrix';
export const AUTOMATIONS = '/automations';
export const JOBS_LIVE_JOBS = '/jobs/live-jobs';
export const JOBS_CAMPAIGNS = '/jobs/campaigns';
export const JOBS_MAP = '/jobs/map';
export const JOBS_IMPORT = '/jobs/import';
export const JOBS_EXPORT = '/jobs/export';
export const JOBS_IMPORT_DUPLICATES = '/jobs/duplicates';
export const JOBS_IMPORT_BLOCKED = '/jobs/blocked';
export const JOBS_SETTING_API = '/io/global';
export const JOBS_SETTING_API_MAIN = '/io/global/main';
export const JOBS_SETTING_API_PUB = '/io/global/pub-api';
export const JOBS_SETTING_API_ADV = '/io/global/adv-api';
export const IO_LIVE_JOBS = '/io/live-jobs';
export const IO_IMPORT = '/io/import';
export const IO_EXPORT = '/io/export';
export const IO_FEEDS_PREVIEW = '/io/feeds-preview';
export const TALENT = '/talent';
export const TALENT_API = '/talent/api';
export const TALENT_AUDIENCES = '/talent/audiences';
export const TALENT_SOURCER = '/talent/sourcer';
export const TALENT_LTV = '/talent/ltv';
export const NETWORK_GLOBAL = '/network/global';
export const NETWORK_ADVERTISERS = '/network/adv';
export const NETWORK_PUBLISHERS = '/network/pub';
export const SITES_NEW = '/sites/new';
export const SITES_MANAGE = '/sites/manage';
export const API = '/api';
export const API_PUB_SETTINGS_API = '/api/pubFeeds';
export const ADV_IMPORT = '/advertisers-ssp/import';
export const ADV_FEEDS_API = '/advertisers-ssp/adv-feeds-api';
export const ADV_LIVE_JOBS = '/advertisers-ssp/live-jobs';
export const ADV_ADVERTISERS = '/advertisers-ssp/adv-accounts';
export const ADV_RECONCILIATION_ADVERTISERS = '/advertisers-ssp/reconciliation-advertisers';
export const ADV_BILLING = '/advertisers-ssp/billing';
export const ADV_LOGS = '/advertisers-ssp/logs';
export const PUB_EXPORT = '/publishers-dsp/export';
export const PUB_SETTINGS_API = '/publishers-dsp/setApi';
export const PUB_PUBLISHERS = '/publishers-dsp/pub-accounts';
export const PUB_RECONCILIATION_PUBLISHERS = '/publishers-dsp/reconciliation-publishers';
export const PUB_BILLING = '/publishers-dsp/billing';
export const PUB_LOGS = '/publishers-dsp/logs';
export const PUB_API_KEY = '/affiliate/pubapikey';
export const AFFILIATE_WIDGET = '/affiliate/widget';
export const AFFILIATE_WEBSITES = '/affiliate/websites';
export const WEBSITES = '/websites';
export const MARKETPLACE_BUY = '/marketplace/buy';
export const MARKETPLACE_SELL = '/marketplace/sell';
