/* eslint-disable @typescript-eslint/no-empty-function */
import { TypeOperationEnum } from '@/components/Filter/types';
import { STORAGE_KEY_THEME } from '@/constants/storage';
import { ThemeEnum } from '@/providers/types';
import { Filter } from '@devexpress/dx-react-grid';
import format from 'date-fns/format';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { getCustomColor, getRoiColor } from '@/app/Trade/Matrix/utils';
import NumberObj from './number';
import { TypeNumberEnum } from './number/types';
import { getItem } from './storage';

export const getFilterByCompareDate = (dateCompare: string) => [
  { columnName: 'date', value: dateCompare || 'undefined to undefined', operation: TypeOperationEnum.NULL },
];

export const getIsCompare = (dateCompare: string) => !!dateCompare;

export const getIsRealtime = (compareType: string) => ['realtimeDay', 'realtimeWeekDay'].includes(compareType || '');

export const getValueFromFilters = (filters: Filter[], columnName: string) => filters?.find((item) => item.columnName === columnName)?.value || '';

export const isValueFromFilters = (
  filters: Filter[], columnName: string,
) => !!filters?.find((item) => item.columnName === columnName);

export const getSum = (data: object[], field: string) => data.reduce(
  (previousValue, currentValue) => previousValue + (Number(currentValue[field]) || 0), 0,
);

export const getAvg = (data: object[], field: string) => getSum(data, field) / data.length;

export const parseDate = (date: string): {from: string; till: string} => {
  const arrayDate = date.split(' to ');
  return { from: arrayDate[0], till: arrayDate[1] };
};

export const getIsOneDay = (date: string) => {
  const arrayDate = parseDate(date);
  return arrayDate.from === arrayDate.till;
};

export const getIsToday = (date: string) => {
  if (getIsOneDay(date)) {
    return parseDate(date).from === format(new Date(), 'MM/dd/yyyy');
  }
  return false;
};

export const isEqualCollection = (
  collection1: object[], collection2: object[],
) => collection1.every((n, index) => isEqual(n, collection2[index]));

export const isEmail = (email: string): boolean => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const getGradientNegative = (value: number, minItem = -100) => {
  const theme = getItem(STORAGE_KEY_THEME) || ThemeEnum.DARK;
  const step = (theme === ThemeEnum.DARK)
    ? [2 / (minItem as number), 119 / (minItem as number), 136 / (minItem as number)]
    : [160 / (minItem as number), 9 / (minItem as number), 6 / (minItem as number)];
  const colorMax = [value * step[0], value * step[1], value * step[2]];
  return (theme === ThemeEnum.DARK)
    ? `rgb(${223 + colorMax[0]}, ${223 - colorMax[1]}, ${225 - colorMax[2]})`
    : `rgb(${95 + colorMax[0]}, ${95 + colorMax[1]}, ${95 - colorMax[2]})`;
};

export const getGradientPositive = (value: number, maxItem = 100) => {
  const theme = getItem(STORAGE_KEY_THEME) || ThemeEnum.DARK;
  const step = (theme === ThemeEnum.DARK)
    ? [218 / (maxItem as number), 50 / (maxItem as number), 105 / (maxItem as number)]
    : [95 / (maxItem as number), 57 / (maxItem as number), 9 / (maxItem as number)];
  const colorMax = [value * step[0], value * step[1], value * step[2]];
  return (theme === ThemeEnum.DARK)
    ? `rgb(${223 - colorMax[0]}, ${223 - colorMax[1]}, ${225 - colorMax[2]})`
    : `rgb(${95 - colorMax[0]}, ${95 + colorMax[1]}, ${95 + colorMax[2]})`;
};

export const getStepMax = (maxItem: number, isReverse = false) => {
  const theme = getItem(STORAGE_KEY_THEME) || ThemeEnum.DARK;
  return ((theme === ThemeEnum.DARK && !isReverse) || (theme === ThemeEnum.LIGHT && isReverse))
    ? [32 / (maxItem as number), 132 / (maxItem as number), 73 / (maxItem as number)]
    : [168 / (maxItem as number), 68 / (maxItem as number), 118 / (maxItem as number)];
};

export const getStepMin = (minItem: number, isReverse = false) => {
  const theme = getItem(STORAGE_KEY_THEME) || ThemeEnum.DARK;
  return ((theme === ThemeEnum.DARK && !isReverse) || (theme === ThemeEnum.LIGHT && isReverse))
    ? [175 / (minItem as number), 68 / (minItem as number), 51 / (minItem as number)]
    : [25 / (minItem as number), 132 / (minItem as number), 140 / (minItem as number)];
};

export const getMatrixColor = (
  isActive: boolean | null | undefined,
  uid: number | null | undefined,
  value: number,
  stepColorConfig: { max: number[]; min: number[] },
  isReverse = false,
  field: string,
) => {
  if (['roi', 'roiPercent'].includes(field)) {
    return getRoiColor(value);
  }
  if (['cr', 'rkj', 'pkj'].includes(field)) {
    return getCustomColor(value);
  }
  const { max, min } = stepColorConfig;
  const theme = getItem(STORAGE_KEY_THEME) || ThemeEnum.DARK;
  const step = ((value as number) > 0) ? max : min;
  const colorMax = [value * step[0], value * step[1], value * step[2]];
  return ((theme === ThemeEnum.DARK && !isReverse) || (theme === ThemeEnum.LIGHT && isReverse))
    ? `rgb(${55 + colorMax[0]}, ${55 + colorMax[1]}, ${64 + colorMax[2]})`
    : `rgb(${255 - colorMax[0]}, ${255 - colorMax[1]}, ${255 - colorMax[2]})`;
};

export const getBgMatrixColor = (
  isActive: boolean | null | undefined,
  uid: number | null | undefined,
  value: number,
  stepColorConfig: { max: number[]; min: number[] },
  isReverse = false,
) => {
  const { max, min } = stepColorConfig;
  const theme = getItem(STORAGE_KEY_THEME) || ThemeEnum.DARK;
  const step = ((value as number) > 0) ? max : min;
  const colorMax = [value * step[0], value * step[1], value * step[2]];
  if (value && value > 0) {
    if (!isActive && uid) {
      return ((theme === ThemeEnum.DARK && !isReverse) || (theme === ThemeEnum.LIGHT && isReverse))
        ? `rgb(${38}, ${38}, ${38})`
        : '';
    }
    return ((theme === ThemeEnum.DARK && !isReverse) || (theme === ThemeEnum.LIGHT && isReverse))
      ? `rgb(${55 + colorMax[0]}, ${55 + colorMax[1]}, ${64 + colorMax[2]})`
      : `rgb(${255 - colorMax[0]}, ${255 - colorMax[1]}, ${255 - colorMax[2]})`;
  }
  return '';
};

export const getGradient = (value: number, lineBoundary?: number) => (value >= 0
  ? getGradientPositive(value, lineBoundary)
  : getGradientNegative(value, lineBoundary));

export const getCleanString = (str: string) => str.replace('$', '').trim();

export const getSumArray = (array: number[]) => (isArray(array)
  ? Number(array.reduce((previous, current) => previous + Number(current), 0).toFixed(2)) : 0
);

export const getAvgArray = (array: number[]) => (isArray(array)
  ? Number((getSumArray(array) / array.length).toFixed(2)) : 0
);

export const getTotal = (summary: string, type: TypeNumberEnum, serie: number[]) => {
  const total = summary === 'sum' ? getSumArray(serie) : getAvgArray(serie);
  return NumberObj.create(total, type);
};

export const getTotalCompare = (summary: string, type: TypeNumberEnum, serie: number[], compareSerie: number[]) => {
  const total = summary === 'sum'
    ? getSumArray(serie) - getSumArray(compareSerie)
    : getAvgArray(serie) - getAvgArray(compareSerie);
  return NumberObj.create(total, type);
};

export const getFilterByName = (columnName: string, filters: Filter[]) => {
  const filter = filters?.find((item) => item.columnName === columnName);
  return filter && filter.value !== 'all' ? filter.value : undefined;
};

export const liveJobsExportLink = 'https://cloud.glo.net/GLOnet/api.php?api_key=127ccb0eea8a706c4c34a16891f84e7b&e=live_jobs&oper=download';

export const spPeriodList = [
  { key: '', name: 'None' },
  { key: 'month', name: 'Month' },
  { key: 'day', name: 'Day' },
];

export const spTypeList = [
  { key: 'next_job', name: 'Next job' },
  { key: 'jobview', name: 'Job View' },
];

export const auth0Roles = [
  { key: 'adv', name: 'Advertiser Only' },
  { key: 'pub_adv', name: 'Publisher + Advertiser' },
];

export const volumeOptions = [
  { key: 'Good', name: 'Good' },
  { key: 'OK', name: 'OK' },
  { key: 'Bad', name: 'Bad' },
];
