import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => (
  {
    inputRoot: {
      paddingTop: '13px !important',
    },
    input: {
      paddingBottom: '2.5px !important',
    },
    tag: {
      height: '18px',
      marginTop: '5px',
      fontSize: '10px',
      '& > span': {
        padding: '0px 8px',
      },
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  }
));

export default useStyles;
