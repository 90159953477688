export const getItem = (key: string, local = true) => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return local ? window.localStorage.getItem(key) : window.sessionStorage.getItem(key);
};

export const removeItem = (
  key: string, local = true,
) => (local ? window.localStorage.removeItem(key) : window.sessionStorage.removeItem(key));

export const saveItem = (
  key: string, value: string, local = true,
) => (local ? window.localStorage.setItem(key, value) : window.sessionStorage.setItem(key, value));
