import { createMuiTheme } from '@material-ui/core/styles';

const lightTheme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    type: 'light',
    secondary: {
      main: '#05ad78',
      dark: '#05ad78',
    },
    primary: {
      main: '#1EB980',
      dark: '#1EB980',
    },
    error: {
      main: '#FF6859',
      contrastText: '#70b7ae',
    },
    background: {
      default: '#f5f8f4',
      paper: 'white',
    },
    text: {
      primary: 'rgb(66, 66, 66)',
      secondary: 'rgb(66, 66, 66)',
      hint: 'rgb(203 237 225)',
    },
    common: {
      black: '#e8e8e8',
      white: 'rgb(66, 66, 66)',
    },
    info: {
      main: 'rgba(0, 0, 0, 0.12)',
      light: 'white',
      dark: 'white',
      contrastText: 'rgba(66, 66, 66, 0.84)',
    },
    success: {
      main: '#4caf50',
      light: '#e6e6e6',
      dark: '#9c9c9c',
      contrastText: 'rgba(224, 224, 224, 1)',
    },
    warning: {
      main: '#e3ecf4',
      contrastText: '#e7ebe5',
      light: '#f5f8f4',
    },
  },
  overrides: {
    MuiFilledInput: {
      inputMarginDense: {
        paddingTop: '18px',
        paddingBottom: '3px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '11px',
          height: '11px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#70b7ae',
          borderRadius: '11px',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '8px',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.9rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        color: 'white',
      },
    },
    MuiTableBody: {
      root: {
        '& > tr:hover, & > tr:hover td > div': {
          backgroundColor: '#e6e6e6',
        },
        '& > tr td:hover > span, & > tr td:hover, & > tr td:hover > div': {
          backgroundColor: '#dddddd !important',
        },
        '& > tr:hover > td': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '0.85rem',
      },
    },
    MuiTab: {
      root: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15.75px',
        lineHeight: '21px',
      },
      textColorInherit: {
        '&.Mui-selected': {
          color: '#1EB980',
        },
      },
    },
    MuiTabs: {
      root: {
        color: 'rgb(66, 66, 66)',
        opacity: 1,
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: '#878787',
      },
      switchBase: {
        color: '#878787',
      },
    },
  },
});

export default lightTheme;
