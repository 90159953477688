import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    padding: 0,
    width: 126,
    '& > :first-child': {
      padding: '6px',
      fontSize: '14px',
    },
  },
  input: {
    '& > :first-child': {
      width: '126px',
      padding: 0,
      '& > input': {
        padding: '7px',
        fontSize: '14px',
      },
    },
  },
  selectLG: {
    width: 225,
    '& > :first-child': {
      padding: '12px 32px 9px 12px',
      fontSize: '1rem',
    },
  },
  inputLG: {
    '& > :first-child': {
      width: '225px',
      '& > input': {
        padding: '12px 32px 9px 12px',
        fontSize: '1rem',
      },
    },
  },
  selectMD: {
    width: '100%',
    '& > :first-child': {
      padding: '8px 32px 8px 9px',
      fontSize: '14px',
    },
  },
  inputMD: {
    '& > :first-child': {
      width: '100%',
      '& > input': {
        padding: '9px 32px 9px 9px',
        fontSize: '14px',
      },
    },
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  text: {
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    width: 'auto',
    flexShrink: 0,
  },
  item: {
    fontSize: '14px',
    height: 32,
  },
  itemLG: {
    fontSize: '1rem',
    height: 40,
  },
  itemMD: {
    fontSize: '14px',
    height: 35,
  },
}));

export default useStyles;
