/* eslint-disable @typescript-eslint/no-empty-function */

export const finishedTaskMenu = [
  // { key: 'done', name: 'Done' },
  { key: 'review', name: 'Review' },
  { key: 'hold', name: 'On Hold' },
  { key: 'completed', name: 'Completed' },
  // { key: 'finished', name: 'Finish' },
  { key: 'started', name: 'In Progress' },
];

export const additionalFinishedTaskMenu = [
  // { key: 'done', name: 'Done' },
  { key: 'review', name: 'Review' },
  { key: 'hold', name: 'On Hold' },
  { key: 'planned', name: 'Planned' },
  { key: 'strategy', name: 'Strategy' },
  { key: 'optimizing_performance', name: 'Performance' },
  { key: 'scaling_rev', name: 'Scaling Rev' },
  { key: 'maximizing_roi', name: 'Max ROI' },
  { key: 'completed', name: 'Completed' },
  { key: 'parked', name: 'Parked' },
  // { key: 'finished', name: 'Finish' },
  { key: 'started', name: 'In Progress' },
];

export const TaskMenuList = [
  { key: 'open', name: 'Open' },
  { key: 'started', name: 'In Progress' },
  { key: 'review', name: 'Review' },
  { key: 'hold', name: 'On Hold' },
  // { key: 'finished', name: 'Finished' },
  // { key: 'done', name: 'Done' },
  { key: 'requested', name: 'Requested' },
];

// export const priorityIncrease = 'rgb(5, 173, 120)';
export const priorityDecrease = 'rgb(255, 104, 89)';
export const priorityOrange = 'rgb(255, 156, 90)';
export const priorityPink = 'rgb(255, 157, 157)';
export const priorityGrey = 'rgb(144, 144, 144)';
export const priorityLightGrey = 'rgb(196, 196, 196)';

export const statusIncrease = 'rgb(5, 173, 120)';
export const statusDecrease = 'rgb(255, 43, 18)';
export const statusOrange = 'rgb(196, 86, 27)';
export const statusLightOrange = 'rgb(255, 87, 34)';
export const statusPink = 'rgb(192, 5, 124)';
export const statusMagenta = 'rgb(128, 0, 128)';
export const statusBlue = 'rgb(14 ,111, 185)';
export const statusBrightGreen = 'rgb(8, 159, 40)';
export const statusDarkRed = 'rgb(120, 32, 32)';
export const statusDarkBlue = 'rgb(3, 56, 101)';

export const getStatusColor = (value: string) => {
  switch (value) {
    case 'done':
      return statusIncrease;
    case 'started':
      return statusLightOrange;
    case 'hold':
      return statusDecrease;
    case 'review':
      return statusOrange;
    case 'planned':
      return statusPink;
    case 'optimizing_performance':
      return statusMagenta;
    case 'scaling_rev':
      return statusBlue;
    case 'maximizing_roi':
      return statusBrightGreen;
    case 'strategy':
      return statusDarkRed;
    case 'parked':
      return statusDarkBlue;
    default:
      return statusIncrease;
  }
};

export const getPriorityColor = (value: string) => {
  switch (value) {
    case '1':
      return priorityDecrease;
    case '2':
      return priorityPink;
    case '3':
      return priorityOrange;
    case '4':
      return priorityGrey;
    case '5':
      return priorityLightGrey;
    default:
      return '';
  }
};

export const getTaskNextState = (value: string | null) => {
  switch (value) {
    case 'requested':
    case 'open':
      return { key: 'started', name: 'Start' };
    case 'started':
      return { key: 'started', name: 'In Progress' };
    // case 'finished':
    //   return { key: 'finished', name: 'Finished' };
    // case 'done':
    //   return { key: 'done', name: 'Done' };
    case 'review':
      return { key: 'review', name: 'Review' };
    case 'planned':
      return { key: 'planned', name: 'Planned' };
    case 'strategy':
      return { key: 'strategy', name: 'strategy' };
    case 'optimizing_performance':
      return { key: 'optimizing_performance', name: 'Performance' };
    case 'scaling_rev':
      return { key: 'scaling_rev', name: 'Scaling Rev' };
    case 'maximizing_roi':
      return { key: 'maximizing_roi', name: 'Max ROI' };
    case 'completed':
      return { key: 'completed', name: 'Completed' };
    case 'parked':
      return { key: 'parked', name: 'Parked' };
    case 'hold':
      return { key: 'hold', name: 'On Hold' };
    case 'reopened':
      return { key: 'open', name: 'Reopened' };
    default:
      return { key: 'started', name: 'Start' };
  }
};
