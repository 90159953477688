import React, {
  FC, ChangeEvent, useContext, useState, useEffect,
} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useApolloClient } from '@apollo/react-hooks';
import { saveItem } from '@/utils';
import { STORAGE_KEY_ROLE } from '@/constants/storage';
import { UserContext } from '@/providers/UserProvider';
import userConfig, { roles } from '@/config/user';
import { IUserProvider, RoleE } from '@/providers/types';
import useStyles from './styles';

const RoleChange: FC = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const { user, setUser } = useContext(UserContext);
  const userRoles = user?.roles || [];
  const [role, setRole] = useState<string>('');

  useEffect(() => {
    setRole(user?.role || '');
  }, [user]);

  return (
    <TextField
      autoComplete="off"
      size="small"
      select
      variant="filled"
      label="Current role:"
      className={classes.select}
      onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>)}
      value={role}
    >
      {
        roles.filter((item) => userRoles.includes(item.key as RoleE)).map((item) => (
          <MenuItem key={`${item.key}-role`} value={item.key}>{item.value}</MenuItem>
        ))
      }
    </TextField>
  );

  async function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    saveItem(STORAGE_KEY_ROLE, value || '');
    const userPrepe = { ...user, role: value as RoleE, config: value ? userConfig[value] : null } as IUserProvider;
    setUser(userPrepe);
    client.resetStore();
  }
};

export default RoleChange;
