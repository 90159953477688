/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Getter, Plugin, Getters } from '@devexpress/dx-react-core';
import { IntegratedGroupingProps } from './types';
import {
  groupRowChecker,
  groupRowLevelKeyGetter,
  groupCollapsedRowsGetter,
  groupedRows,
  expandedGroupRows,
  getColumnExtension,
} from './dx-grid-core.es';

const pluginDependencies = [
  { name: 'GroupingState' },
];

const getCollapsedRowsComputed = (
  { getCollapsedRows }: Getters,
) => groupCollapsedRowsGetter(getCollapsedRows);

const expandedGroupedRowsComputed = (
  {
    rows, grouping, expandedGroups, isExporting,
  }: Getters, isCustomGroup: boolean,
) => {
  if (isCustomGroup) {
    const deleteRow: number[] = rows.reduce((arr: number[], e: any, i: number) => {
      if (e.compoundKey && e.compoundKey.indexOf('undefined') !== -1) arr.push(i);
      return arr;
    }, []);
    deleteRow.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      delete rows[item];
    });
  }
  return expandedGroupRows(rows, grouping, expandedGroups, isExporting);
};

class IntegratedGroupingBase extends React.PureComponent<IntegratedGroupingProps> {
  render() {
    const { columnExtensions, isCustomGroup } = this.props;
    const getColumnCriteria = (columnName: any) => (getColumnExtension(
      columnExtensions, columnName,
    ) as any).criteria;

    const groupedRowsComputed = (
      { rows, grouping, getCellValue }: Getters,
    ) => groupedRows(rows, grouping, getCellValue, getColumnCriteria);

    return (
      <Plugin
        name="IntegratedGrouping"
        dependencies={pluginDependencies}
      >
        <Getter name="isGroupRow" value={groupRowChecker} />
        <Getter name="getRowLevelKey" value={groupRowLevelKeyGetter} />
        <Getter name="getCollapsedRows" computed={getCollapsedRowsComputed} />
        <Getter name="rows" computed={groupedRowsComputed} />
        <Getter name="rows" computed={(_a) => expandedGroupedRowsComputed(_a, isCustomGroup || false)} />
      </Plugin>
    );
  }
}
const IntegratedGrouping: React.ComponentType<IntegratedGroupingProps> = IntegratedGroupingBase;

export default IntegratedGrouping;
