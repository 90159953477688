import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  containerNotify: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 500,
  },
}));

export default useStyles;
