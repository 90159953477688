import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  IntegratedGrouping, GroupingState, SummaryState,
  IntegratedSummary, IntegratedSorting, Sorting, SortingState, FilteringState, IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid, TableHeaderRow, TableGroupRow, TableSummaryRow, TableColumnVisibility, ColumnChooser, Toolbar,
  TableColumnResizing, DragDropProvider, TableColumnReordering, VirtualTable, TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { compareDate } from '@/utils/date';
import { IGroupedTable } from '../../types';

const GroupedTable: FC<IGroupedTable> = ({
  rows, columns, isActiveExpand, isGroup, columnWidths, handleColumnWidthsChange, columnOrder,
  setColumnOrder, TotalCellComponent, hiddenColumns, handleHiddenColumns, isColumnsFilter = false,
  handleCellComponent, HandleRowComponent, group, subgroup, grouping,
  HeaderCellComponent, SummaryItems, groupSummaryItems, defaultSort,
  handleContentComponent, handleSummaryItemComponent,
}) => {
  const [sorting, setSorting] = useState<Sorting[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const integratedSortingColumnExtensions = useMemo(() => {
    const sortingColumns = [];
    const columnTypesDates = ['date', 'month'];
    if (columnTypesDates.includes(group)) {
      sortingColumns.push({ columnName: 'grouped', compare: compareDate });
    }
    if (columnTypesDates.includes(subgroup)) {
      sortingColumns.push({ columnName: 'subgrouped', compare: compareDate });
    }
    return sortingColumns;
  }, [group, subgroup]);

  useEffect(() => {
    setSorting(defaultSort);
  }, [defaultSort, isGroup]);

  useEffect(() => {
    const expandedGroup = isActiveExpand ? rows.map((item) => String(item.grouped)) : [];
    const uniqueExpandedGroup = [...new Set(expandedGroup)];
    setExpanded(uniqueExpandedGroup);
  }, [isActiveExpand, rows]);

  const setExpandedGroups = (value: string[]) => {
    setExpanded(value);
  };

  return (
    <>
      {
        isGroup && (
          <Grid rows={rows} columns={columns}>
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
            <GroupingState
              grouping={grouping}
              expandedGroups={expanded}
              onExpandedGroupsChange={setExpandedGroups}
            />
            <SummaryState groupItems={groupSummaryItems} totalItems={SummaryItems} />
            <IntegratedGrouping />
            <IntegratedSummary />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <DragDropProvider />
            <VirtualTable rowComponent={HandleRowComponent} cellComponent={handleCellComponent} height="auto" />
            <TableColumnResizing
              resizingMode="widget"
              columnWidths={columnWidths}
              onColumnWidthsChange={handleColumnWidthsChange}
            />
            <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
            <TableHeaderRow cellComponent={HeaderCellComponent} showSortingControls />
            {
              isColumnsFilter && (<TableFilterRow />)
            }
            <TableSummaryRow totalCellComponent={TotalCellComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumns}
              onHiddenColumnNamesChange={handleHiddenColumns}
              columnExtensions={[{ columnName: 'grouped', togglingEnabled: false }]}
            />
            <Toolbar />
            <ColumnChooser />
            <TableGroupRow
              showColumnsWhenGrouped
              contentComponent={handleContentComponent}
              summaryItemComponent={handleSummaryItemComponent}
            />
          </Grid>
        )
      }
      {
        !isGroup && (
          <Grid rows={rows} columns={columns}>
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
            <SummaryState totalItems={SummaryItems} />
            <IntegratedSummary />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <DragDropProvider />
            <VirtualTable rowComponent={HandleRowComponent} cellComponent={handleCellComponent} height="auto" />
            <TableColumnResizing
              resizingMode="widget"
              columnWidths={columnWidths}
              onColumnWidthsChange={handleColumnWidthsChange}
            />
            <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
            <TableHeaderRow cellComponent={HeaderCellComponent} showSortingControls />
            {
              isColumnsFilter && (<TableFilterRow />)
            }
            <TableSummaryRow totalCellComponent={TotalCellComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumns}
              onHiddenColumnNamesChange={handleHiddenColumns}
              columnExtensions={[{ columnName: 'grouped', togglingEnabled: false }]}
            />
            <Toolbar />
            <ColumnChooser />
          </Grid>
        )
      }
    </>
  );
};

export default GroupedTable;
