/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import { ICatalog } from '@/gql/types/dynamicServer';
import useStyles from './styles';

const FormikAutocompleteWithButton: FC<any> = ({ textFieldProps, ...props }) => {
  const classes = useStyles();
  const { form: { setTouched, setFieldValue }, options, jatRating } = props;
  const [isActive, setIsActive] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const filteredByJat = (isActive && jatRating) && jatRating >= 0
    ? options.filter((item: ICatalog) => item.jatRating && item.jatRating >= jatRating)
    : options;
  const filteredOptions = isActive
    ? filteredByJat.filter((item: ICatalog) => item.isActive)
    : filteredByJat;
  const propsWithButton = isActive && options.length
    ? filteredOptions.concat({ ...options[0], key: '-1', name: 'Show All' })
    : filteredOptions;
  const filteredProps = {
    ...props,
    options: propsWithButton,
  };
  const { error, helperText, ...field } = fieldToTextField(filteredProps);
  const { name } = field;

  return (
    <Autocomplete
      {...filteredProps}
      {...field}
      size="medium"
      onChange={(_, value: ICatalog[]) => {
        getSelectedValues(name, value);
        const filtered = value.filter((item: ICatalog) => item.key !== '-1');
        setFieldValue(name, filtered);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onBlur={() => setTouched({ [name || '']: true })}
      classes={{ inputRoot: classes.inputRoot, input: classes.input, tag: classes.tag }}
      getOptionLabel={(option: ICatalog) => option.name}
      getOptionSelected={(option: ICatalog, value: ICatalog) => option.name === value.name}
      renderInput={(filteredProps) => (
        <TextField
          {...filteredProps}
          {...textFieldProps}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );

  function getSelectedValues(name: string | undefined, value: any) {
    const getShowButton = value.filter((item: ICatalog) => item.key === '-1');
    if (getShowButton.length > 0) {
      setIsActive(false);
      setTimeout(() => {
        setOpen(true);
      }, 10);
    }
  }
};

export default FormikAutocompleteWithButton;
