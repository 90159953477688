import React, { FC } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Icon as IconUI } from '@/ui';
import isString from 'lodash/isString';
import clsx from 'clsx';
import isObject from 'lodash/isObject';
import useStyles from './styles';
import { IProps } from './types';

const IconMenu: FC<IProps> = ({
  Icon, itemPath, currentPath, state,
}) => {
  const classes = useStyles();

  return (
    <>
      {
        isString(Icon) && (
          <ListItemIcon className={clsx(classes.columnIcon, { [classes.columnIconFull]: state })}>
            <IconUI
              path={Icon}
              className={clsx(classes.icon, { [classes.activeIcon]: itemPath.includes(currentPath) })}
            />
          </ListItemIcon>
        )
      }
      {
        isObject(Icon) && (
          <div className={clsx(classes.columnIcon, classes.customIcon, { [classes.columnIconFull]: state })}>
            <Icon className={clsx(classes.icon, { [classes.activeIcon]: itemPath.includes(currentPath) })} />
          </div>
        )
      }
    </>
  );
};

export default IconMenu;
