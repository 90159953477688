import React, { FC } from 'react';
import debounce from 'lodash/debounce';
import { withResizeDetector } from 'react-resize-detector';

interface PropsI {
  refGrid: string | ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;
  widthDetector: number;
  setWidthDetector: (widthDetector: number) => void;
}

const ComponentResizeDetector: FC<PropsI> = ({
  refGrid, widthDetector, setWidthDetector,
}) => {
  const debounceWidthDetector = debounce((width: number) => {
    setWidthDetector(width);
  }, 200);

  const ResizeDetector = ({ width, height }: {width: number; height: number}) => {
    if (widthDetector !== width && width !== undefined) {
      debounceWidthDetector(width);
    }
    return (<div ref={refGrid} />);
  };

  const ComponentResizeDetector = withResizeDetector(ResizeDetector);
  return (
    <ComponentResizeDetector />
  );
};

export default ComponentResizeDetector;
