// TODO: pick up cute colors
export const colors = [
  'rgb(255, 207, 68)', 'rgb(250, 160, 230)', 'rgb(81, 186, 128)', 'rgb(180, 135, 220)',
  'rgb(78, 231, 246)', 'rgb(0, 130, 251)', 'rgb(255, 104, 89)', 'rgb(248, 140, 4)',
  'rgb(93, 176, 255)', 'rgb(255, 232, 93)', 'rgb(131, 219, 255)', 'rgb(255, 75, 134)',
  'rgb(0, 255, 27)', 'rgb(72, 255, 237)', 'rgb(238, 130, 238)', 'rgb(244, 111, 111)',
  'rgb(0, 204, 255)', 'rgb(204, 255, 255)', 'rgb(255, 255, 0)', 'rgb(70, 130, 180)',
  'rgb(153, 255, 0)', 'rgb(255, 153, 0)', 'rgb(0, 255, 255)', 'rgb(51, 153, 255)',
  'rgb(218, 165, 32)', 'rgb(153, 102, 204)', 'rgb(51, 204, 153)', 'rgb(255, 204, 0)',
  'rgb(51, 204, 204)',
  'rgb(255, 207, 68)', 'rgb(250, 160, 230)', 'rgb(81, 186, 128)', 'rgb(180, 135, 220)',
  'rgb(78, 231, 246)', 'rgb(0, 130, 251)', 'rgb(255, 104, 89)', 'rgb(248, 140, 4)',
  'rgb(93, 176, 255)', 'rgb(255, 232, 93)', 'rgb(131, 219, 255)', 'rgb(255, 75, 134)',
  'rgb(0, 255, 27)', 'rgb(72, 255, 237)', 'rgb(238, 130, 238)', 'rgb(244, 111, 111)',
  'rgb(0, 204, 255)', 'rgb(204, 255, 255)', 'rgb(255, 255, 0)', 'rgb(70, 130, 180)',
  'rgb(153, 255, 0)', 'rgb(255, 153, 0)', 'rgb(0, 255, 255)', 'rgb(51, 153, 255)',
  'rgb(218, 165, 32)', 'rgb(153, 102, 204)', 'rgb(51, 204, 153)', 'rgb(255, 204, 0)',
  'rgb(51, 204, 204)',
  'rgb(255, 207, 68)', 'rgb(250, 160, 230)', 'rgb(81, 186, 128)', 'rgb(180, 135, 220)',
  'rgb(78, 231, 246)', 'rgb(0, 130, 251)', 'rgb(255, 104, 89)', 'rgb(248, 140, 4)',
  'rgb(93, 176, 255)', 'rgb(255, 232, 93)', 'rgb(131, 219, 255)', 'rgb(255, 75, 134)',
  'rgb(0, 255, 27)', 'rgb(72, 255, 237)', 'rgb(238, 130, 238)', 'rgb(244, 111, 111)',
  'rgb(0, 204, 255)', 'rgb(204, 255, 255)', 'rgb(255, 255, 0)', 'rgb(70, 130, 180)',
  'rgb(153, 255, 0)', 'rgb(255, 153, 0)', 'rgb(0, 255, 255)', 'rgb(51, 153, 255)',
  'rgb(218, 165, 32)', 'rgb(153, 102, 204)', 'rgb(51, 204, 153)', 'rgb(255, 204, 0)',
  'rgb(51, 204, 204)',
  'rgb(255, 207, 68)', 'rgb(250, 160, 230)', 'rgb(81, 186, 128)', 'rgb(180, 135, 220)',
  'rgb(78, 231, 246)', 'rgb(0, 130, 251)', 'rgb(255, 104, 89)', 'rgb(248, 140, 4)',
  'rgb(93, 176, 255)', 'rgb(255, 232, 93)', 'rgb(131, 219, 255)', 'rgb(255, 75, 134)',
  'rgb(0, 255, 27)', 'rgb(72, 255, 237)', 'rgb(238, 130, 238)', 'rgb(244, 111, 111)',
  'rgb(0, 204, 255)', 'rgb(204, 255, 255)', 'rgb(255, 255, 0)', 'rgb(70, 130, 180)',
  'rgb(153, 255, 0)', 'rgb(255, 153, 0)', 'rgb(0, 255, 255)', 'rgb(51, 153, 255)',
  'rgb(218, 165, 32)', 'rgb(153, 102, 204)', 'rgb(51, 204, 153)', 'rgb(255, 204, 0)',
  'rgb(51, 204, 204)',
];

export const rgbaToRgb = (rgb: string, opacity: number) => {
  const color = rgb.slice(4, -1).split(',');

  const r = Math.round(((1 - opacity) * 255) + (opacity * Number(color[0])));
  const g = Math.round(((1 - opacity) * 255) + (opacity * Number(color[1])));
  const b = Math.round(((1 - opacity) * 255) + (opacity * Number(color[2])));

  return `rgb(${r},${g},${b})`;
};

export const colorIncrease = 'rgb(5, 173, 120)';
export const colorDecrease = 'rgb(255, 104, 89)';

export const colorBrightGreen = 'rgb(107, 230, 25)';

export const colorLightGreen = 'rgb(134, 205, 153)';
export const colorDarkerGreen = 'rgb(82, 182, 149)';

export const colorYellow = 'rgb(255, 156, 90)';
export const colorPink = 'rgb(255, 157, 157)';

export const colorGrey = 'rgb(144, 144, 144)';
export const colorLightGrey = 'rgb(196, 196, 196)';

export const colorBlue = 'rgb(9,79,133)';
