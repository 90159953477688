/* eslint-disable arrow-body-style */
import React, { FC } from 'react';
import {
  Grid, TableHeaderRow, TableGroupRow, TableSummaryRow, TableColumnVisibility, ColumnChooser, Toolbar,
  TableColumnResizing, DragDropProvider, TableColumnReordering, VirtualTable, TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SummaryState,
  GroupingState, IntegratedSummary, FilteringState, IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import IntegratedGrouping from '../../CustomPlugin/IntegratedGroupingBase/IntegratedGroupingBase';
import { ICustomGroupedTable } from '../../types';

const CustomGroupedTable: FC<ICustomGroupedTable> = ({
  rows, columns, groupSummaryItems, SummaryItems, HandleRowComponent,
  handleCellComponent, columnWidths, handleColumnWidthsChange, columnOrder,
  setColumnOrder, HeaderCellComponent, TotalCellComponent, hiddenColumns,
  handleHiddenColumns, CustomToolbar, handleContentComponent, handleSummaryItemComponent, isColumnsFilter = false,
}) => {
  return (
    <Grid rows={rows} columns={columns}>
      <GroupingState defaultGrouping={[{ columnName: 'level0' }, { columnName: 'level1' }, { columnName: 'level2' }, { columnName: 'level3' }]} />
      <SummaryState groupItems={groupSummaryItems} totalItems={SummaryItems} />
      <IntegratedGrouping isCustomGroup />
      <IntegratedSummary />
      <FilteringState defaultFilters={[]} />
      <IntegratedFiltering />
      <DragDropProvider />
      <VirtualTable rowComponent={HandleRowComponent} cellComponent={handleCellComponent} height="auto" />
      <TableColumnResizing
        resizingMode="widget"
        columnWidths={columnWidths}
        onColumnWidthsChange={handleColumnWidthsChange}
      />
      <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
      <TableHeaderRow cellComponent={HeaderCellComponent} />
      {
        isColumnsFilter && (<TableFilterRow />)
      }
      <TableSummaryRow totalCellComponent={TotalCellComponent} />
      <TableColumnVisibility
        hiddenColumnNames={hiddenColumns}
        onHiddenColumnNamesChange={handleHiddenColumns}
      />
      <Toolbar />
      <CustomToolbar />
      <ColumnChooser />
      <TableGroupRow
        showColumnsWhenGrouped
        contentComponent={handleContentComponent}
        summaryItemComponent={handleSummaryItemComponent}
      />
    </Grid>
  );
};

export default CustomGroupedTable;
