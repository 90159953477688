/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext, FC, useState, useEffect,
} from 'react';
import userConfig from '@/config/user';
import { useAuth0 } from '@auth0/auth0-react';
import { removeItem, saveItem } from '@/utils/storage';
import {
  STORAGE_KEY_IS_AUTH, STORAGE_KEY_RETURN_PATH, STORAGE_KEY_ROLE, STORAGE_KEY_TOKEN,
} from '@/constants/storage';
import { SIGN_IN } from '@/constants/routes';
import throttle from 'lodash/throttle';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { ADD_NOTIFICATION } from '@/gql/query/notification';
import { NotificationE } from '@/ui';
import { IUserProvider, RoleE } from './types';

interface IProps {
  user: IUserProvider;
  setUser: (user: IUserProvider) => void;
}

export const UserContext = createContext<IProps>({
  user: null, setUser: (user: IUserProvider): void => {},
});

const auth0Namespace = 'https://glo.com/';

const UserProvider: FC = ({ children }) => {
  const [userData, setUserData] = useState<IUserProvider>(null);
  const client = useApolloClient();
  const noActiveDelay = 60; // 60 min
  const timeInterval = 1000 * 60; // 1 min
  const messageTime = 55; // 55 min

  const {
    user,
    isAuthenticated,
    // getIdTokenClaims,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [addNotification] = useMutation(ADD_NOTIFICATION);

  useEffect(() => {
    if (isAuthenticated) {
      saveItem(STORAGE_KEY_IS_AUTH, 'User logged in');
    }
    if (!isAuthenticated) {
      saveItem(STORAGE_KEY_RETURN_PATH, window.location.pathname, false);
      removeItem(STORAGE_KEY_IS_AUTH);
      removeItem(STORAGE_KEY_TOKEN); // TODO: fix on token
      removeItem(STORAGE_KEY_ROLE);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      const roles: RoleE[] = (user[`${auth0Namespace}roles`] || [])
        .map((item: string) => reducerRole(item))
        .filter((item: RoleE | string) => Object.values(RoleE).includes(item as RoleE));
      const role = reducerRole(user[`${auth0Namespace}role`]) || null;
      saveItem(STORAGE_KEY_TOKEN, user.email); // TODO: fix on token
      saveItem(STORAGE_KEY_ROLE, role || '');
      client.resetStore();
      setUserData({
        email: user.email,
        role,
        roles,
        name: user.name,
        username: user.nickname,
        picture: user.picture,
        config: role ? userConfig[role] : null,
      });
    }
  }, [client, user]);

  useEffect(() => {
    console.log('useEffect');
    let nowNoActive = 0;
    // eslint-disable-next-line no-implied-eval
    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      nowNoActive++;
    }, timeInterval);
    setInterval(updateChat, timeInterval);
    document.onmousemove = activeUser;
    let refreshToken: any = null;

    async function activeUser() {
      // console.log('activeUser');
      nowNoActive = 0;
      if (!refreshToken && isAuthenticated) {
        refreshToken = throttle(async () => {
          try {
            console.log('activeUser: token');
            const tokenSilently = await getAccessTokenSilently();
            console.log('tokenSilently', tokenSilently);
          } catch (e) {
            console.log('error tokenSilently', e);
          } finally {
            refreshToken = null;
          }
        }, timeInterval, { leading: false });
        refreshToken();
      }
    }
    async function updateChat() {
      console.log('updateChat', nowNoActive);
      if (nowNoActive >= noActiveDelay) {
        nowNoActive = 0;
        // const token = await getIdTokenClaims();
        // if (!token) {
        logout({ returnTo: window.location.origin + SIGN_IN });
        // }
      }
      if (nowNoActive === messageTime) {
        const leftTime = noActiveDelay - messageTime;
        addNotification({
          variables: {
            message: `If there is no activity in the system, the user will be logged out in ${leftTime} minutes`,
            status: true,
            type: NotificationE.Error,
          },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{ user: userData, setUser: setUserData }}>
      {children}
    </UserContext.Provider>
  );

  function reducerRole(item: string) {
    switch (item) {
      case 'advertiser':
        return RoleE.ADV;
      case 'publisher':
        return RoleE.PUB;
      case 'admin':
        return RoleE.ADMIN;
      case 'trade':
        return RoleE.TRADE;
      default:
        return '';
    }
  }
};

export default UserProvider;
