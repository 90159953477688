import { createMuiTheme } from '@material-ui/core/styles';

const darkTheme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    type: 'dark',
    secondary: {
      main: '#05ad78',
      dark: '#05ad78',
    },
    primary: {
      main: '#1EB980',
      dark: '#1EB980',
    },
    error: {
      main: '#FF6859',
      contrastText: '#32514D',
    },
    background: {
      default: '#33333D',
      paper: '#373740',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      hint: 'rgb(51 84 78)',
    },
    common: {
      black: '#25282f',
      white: 'rgba(255, 255, 255, 0.64)',
    },
    info: {
      main: 'rgba(82, 83, 92, 0.4)',
      light: '#1e272e',
      dark: '#1E2126',
      contrastText: 'rgba(255, 255, 255, 0.84)',
    },
    success: {
      main: '#4caf50',
      light: '#42424c',
      dark: '#53535c',
      contrastText: 'rgba(81, 81, 81, 1)',
    },
    warning: {
      main: '#3e3e50',
      contrastText: '#2c2e35',
      light: '#2f3038',
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        color: 'rgba(255, 255, 255, 0.6)',
        backgroundColor: '#25282F',
        '&:hover': {
          backgroundColor: '#25282F',
        },
        '&:hover .MuiSvgIcon-root': {
          color: '#05ad78',
        },
        '&.Mui-focused': {
          backgroundColor: '#25282F',
        },
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid transparent',
        },
        '&:hover:before': {
          borderBottom: '1px solid transparent',
        },
        '&:after': {
          borderBottom: '2px solid #05ad78',
        },
      },
      inputMarginDense: {
        paddingTop: '18px',
        paddingBottom: '3px',
      },
    },
    MuiIconButton: {
      root: {
        opacity: 0.6,
        '&:hover': {
          opacity: 1,
        },
        padding: '8px',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.9rem',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '11px',
          height: '11px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#32514D',
          borderRadius: '11px',
        },
      },
    },
    MuiTableBody: {
      root: {
        '& > tr:hover, & > tr:hover td > div': {
          backgroundColor: '#42424c',
        },
        '& > tr td:hover > span, & > tr td:hover, & > tr td:hover > div': {
          backgroundColor: '#53535c !important',
        },
        '& > tr:hover > td': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '0.85rem',
      },
    },
    MuiTab: {
      root: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15.75px',
        lineHeight: '21px',
      },
      textColorInherit: {
        '&.Mui-selected': {
          color: '#1EB980',
        },
      },
    },
    MuiTabs: {
      root: {
        color: '#ffffff',
        opacity: 1,
      },
    },
  },
});

export default darkTheme;
