import React, { FC, ComponentType } from 'react';
import {
  Route, Redirect, RouteProps, useLocation, useHistory,
} from 'react-router';
import { getItem } from '@/utils';
import * as ROUTES from '@/constants/routes';
import { STORAGE_KEY_IS_AUTH } from '@/constants/storage';
import CatalogsProvider from '@/providers/CatalogsProvider';
// import JobsProvider from '@/providers/JobsProvider';

const ProtectedRoute: FC<RouteProps & { component: ComponentType }> = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const history = useHistory();

  if (location.pathname === ROUTES.HOME) {
    // TODO: do without condition
    const routeRedirect = process.env.REACT_APP_EFC ? ROUTES.PERFORMANCE_OVERVIEW : ROUTES.PERFORMANCE_TRENDS;
    history.replace(routeRedirect, location);
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (getItem(STORAGE_KEY_IS_AUTH)) {
          return (
            <CatalogsProvider>
              {
                process.env.REACT_APP_EFC ? (
                  // <JobsProvider>
                  <Component {...props} />
                  // </JobsProvider>
                ) : (
                  <Component {...props} />
                )
              }
            </CatalogsProvider>
          );
        }
        return (
          <Redirect
            to={{
              pathname: ROUTES.SIGN_IN,
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
