import { SIGN_IN } from '@/constants/routes';
import history from './utils/history';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname,
  );
};

const providerConfig = {
  domain: 'glonet.us.auth0.com' || '', // TODO: process.env.REACT_APP_DOMAIN || ''
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  // audience: 'https://api.glo.net/v1/jobs',
  redirectUri: window.location.origin + SIGN_IN,
  onRedirectCallback,
};

export default providerConfig;
