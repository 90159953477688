import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    paddingTop: '16px',
    paddingLeft: '37px',
    paddingBottom: '8px',
  },
  dafaultBackground: {
    background: theme.palette.background.paper,
  },
  navigate: {
    display: 'flex',
    color: theme.palette.text.primary,
    opacity: '0.68',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.78',
    },
  },
  active: {
    color: '#1EB980',
    opacity: '1',
  },
}));

export default useStyles;
