import React, {
  FC, useEffect, useState, useCallback,
} from 'react';
import {
  PagingState,
  CustomPaging,
  SortingState,
  SummaryState,
  IntegratedSummary,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnReordering,
  DragDropProvider,
  TableColumnResizing,
  TableFixedColumns,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { IStandardTable } from '@/components/TableUniversal/types';
import { isNull } from 'lodash';

const StandardTable: FC<IStandardTable> = ({
  rows, columns, SummaryItems, summaryCalculator, params,
  handleChangeSorting, columnsExtension, isPaging, isEdit,
  isFilter, isColumnsFilter = false, currentPage, handleChangePage, handlePageSizeChange,
  totalCount, handleCellComponent, HandleRowComponent,
  columnOrder, setColumnOrder, hiddenColumns, handleHiddenColumns,
  columnWidths, handleColumnWidthsChange, HeaderCellComponent, CustomToolbar,
  TotalCellComponent, leftFixedColumns, pageSizes, PagingContainer, isVisible,
}) => {
  const memoCompareFiltered = useCallback(compareFiltered, [params?.sort]);

  const [integratedSortingColumnExtensions, setIntegratedSortingColumnExtensions] = useState([
    { columnName: 'filtered', compare: compareFiltered },
    { columnName: 'name', compare: compareFiltered },
  ]);
  useEffect(() => {
    setIntegratedSortingColumnExtensions([
      { columnName: 'filtered', compare: memoCompareFiltered },
      { columnName: 'name', compare: memoCompareFiltered },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoCompareFiltered]);

  return (
    <Grid
      rows={rows}
      columns={columns}
    >
      <SummaryState totalItems={SummaryItems} />
      <IntegratedSummary calculator={summaryCalculator} />
      <SortingState
        sorting={params?.sort}
        onSortingChange={handleChangeSorting}
        columnExtensions={columnsExtension}
      />
      <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
      {
        isPaging && (
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={handleChangePage}
            onPageSizeChange={handlePageSizeChange}
            defaultPageSize={params?.take}
          />
        )
      }
      {
        isPaging && (
          <CustomPaging
            totalCount={totalCount}
          />
        )
      }
      <FilteringState defaultFilters={[]} />
      <IntegratedFiltering />
      <DragDropProvider />
      <VirtualTable cellComponent={handleCellComponent} rowComponent={HandleRowComponent} height="auto" />
      <TableColumnReordering
        order={columnOrder}
        onOrderChange={setColumnOrder}
      />
      <TableColumnVisibility
        hiddenColumnNames={hiddenColumns}
        onHiddenColumnNamesChange={handleHiddenColumns}
      />
      <TableColumnResizing
        resizingMode="widget"
        columnWidths={columnWidths}
        onColumnWidthsChange={handleColumnWidthsChange}
      />
      <TableHeaderRow showSortingControls cellComponent={HeaderCellComponent} />
      {
        isColumnsFilter && (<TableFilterRow />)
      }
      {
        isVisible && (<Toolbar />)
      }
      {
        (isEdit || isFilter) && (
          <CustomToolbar />
        )
      }
      {
        isVisible && (<ColumnChooser />)
      }
      <TableSummaryRow totalCellComponent={TotalCellComponent} />
      <TableFixedColumns leftColumns={leftFixedColumns} />
      {
        isPaging && (
          <PagingPanel pageSizes={pageSizes} containerComponent={PagingContainer} />
        )
      }
    </Grid>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function compareFiltered(a: any, b: any) {
    const sort = params?.sort && params?.sort.length > 0 ? params?.sort[0].direction : 'asc';

    if (a === b) {
      return 0;
    }
    if (isNull(a)) {
      return sort === 'asc' ? 1 : -1;
    }
    if (isNull(b)) {
      return sort === 'asc' ? -1 : 1;
    }
    return (a.trim().toLowerCase() < b.trim().toLowerCase()) ? -1 : 1;
  }
};

export default StandardTable;
