import { Theme } from '@material-ui/core';
import lightTheme from './light';
import darkTheme from './dark';

const themeMap: { [key: string]: Theme } = {
  lightTheme,
  darkTheme,
};

const getThemeByName = (theme: string): Theme => themeMap[theme];

export default getThemeByName;

// TODO: SelectProps и MenuProps
export const SelectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: '376px',
      },
    },
  },
};

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '376px',
      width: 250,
    },
  },
};

export const MultipleSelectProps = {
  PaperProps: {
    style: {
      maxHeight: '376px',
      width: 200,
    },
  },
};
