/* eslint-disable react/require-default-props */
import React, {
  MouseEvent, useState, useMemo,
} from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  IFilterAdditional, TypeFilterEnum, TypeOperationEnum,
} from '@/components/Filter/types';
import FormControl from '@material-ui/core/FormControl';
import { MenuProps } from '@/theme/base';
import { DateRangePickerRsuite } from '@/ui/DateRangePickerRsuite';
import { ValueType } from 'rsuite/lib/DateRangePicker/DateRangePicker';
import { SelectInput } from '@/ui/SelectInput';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getDateFilterFormat, parserBetweenDates } from '@/utils/date';
import useStyles from '../styles';

const CellHeader = (
  props: TableHeaderRow.CellProps & {
    type: string;
    className?: string;
    additional?: IFilterAdditional;
    columnname: string;
    value?: string;
    initOperation?: string;
    title: string;
    catalog: Record<string, string>[];
    functionfilter?: Record<string, Function | undefined>;
 },
) => {
  const {
    type, className, additional, columnname, value, title, catalog, functionfilter, initOperation,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filter, setFilter] = useState(value || '');
  const [operation, setOperation] = useState(initOperation || ''); // TODO: использовать во всех фильтрах

  const filterDate = useMemo<ValueType>(() => {
    if (TypeFilterEnum.DATERANGE === type) {
      return parserBetweenDates(filter) as ValueType;
    }
    return [];
  }, [filter, type]);

  switch (type) {
    case TypeFilterEnum.INPUT:
      return (
        <>
          <TableHeaderRow.Cell
            {...props}
            className={clsx(className, classes.header, classes.cellLink)}
            onClick={handleAddPreset}
          />
          <Popover
            id="preset-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <div className={classes.popover}>
              <TextField
                key={`${columnname}-input`}
                autoComplete="off"
                required
                size="small"
                label={title}
                variant="filled"
                value={filter}
                onChange={({ target: { value } }) => onChange(value)}
              />
              <div className={classes.saveWrap}>
                <Button color="secondary" onClick={() => onHandle(additional?.operation || TypeOperationEnum.CN)}>Apply</Button>
              </div>
            </div>
          </Popover>
        </>
      );
    case TypeFilterEnum.SELECT:
      return (
        <>
          <TableHeaderRow.Cell
            {...props}
            className={clsx(className, classes.header, classes.cellLink)}
            onClick={handleAddPreset}
          />
          <Popover
            id="preset-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <div className={classes.popover}>
              <FormControl variant="filled">
                <Select
                  key={`${columnname}-select`}
                  value={filter}
                  onChange={({ target: { value } }) => onChange(value)}
                  className={classes.selectFilter}
                  displayEmpty
                  MenuProps={MenuProps}
                >
                  <MenuItem key={`${columnname}-menuItem`} value="">Select</MenuItem>
                  {
                  !!catalog && catalog.map((menuItem) => (
                    <MenuItem key={`${menuItem.key}-menuItem`} value={menuItem.key}>{menuItem.name}</MenuItem>
                  ))
                }
                </Select>
              </FormControl>
              <div className={classes.saveWrap}>
                <Button color="secondary" onClick={() => onHandle(TypeOperationEnum.EQ)}>Apply</Button>
              </div>
            </div>
          </Popover>
        </>
      );
    case TypeFilterEnum.DATERANGE:
      return (
        <>
          <TableHeaderRow.Cell
            {...props}
            className={clsx(className, classes.header, classes.cellLink)}
            onClick={handleAddPreset}
          />
          <Popover
            id="preset-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <div className={classes.popover}>
              <FormControl variant="filled">
                <DateRangePickerRsuite value={filterDate} onChange={handleDate} placement="bottomStart" />
              </FormControl>
              <div className={classes.saveWrap}>
                <Button color="secondary" onClick={() => onHandle(TypeOperationEnum.EQ)}>Apply</Button>
              </div>
            </div>
          </Popover>
        </>
      );
    case TypeFilterEnum.SELECTINPUT:
      return (
        <>
          <TableHeaderRow.Cell
            {...props}
            className={clsx(className, classes.header, classes.cellLink)}
            onClick={handleAddPreset}
          />
          <Popover
            id="preset-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <div className={classes.popover}>
              <FormControl variant="filled">
                <SelectInput
                  key={`${columnname}-selectInput`}
                  keyItem={columnname}
                  inputItem={filter}
                  handleInputChange={(value) => handleFilterValue(value, 'value')}
                  selectItem={operation as string}
                  handleSelectChange={(value) => handleFilterValue(value, 'operation')}
                  handleClear={() => handleFilterValue('', 'reset')}
                  menuItems={additional?.customCatalog || []}
                  size="lg"
                />
              </FormControl>
              <div className={classes.saveWrap}>
                <Button color="secondary" onClick={() => onHandle(operation)}>Apply</Button>
              </div>
            </div>
          </Popover>
        </>
      );
    case TypeFilterEnum.BOOLEAN:
      return (
        <>
          <TableHeaderRow.Cell
            {...props}
            className={clsx(className, classes.header, classes.cellLink)}
            onClick={handleAddPreset}
          />
          <Popover
            id="preset-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <div className={classes.popover}>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item className={classes.textSwitch}>No</Grid>
                  <Grid item>
                    <Switch
                      key={`${columnname}-switch`}
                      checked={!!Number(filter)}
                      onChange={() => onChange(!Number(filter) ? '1' : '0')}
                    />
                  </Grid>
                  <Grid item className={classes.textSwitch}>Yes</Grid>
                </Grid>
              </Typography>

              <div className={classes.saveWrap}>
                <Button color="secondary" onClick={() => onHandle(additional?.operation || TypeOperationEnum.CN)}>Apply</Button>
              </div>
            </div>
          </Popover>
        </>
      );
    default:
      return <TableHeaderRow.Cell {...props} className={clsx(className, classes.header)} />;
  }

  function handleAddPreset(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function onHandle(operation: string) {
    if (functionfilter?.handleFilter) {
      // TODO: columnName: columnname
      functionfilter.handleFilter({ columnName: columnname, value: filter, operation });
    }
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function onChange(value: unknown) {
    setFilter(value as string);
  }

  function handleDate(value: ValueType) {
    setFilter(getDateFilterFormat(value[0] as Date, value[1] as Date));
  }

  function handleFilterValue(value: string, type?: string) {
    if (type === 'value') {
      setFilter(value);
    }
    if (type === 'operation') {
      setOperation(value);
    }
    // TODO: does not work correctly
    if (type === 'reset') {
      setFilter('');
      setOperation('');
      // TODO: repeat
      if (functionfilter?.handleFilter) {
        functionfilter.handleFilter({ columnName: columnname, value: '', operation: '' });
      }
    }
  }
};

export default CellHeader;
