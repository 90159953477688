import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 206;
const drawerWidthClosed = 60;

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    padding: `${theme.spacing(2)}px 0`,
    // background: '#33333D',
    minWidth: '960px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  main: {
    marginBottom: theme.spacing(4),
    marginLeft: `${drawerWidthClosed}px`,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  mainOpen: {
    marginBottom: theme.spacing(4),
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  mainAuth: {
    width: '100%',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(8),
    paddingRight: theme.spacing(1),
  },
  drawerPaper: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(9.75),
    zIndex: 1099,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      zIndex: '1101',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      marginTop: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerWidthClosed,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthClosed,
    },
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  footer: {
    background: theme.palette.background.default,
    height: '49px',
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 1101,
    width: '100%',
    borderTop: `solid 2px ${theme.palette.info.main}`,
    fontSize: '11px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.spacing(3)}px`,
  },
  containerFooterText: {
    margin: 0,
    marginTop: '2px',
  },
  footerText: {
    opacity: 0.6,
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: 'underline',
    '&:hover': {
      opacity: 1,
    },
  },
  navigation: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default useStyles;
