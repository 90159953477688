/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import { ICatalog } from '@/gql/types/dynamicServer';
import useStyles from './styles';

const FormikAutocomplete: FC<any> = ({ textFieldProps, ...props }) => {
  const classes = useStyles();
  const { form: { setTouched, setFieldValue } } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  return (
    <Autocomplete
      {...props}
      {...field}
      size="medium"
      onChange={(_, value) => setFieldValue(name, value)}
      onBlur={() => setTouched({ [name || '']: true })}
      classes={{ inputRoot: classes.inputRoot, input: classes.input, tag: classes.tag }}
      getOptionLabel={(option: ICatalog) => option.name}
      getOptionSelected={(option: ICatalog, value: ICatalog) => option.name === value.name}
      renderInput={(props) => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
};

export default FormikAutocomplete;
