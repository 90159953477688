import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  day: {
    margin: 0,
    width: '40px',
    borderRadius: '0',
    transition: 'none',
  },
  beginCap: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endCap: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  select: {
    width: 250,
    marginLeft: theme.spacing(2),
    '& p': {
      display: 'none',
    },
  },
  selectBtn: {
    marginRight: -9,
  },
  calendar: {
    display: 'none',
  },
}));

export default useStyles;
