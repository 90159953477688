import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  panelDateRange: {
    textAlign: 'end',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
    '& > *:last-child': {
      marginRight: theme.spacing(1),
    },
  },
  select: {
    minWidth: '130px',
  },
  button: {
    fontSize: '12px',
    color: '#1EB980',
    minWidth: '80px',
  },
  active: {
    background: theme.palette.text.hint,
  },
}));

export default useStyles;
