import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    '& th[class*="EditColumn"], & td[class*="EditColumn"]': {
      textAlign: 'right',
      paddingRight: theme.spacing(3),
    },
    '& th[class*="TableFilterCell"]:first-child > div > div': {
      marginLeft: 0,
    },
    position: 'relative',
    '& > div > div table': {
      marginBottom: '0 !important',
    },
  },
  cellLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  cursor: {
    cursor: 'pointer',
  },
  cellController: {
    padding: 0,
  },
  header: {
    color: theme.palette.common.white,
    fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
    paddingTop: '15px',
    paddingBottom: '14px',
    cursor: 'pointer',
  },
  row: {
    color: theme.palette.info.contrastText,
    paddingTop: '14px',
    paddingBottom: '14px',
  },
  cell: {
    paddingTop: '14px',
    paddingBottom: '14px',
  },
  rowGroup: {
    color: theme.palette.text.primary,
    opacity: 0.84,
  },
  summaryRow: {
    fontSize: '0.8125rem',
    fontWeight: 700,
  },
  containerAddRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '16px',
  },
  toolbar: {
    minHeight: '64px',
  },
  popover: {
    padding: theme.spacing(2),
  },
  saveWrap: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
  },
  selectFilter: {
    width: 225,
    '& > :first-child': {
      padding: '14px',
    },
  },
  increasing: {
    color: 'rgb(5, 173, 120)',
  },
  decreasing: {
    color: 'rgb(255, 104, 89)',
  },
  lightBlue: {
    color: 'rgb(25 152 162)',
  },
  lightGray: {
    backgroundColor: 'rgb(238, 238, 238)',
  },
  darkerGray: {
    backgroundColor: 'rgb(212, 212, 212)',
  },
  gray: {
    backgroundColor: 'rgb(46, 46, 55)',
  },
  darkGray: {
    backgroundColor: 'rgb(28, 28, 28)',
  },
  lightGreen: {
    color: 'rgb(134, 205, 153)',
  },
  brightGreen: {
    color: 'rgb(107, 230, 25)',
    fontWeight: 700,
  },
  darkerGreen: {
    color: 'rgb(82, 182, 149)',
  },
  yellow: {
    color: 'rgb(255, 156, 90)',
  },
  yellowBg: {
    backgroundColor: 'rgb(64, 64, 40)',
  },
  yellowLightBg: {
    backgroundColor: 'rgb(252, 252, 171)',
  },
  orange: {
    color: 'rgb(196,84,3)',
  },
  pink: {
    color: 'rgb(255, 157, 157)',
  },
  blinking: {
    animation: '$blinking 1s 2',
  },
  '@keyframes blinking': {
    from: { backgroundColor: theme.palette.success.dark },
    to: { backgroundColor: 'inherit' },
  },
  colorSwitchBase: {
    color: theme.palette.error.main,
  },
  colorSwitchTrack: {
    backgroundColor: theme.palette.error.main,
  },
  arrow: {
    marginTop: '1px',
    marginRight: theme.spacing(0.5),
  },
  containerFlex: {
    display: 'flex',
  },
  containerFilter: {
    display: 'flex',
    width: '134px',
    alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(30, 185, 128, 0.08)',
    },
  },
  text: {
    color: '#1EB980',
    fontSize: '12px',
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 500,
    cursor: 'pointer',
    marginTop: '3px',
  },
  component: {
    width: '225px',
  },
  containerSwitch: {
    marginTop: '7px',
    marginBottom: '8px',
  },
  ml8: {
    marginLeft: theme.spacing(1),
  },
  ml6: {
    marginLeft: theme.spacing(2),
  },
  containerCellContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  containerIconContent: {
    marginRight: theme.spacing(4),
    marginTop: '5px',
    opacity: 0.84,
  },
  textSwitch: {
    fontSize: '17px',
  },
  containerButton: {
    display: 'flex',
    width: '147px',
    alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(30, 185, 128, 0.08)',
    },
  },
  cellCheckbox: {
    paddingLeft: '22px',
  },
  ratingFull: {
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    color: 'transparent',
  },
  ratingEmpty: {
    color: '#9c9da2',
  },
  volumeBar: {
    width: '4px',
    height: '8px',
    background: '#9c9da2',
    marginRight: '1px',
  },
  volumeBarFull: {
    width: '4px',
    height: '8px',
    background: '#eaea2d',
    marginRight: '1px',
  },
  button: {
    fontSize: '12px',
    color: '#1EB980',
    minWidth: '80px',
    background: theme.palette.text.hint,
  },
  tooltipFraud: {
    maxWidth: 720,
  },
  editAction: {
    display: 'flex',
  },
  editLink: {
    textDecoration: 'underline',
    width: 40,
    margin: '0 10px 10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  boldCell: {
    fontWeight: 700,
  },
  icon: {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
