import ReactDOM from 'react-dom';
import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink, from,
} from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from '@/app';
import { STORAGE_KEY_TOKEN } from '@/constants/storage';
import { getItem } from '@/utils';
import DateFnsUtils from '@date-io/date-fns';
import { resolversLocal, cacheLocal } from '@/gql';
import { withClientState } from 'apollo-link-state';
import { onError } from '@apollo/link-error';
import { Observable } from 'apollo-link';
// import { nanoid } from 'nanoid';
import { Auth0Provider } from '@auth0/auth0-react';
import { REALJOBS } from '@/constants/domains';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from './providers/ThemeProvider';
// import { GET_NOTIFICATIONS } from './gql/query/notification';
// import { IQuery } from './gql/query/types';
import { getRole } from './config/user';
import providerConfig from './config-auth';
import GhostProvider from './providers/GhostProvider';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {},
    },
  },
});

// const url = REALJOBS.includes(window.location.hostname)
//   ? 'https://api.realjobs.net'
//   : process.env.REACT_APP_GRAPHQL_URL;

const url = REALJOBS.includes(window.location.hostname)
  ? 'https://backend.dev.glo.net'
  : process.env.REACT_APP_GRAPHQL_URL;

const httpLink = new HttpLink({ uri: url });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }: { headers: object }) => ({
    headers: {
      ...headers,
      authorization: getItem(STORAGE_KEY_TOKEN) || '',
      role: getRole() || '',
    },
  }));

  return forward(operation);
});

const listOfMessages = ['Response not successful: Received status code 400', 'Invalid time value'];

const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
}) => new Observable((observer) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.code === 'UNAUTHENTICATED') {
        // TODO: refresh token
      }
    });
    const error = graphQLErrors.filter((item) => item.extensions?.code !== 'UNAUTHENTICATED' && !listOfMessages.includes(item.message));
    const message = error.reduce((acc, cur) => `${acc + cur.message} `, '');
    // error.length && callNotification(message);
    console.log(`[GraphQL error]: ${message}`);
    observer.error({ message });
  }
  if (networkError) {
    const { message } = networkError;
    if (!listOfMessages.includes(message)) {
      // callNotification(networkError.message);
    }
    console.log(`[Network error]: ${networkError}`);
    observer.error(networkError);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any);

const memoryCache = cacheLocal();
cache.writeQuery(memoryCache);

const timeoutLink = new ApolloLinkTimeout(700000);
const timeoutHttpLink = timeoutLink.concat(httpLink);

const apolloClient = new ApolloClient({
  cache,
  link: from([authMiddleware, (errorLink as unknown) as ApolloLink, timeoutHttpLink]),
  connectToDevTools: true,
  resolvers: resolversLocal,
});

apolloClient.onResetStore(async () => {
  const memoryCache = cacheLocal();
  cache.writeQuery(memoryCache);
  const stateLink = withClientState({ cache, resolvers: resolversLocal });
  return stateLink.writeDefaults;
});

const Component = () => (
  <Auth0Provider {...providerConfig} useRefreshTokens cacheLocation="localstorage">
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <GhostProvider>
          <ThemeProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </GhostProvider>
      </BrowserRouter>
    </ApolloProvider>
  </Auth0Provider>
);

serviceWorker.unregister();

ReactDOM.render(<Component />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./app', () => {
    ReactDOM.render(<Component />, document.getElementById('root'));
  });
}

// function callNotification(message: string) {
//   const cacheData = cache.readQuery<IQuery>({ query: GET_NOTIFICATIONS });
//   const id = nanoid();
//   if (cacheData?.getNotifications) {
//     cache.writeQuery({
//       query: GET_NOTIFICATIONS,
//       data: {
//         getNotifications: [
//           ...cacheData.getNotifications,
//           {
//             message, type: 'error', status: true, id, __typename: 'Notification',
//           },
//         ],
//       },
//     });
//   }
// }
