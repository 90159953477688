import React, { FC } from 'react';
import { LoadingCircle } from '@/ui/LoadingCircle';
import clsx from 'clsx';
import useStyles from './styles';
import { IProps } from './types';

const Loading: FC<IProps> = ({
  state, className, transparentBg, ...restProps
}) => {
  const classes = useStyles();

  return (
    <>
      {
        state && (
          <div className={clsx(classes.innerLoading, className, { [classes.transparentBg]: transparentBg })}>
            <LoadingCircle isLoading={state} {...restProps} />
          </div>
        )
      }
    </>
  );
};

export default Loading;
