import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  containerIcon: {
    opacity: 0.87,
    color: '#fff',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(2.5),
  },
  title: {
    marginLeft: theme.spacing(1),
    color: '#05AD78',
    fontSize: '16px',
    fontWeight: 'normal',
    fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
    marginRight: theme.spacing(2),
  },
  containerToolbar: {
    paddingLeft: 0,
    height: '78px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(1),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    ...theme.mixins.toolbar,
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    zIndex: 1102,
  },
  icon: {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  containerLogo: {
    marginTop: theme.spacing(0.75),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  imageLogo: {
    height: '35px',
  },
  containerImageLogo: {
    textAlign: 'center',
  },
  containerElHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  nameCompany: {
    cursor: 'pointer',
  },
  leftEl: {
    width: 'calc(100% - 245px)',
    justifyContent: 'flex-end',
  },
  containerWidjet: {
    maxWidth: 'calc(100% - 790px)',
    marginTop: theme.spacing(1),
  },
  containerRoleChange: {
    padding: theme.spacing(2),
    minWidth: '182px',
  },
  containerPanel: {
    minWidth: '512px',
  },
  customBadge: {
    top: 10,
    right: 10,
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
