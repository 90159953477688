import setWith from 'lodash/setWith';
import { responseResolverSuccess, responseResolverFail } from './utils';
import { IResolverContext } from './types';
import { IQuery } from '../query/types';
import { GET_GLO_OPTIONS } from '../query/common';

const editGloOptions = (
  _root: object, {
    type, options,
  }: { type: string; options: object | string }, { cache }: IResolverContext,
) => {
  const cacheDataQuery = cache.readQuery<IQuery>({ query: GET_GLO_OPTIONS });
  if (cacheDataQuery?.getGloOptions) {
    const path = type.split('.');
    const gloOptions = JSON.parse(JSON.stringify(cacheDataQuery.getGloOptions));
    cache.writeQuery({
      query: GET_GLO_OPTIONS,
      data: {
        getGloOptions: setWith(gloOptions, path, options, Object),
      },
    });
    return responseResolverSuccess('Screener preset option changed successfully!', { type });
  }
  return responseResolverFail('Error: Screener preset option error!', { type });
};

export default editGloOptions;
