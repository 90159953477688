import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  columnIcon: {
    marginLeft: theme.spacing(0.5),
    minWidth: '40px',
  },
  columnIconFull: {
    marginLeft: theme.spacing(0.5),
  },
  icon: {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  activeIcon: {
    fill: '#1EB980',
  },
  customIcon: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    minWidth: '40px',
    marginLeft: '4px',
  },
}));

export default useStyles;
