import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  containerPaper: {
    background: theme.palette.background.default,
    minWidth: '230px',
    height: '36px',
    paddingLeft: '16px',
    paddingTop: '6px',
    paddingRight: '4px',
    paddingBottom: '10px',
    fontSize: '14px',
    lineHeight: '25px',
    position: 'relative',
  },
  text: {
    opacity: '0.7',
    marginRight: '8px',
  },
  containerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    marginTop: '-2px',
  },
  icon: {
    fontSize: '1rem',
  },

  containerPaperSecondary: {
    background: '#616161',
    minWidth: '200px',
    height: '30px',
    paddingLeft: '8px',
    paddingTop: '6px',
    paddingRight: '4px',
    paddingBottom: '10px',
    fontSize: '13px',
    lineHeight: '18px',
    position: 'relative',
  },
  textSecondary: {
    marginRight: '4px',
  },
}));

export default useStyles;
