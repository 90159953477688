import { INumberObj, TypeNumberEnum } from './types';

export const getFinanceNumber = (value: number) => (value).toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const getNumber = (value: number) => (value).toLocaleString('en-US');

export const getPercentNumber = (value: number) => `${getNumber(value)}%`;

const isNumber = (value: number) => !isNaN(value);

const NumberObj = {
  create(value: number, type: TypeNumberEnum = TypeNumberEnum.NUMBER): INumberObj {
    const numValue = Number(value);
    return {
      value: numValue,
      type,
      toString: () => {
        if (!isNumber(Number(numValue))) {
          return String(value);
        }
        switch (type) {
          case TypeNumberEnum.CURRENCY:
            return getFinanceNumber(numValue);
          case TypeNumberEnum.PERCENT:
            return getPercentNumber(numValue);
          case TypeNumberEnum.NUMBER:
            return getNumber(numValue);
          default:
            return String(numValue);
        }
      },
    };
  },
};

export default NumberObj;
