import React, {
  FC, useState, useEffect, useMemo,
} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { EDIT_GLO_OPTIONS } from '@/gql/query/common';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import DateRangePickerRsuite from '@/ui/DateRangePickerRsuite/DateRangePickerRsuite';
import { ValueType } from 'rsuite/lib/DateRangePicker/DateRangePicker';
import {
  getCompareDateInFormatDate,
  getDateFilterFormat, getInFilterToday, getRangeDate, parserBetweenDates,
} from '@/utils/date';
import clsx from 'clsx';
import { TypeOperationEnum } from '@/components/Filter/types';
import isEqual from 'lodash/isEqual';
import { DateCompareE } from '@/utils/date/types';
import { listCompare, listCompareRT } from '@/utils/date/config';
import useStyles from './styles';
import { IProps } from './types';

const PanelPerformance: FC<IProps> = ({
  filterByDate, compareType, initDateCompare, type, isRealtime,
}) => {
  const classes = useStyles();
  const [editGloOptions] = useMutation(EDIT_GLO_OPTIONS);

  const [rangeDate, setRangeDate] = useState<ValueType>(getRangeDate(filterByDate));
  const [compareDate, setCompareDate] = useState<ValueType>(parserBetweenDates(initDateCompare) as ValueType);
  const [compare, setCompare] = useState<DateCompareE | ''>(compareType ? compareType as DateCompareE : '');

  useEffect(() => {
    const newRangeDate = getRangeDate(filterByDate);
    if (!isEqual(newRangeDate, rangeDate)) {
      setRangeDate(newRangeDate);
    }
  }, [filterByDate, rangeDate]);

  useEffect(() => {
    const newCompareDate = parserBetweenDates(initDateCompare) as ValueType;
    if (!isEqual(newCompareDate, compareDate)) {
      setCompareDate(newCompareDate);
    }
  }, [compareDate, initDateCompare]);

  useEffect(() => {
    if (compare !== compareType) {
      setCompare(compareType ? compareType as DateCompareE : '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareType]);

  const isCustomCompare = useMemo(() => compare === DateCompareE.CUSTOM, [compare]);

  return (
    <section className={classes.panelDateRange}>
      <Button color="primary" className={clsx(classes.button, { [classes.active]: isRealtime })} onClick={setToday}>Real-Time</Button>
      <DateRangePickerRsuite value={rangeDate} onChange={handleDate} />
      <TextField
        autoComplete="off"
        size="small"
        select
        variant="filled"
        label="Compare to:"
        className={classes.select}
        onChange={({ target: { value } }) => handleChangeCompare(value as DateCompareE, rangeDate)}
        value={compare}
      >
        <MenuItem value=""><em>None</em></MenuItem>
        {
          (isRealtime ? listCompareRT : listCompare).map((item) => (
            <MenuItem key={`${item.key}-group`} value={item.key}>{item.value}</MenuItem>
          ))
        }
      </TextField>
      {compare && (
        <section>
          <DateRangePickerRsuite value={compareDate} onChange={handleCompareDate} disabled={!isCustomCompare} />
        </section>
      )}
    </section>
  );

  function setToday() {
    updateOptions(`${type}.isRealtime`, !isRealtime);
    if (compare === DateCompareE.MONTH) {
      handleChangeCompare(DateCompareE.MONTH_DOW, rangeDate);
    }
    if (!isRealtime) {
      const filterToday = getInFilterToday();
      setDateToCache(filterToday.value);
    }
  }

  function handleDate(value: ValueType) {
    setRangeDate(value);
    const filter = getDateFilterFormat(value[0] as Date, value[1] as Date);
    setDateToCache(filter);
    if (compare !== DateCompareE.CUSTOM) {
      handleChangeCompare(compare as DateCompareE, value);
    }
  }

  function handleCompareDate(value: ValueType) {
    setCompareDate(value);
    const filter = getDateFilterFormat(value[0] as Date, value[1] as Date);
    setCompareToCache(DateCompareE.CUSTOM, filter);
  }

  function handleChangeCompare(value: DateCompareE, rangeDate: ValueType) {
    setCompare(value);
    const newCompareDate = getCompareDateInFormatDate(value, rangeDate);

    switch (value) {
      case DateCompareE.DAY:
      case DateCompareE.WEEK:
      case DateCompareE.MONTH_DOW:
      case DateCompareE.MONTH:
        setCompareToCache(value, getDateFilterFormat(newCompareDate[0] as Date, newCompareDate[1] as Date));
        break;
      case DateCompareE.CUSTOM:
        break;
      default:
        setCompareToCache('', '');
        break;
    }
    setCompareDate(newCompareDate);
  }

  function setDateToCache(value: string) {
    updateOptions(`${type}.filterByDate`, [{ columnName: 'date', value, operation: TypeOperationEnum.NULL }]);
  }

  function setCompareToCache(compareType: DateCompareE | '', date: string) {
    updateOptions(`${type}.compareType`, compareType);
    updateOptions(`${type}.dateCompare`, date);
  }

  function updateOptions(type: string, options: object | string | boolean) {
    editGloOptions({ variables: { type, options } });
  }
};

export default PanelPerformance;
