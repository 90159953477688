import '@/theme/global.scss';
import { CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import React, { FC, Suspense, lazy } from 'react';
import * as ROUTES from '@/constants/routes';
import { ProtectedRoute } from '@/components';
import UserProvider from '@/providers/UserProvider';
import { Loading } from '@/components/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from './Layout';

const DashboardPage = lazy(() => import('./Dashboard'));
const Auth0Page = lazy(() => import('./Auth0'));
const JobsPage = lazy(() => import('./Jobs/JobsUser/TableJobs'));
const JobsFeedsPage = lazy(() => import('./Jobs/JobsUser/Feeds'));
const CampaignsUserPage = lazy(() => import('./Jobs/JobsUser/Campaigns'));
const JobsSettingsPage = lazy(() => import('./Jobs/JobsAdmin/JobsSettings'));
const JobsPubSettingsPage = lazy(() => import('./Jobs/JobsAdmin/JobsPubSettings'));
const JobsAdvSettingsPage = lazy(() => import('./Jobs/JobsAdmin/JobsAdvSettings'));
const LiveJobsPage = lazy(() => import('./Jobs/JobsAdmin/LiveJobs'));
const CampaignsPage = lazy(() => import('./Jobs/JobsAdmin/Campaigns'));
const CampaignsMapPage = lazy(() => import('./Jobs/JobsAdmin/CampaignsMap'));
const ImportPage = lazy(() => import('./Jobs/JobsAdmin/Import'));
const ExportPage = lazy(() => import('./Jobs/JobsAdmin/Export'));
const DuplicatesPage = lazy(() => import('./Jobs/JobsAdmin/Duplicates'));
const BlockedPage = lazy(() => import('./Jobs/JobsAdmin/Blocked'));
const FeedPreview = lazy(() => import('./Jobs/JobsAdmin/FeedPreview'));
const LogsPage = lazy(() => import('./Performance/Page/Logs'));
const OverviewPage = lazy(() => import('./Performance/Page/Overview'));
const ReconciliationPage = lazy(() => import('./Performance/Page/Reconciliation'));
const ReportsPage = lazy(() => import('./Performance/Page/Reports'));
const TrendsPage = lazy(() => import('./Performance/Page/Trends'));
const AlertsPage = lazy(() => import('./Performance/Page/Alerts'));
const AdvertisersPage = lazy(() => import('./Network/Page/Advertisers'));
const PublishersPage = lazy(() => import('./Network/Page/Publishers'));
const SettingsPage = lazy(() => import('./Settings/SettingsTable'));
const SmartContractPage = lazy(() => import('./Settings/SmartContracts'));
const ProfilePage = lazy(() => import('./Settings/Profile'));
const SupportPage = lazy(() => import('./Support'));
const NotFoundPage = lazy(() => import('./NotFound'));
const MatrixPage = lazy(() => import('./Trade/Matrix'));
const TQMPage = lazy(() => import('./Trade/TQM'));
const IntentPage = lazy(() => import('./Traffic/Page/Intent'));
const GeoFencePage = lazy(() => import('./Traffic/Page/GeoFence'));
const VolumePage = lazy(() => import('./Traffic/Page/Volume'));
const CROPage = lazy(() => import('./Traffic/Page/CRO'));
const ProtectionRecoupPage = lazy(() => import('./Traffic/Page/ProtectionRecoup'));
const CPACConvertPage = lazy(() => import('./Traffic/Page/CPACConvert'));
const TalentAPIPage = lazy(() => import('./Talent/TalentAPI'));
const TargetAudiencesPage = lazy(() => import('./Talent/TargetAudiences'));
const AutoSourcerPage = lazy(() => import('./Talent/AutoSourcer'));
const LTVPage = lazy(() => import('./Talent/LTV'));
const PubFeedSettings = lazy(() => import('./Api/Page/PubFeedSettings'));
const PubApiKeyPage = lazy(() => import('./PubApiKey'));
const AffiliateWidgetPage = lazy(() => import('./Affiliate/Widget'));
const AffiliateWebsitesPage = lazy(() => import('./Affiliate/Websites'));

console.log('process.env.REACT_APP_TEST', process.env.REACT_APP_TEST);

const App: FC = () => {
  const { isLoading } = useAuth0();

  return (
    <UserProvider>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>
      <Layout>
        {
          isLoading && <Loading state={isLoading} />
        }
        <Suspense fallback={<Loading state />}>
          <Switch>
            <Route exact path={ROUTES.SIGN_IN} component={Auth0Page} />
            <ProtectedRoute exact path={ROUTES.DASHBOARD} component={DashboardPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_FEEDS} component={JobsFeedsPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_JOBS} component={JobsPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_LIVE_JOBS} component={LiveJobsPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_CAMPAIGNS} component={CampaignsPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_USER_CAMPAIGNS} component={CampaignsUserPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_MAP} component={CampaignsMapPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_IMPORT} component={ImportPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_EXPORT} component={ExportPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_SETTING_API} component={JobsSettingsPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_SETTING_API_MAIN} component={JobsSettingsPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_SETTING_API_PUB} component={JobsPubSettingsPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_SETTING_API_ADV} component={JobsAdvSettingsPage} />
            <ProtectedRoute exact path={ROUTES.IO_LIVE_JOBS} component={LiveJobsPage} />
            <ProtectedRoute exact path={ROUTES.IO_IMPORT} component={ImportPage} />
            <ProtectedRoute exact path={ROUTES.IO_EXPORT} component={ExportPage} />
            <ProtectedRoute exact path={ROUTES.IO_FEEDS_PREVIEW} component={FeedPreview} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_OVERVIEW} component={OverviewPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_MATRIX} component={MatrixPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_TRENDS} component={TrendsPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_REPORTS} component={ReportsPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_LOGS} component={LogsPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_RECONCILIATION} component={ReconciliationPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_RECONCILIATION_ADVERTISERS} component={ReconciliationPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_RECONCILIATION_PUBLISHERS} component={ReconciliationPage} />
            <ProtectedRoute exact path={ROUTES.PERFORMANCE_ALERTS} component={AlertsPage} />
            <ProtectedRoute exact path={ROUTES.TRAFFIC_ALL} component={TQMPage} />
            <ProtectedRoute exact path={ROUTES.SETTINGS_PROFILE} component={ProfilePage} />
            <ProtectedRoute exact path={ROUTES.SETTINGS_SETTINGS} component={SettingsPage} />
            <ProtectedRoute exact path={ROUTES.SETTINGS_SMART_CONTRACTS} component={SmartContractPage} />
            <ProtectedRoute exact path={ROUTES.SETTINGS_ALERTS} component={AlertsPage} />
            <ProtectedRoute exact path={ROUTES.SUPPORT_CONTACT} component={SupportPage} />
            <ProtectedRoute exact path={ROUTES.SUPPORT_TICKET} component={SupportPage} />
            <ProtectedRoute exact path={ROUTES.TRADE_MATRIX} component={MatrixPage} />
            <ProtectedRoute exact path={ROUTES.TRADE_BUY} component={ExportPage} />
            <ProtectedRoute exact path={ROUTES.TRADE_SELL} component={ImportPage} />
            <ProtectedRoute exact path={ROUTES.TRADE_LOGS} component={AlertsPage} />
            <ProtectedRoute exact path={ROUTES.TRAFFIC_INTENT} component={IntentPage} />
            <ProtectedRoute exact path={ROUTES.TRAFFIC_GEO_FENCE} component={GeoFencePage} />
            <ProtectedRoute exact path={ROUTES.TRAFFIC_VOLUME} component={VolumePage} />
            <ProtectedRoute exact path={ROUTES.TRAFFIC_CRO} component={CROPage} />
            <ProtectedRoute exact path={ROUTES.TRAFFIC_PROTECTION_RECOUP} component={ProtectionRecoupPage} />
            <ProtectedRoute exact path={ROUTES.TRAFFIC_CPAC_CONVERT} component={CPACConvertPage} />
            <ProtectedRoute exact path={ROUTES.TALENT_API} component={TalentAPIPage} />
            <ProtectedRoute exact path={ROUTES.TALENT_AUDIENCES} component={TargetAudiencesPage} />
            <ProtectedRoute exact path={ROUTES.TALENT_SOURCER} component={AutoSourcerPage} />
            <ProtectedRoute exact path={ROUTES.TALENT_LTV} component={LTVPage} />
            <ProtectedRoute exact path={ROUTES.API_PUB_SETTINGS_API} component={PubFeedSettings} />
            <ProtectedRoute exact path={ROUTES.ADV_IMPORT} component={ImportPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_IMPORT_DUPLICATES} component={DuplicatesPage} />
            <ProtectedRoute exact path={ROUTES.JOBS_IMPORT_BLOCKED} component={BlockedPage} />
            <ProtectedRoute exact path={ROUTES.ADV_LIVE_JOBS} component={LiveJobsPage} />
            <ProtectedRoute exact path={ROUTES.ADV_ADVERTISERS} component={AdvertisersPage} />
            <ProtectedRoute exact path={ROUTES.ADV_RECONCILIATION_ADVERTISERS} component={ReconciliationPage} />
            <ProtectedRoute exact path={ROUTES.ADV_LOGS} component={AlertsPage} />
            <ProtectedRoute exact path={ROUTES.PUB_EXPORT} component={ExportPage} />
            <ProtectedRoute exact path={ROUTES.PUB_SETTINGS_API} component={PubFeedSettings} />
            <ProtectedRoute exact path={ROUTES.PUB_PUBLISHERS} component={PublishersPage} />
            <ProtectedRoute exact path={ROUTES.PUB_RECONCILIATION_PUBLISHERS} component={ReconciliationPage} />
            <ProtectedRoute exact path={ROUTES.PUB_LOGS} component={AlertsPage} />
            <ProtectedRoute exact path={ROUTES.PUB_API_KEY} component={PubApiKeyPage} />
            <ProtectedRoute exact path={ROUTES.AFFILIATE_WIDGET} component={AffiliateWidgetPage} />
            <ProtectedRoute exact path={ROUTES.AFFILIATE_WEBSITES} component={AffiliateWebsitesPage} />
            <ProtectedRoute path="*" component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Layout>
    </UserProvider>
  );
};

export default App;
