import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: 600,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: '12px',
  },
  containerButton: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(30, 185, 128, 0.08)',
    },
    margin: '0 10px',
    cursor: 'pointer',
  },
  text: {
    color: '#1EB980',
    fontSize: '12px',
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 500,
    cursor: 'pointer',
    marginTop: '3px',
  },
  addTaskContainer: {
    display: 'block',
    border: '1px solid rgb(5, 173, 120)',
    padding: 16,
    margin: 16,
    '& > div > div': {
      gridGap: '20px',
      padding: '10px',
      margin: '10px 0',
    },
  },
  containerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '16px',
  },
  containerGridFull: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: '16px',
  },
  datePicker: {
    '& > div > div': {
      visibility: 'hidden',
      width: 0,
    },
  },
  openedAdd: {
    display: 'inline-table',
  },
  headerFilter: {
    margin: '0 10px',
  },
  selectComponent: {
    // width: '250px',
    width: 'auto',
  },
  selectComponentWithDate: {
    width: 'calc(100% - 40px)',
  },
  select: {
    width: '150px',
    marginRight: '10px',
  },
  active: {
    background: theme.palette.text.hint,
  },
}));

export default useStyles;
