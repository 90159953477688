import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Popup } from '@/ui';
import { PropsI } from './types';

const PopupRemoveRow: FC<PropsI> = ({
  isOpen, onClose, onSubmit, title,
}) => {
  async function handleSubmit() {
    const { success, message } = await onSubmit();

    if (!success) {
      console.error(message);
    } else {
      onClose();
    }
  }

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      actionsComponent={(
        <>
          <Button autoFocus onClick={onClose} color="primary">
            Disagree
          </Button>
          <Button autoFocus onClick={handleSubmit} color="secondary">
            Agree
          </Button>
        </>
      )}
    >
      <Typography gutterBottom>
        Are you sure you confirm the deletion of the row?
      </Typography>
    </Popup>
  );
};

export default PopupRemoveRow;
