import React, { FC, useContext, useMemo } from 'react';
import { ICatalogs, ICatalogsCampaigns, ICatalogsEvents } from '@/gql/types/dynamicServer';
import { useQuery } from '@apollo/react-hooks';
import { GET_CATALOGS, GET_CATALOGS_CAMPAIGNS, GET_CATALOGS_EVENTS } from '@/gql/query/common';
import get from 'lodash/get';
import { UserContext } from '@/providers/UserProvider';
import { GhostContext } from './GhostProvider';

interface ICatalogsContext {
  loading: boolean;
  catalogs: ICatalogs & ICatalogsEvents & ICatalogsCampaigns;
}

export const CatalogsContext = React.createContext<ICatalogsContext>({
  loading: false,
  catalogs: {
    camp: [{ }],
    group: [{ }],
    traders: [{ }],
    sites: [{ }],
    tqfInfo: [{ }],
    unpaidReasons: [{ }],
    mediumSrc: [{ }],
    feeds: [{ }],
    advertisers: [{ }],
    advertisersId: [{ }],
    publishers: [{ }],
    employers: [{ }],
    feedTypesJobs: [{ }],
    feedTypes: [{ }],
    feedQuality: [{ }],
    payType: [{ }],
    ipqsFraudScoreLimit: [{ }],
    importTypes: [{ }],
    feedExpired: [{ }],
    feedStatus: [{ }],
    action: [{ }],
    exportType: [{ }],
    expired: [{ }],
    partnerType: [{ }],
    excluded: [{ }],
    paid: [{ }],
    selectBooleanList: [{ }],
    filterByTracking: [{ }],
    typeAdv: [{ }],
    jobAdType: [{ }],
    schemaAdv: [{ }],
    rating: [{ }],
    contentType: [{ }],
    logsType: [{ }],
    grouping: [{ }],
    subgrouping: [{ }],
    advertisersEvents: [{ }],
    employersEvents: [{ }],
    publishersEvents: [{ }],
    actionEvents: [{ }],
    parentsCampaigns: [{ }],
    feedsCampaigns: [{ }],
    filtersCampaigns: [{ }],
    groupsCampaigns: [{ }],
    selectionsCampaigns: [{ }],
  },
});

const CatalogsProvider: FC = ({ children }) => {
  const { isGhostMode } = useContext(GhostContext);
  const { user } = useContext(UserContext);
  const { data, loading } = useQuery(GET_CATALOGS, { variables: { isGhost: isGhostMode, userEmail: user?.email } });
  const { data: dataEvents, loading: loadingEvents } = useQuery(GET_CATALOGS_EVENTS,
    { variables: { isGhost: isGhostMode } });
  const { data: dataCampaigns, loading: loadingCampaigns } = useQuery(
    GET_CATALOGS_CAMPAIGNS, { variables: { isGhost: isGhostMode } },
  );

  const catalogs = get(data, 'catalogs.payload') || {
    camp: [{ }],
    group: [{ }],
    traders: [{ }],
    sites: [{ }],
    tqfInfo: [{ }],
    unpaidReasons: [{ }],
    mediumSrc: [{ }],
    feeds: [{ }],
    advertisers: [{ }],
    advertisersId: [{ }],
    publishers: [{ }],
    employers: [{ }],
    feedTypesJobs: [{ }],
    feedTypes: [{ }],
    feedQuality: [{ }],
    feedStatus: [{ }],
    payType: [{ }],
    jatRating: [{ }],
    jatFilter: [{ }],
    groupByMatrix: [{ }],
    booleanSelect: [{ }],
    sales: [{ }],
    paidClicks: [{ }],
    cpaJobsPayout: [{ }],
    exportTypePub: [{ }],
    specifTrendsMatrix: [{ }],
    specifCompareTrendsMatrix: [{ }],
    billOff: [{ }],
    ipqsFraudScoreLimit: [{ }],
    importTypes: [{ }],
    feedExpired: [{ }],
    action: [{ }],
    exportType: [{ }],
    expired: [{ }],
    partnerType: [{ }],
    excluded: [{ }],
    paid: [{ }],
    selectBooleanList: [{ }],
    filterByTracking: [{ }],
    typeAdv: [{ }],
    jobAdType: [{ }],
    schemaAdv: [{ }],
    rating: [{ }],
    contentType: [{ }],
    logsType: [{ }],
    grouping: [{ }],
    subgrouping: [{ }],
  };

  const catalogEvents = get(dataEvents, 'catalogEvents.payload') || {
    advertisersEvents: [{ }],
    employersEvents: [{ }],
    publishersEvents: [{ }],
    actionEvents: [{ }],
  };

  const catalogCampaigns = get(dataCampaigns, 'catalogsCampaigns.payload') || {
    parentsCampaigns: [{ }],
    feedsCampaigns: [{ }],
    filtersCampaigns: [{ }],
    groupsCampaigns: [{ }],
    selectionsCampaigns: [{ }],
  };

  const memoValueCatalogs = useMemo(() => (
    {
      loading: loading || loadingEvents || loadingCampaigns,
      catalogs: {
        ...catalogs,
        ...catalogEvents,
        ...catalogCampaigns,
        advertisersReport: catalogs.advertisers,
        advertisers: catalogEvents.advertisersEvents,
      },
    }),
  [catalogCampaigns, catalogEvents, catalogs, loading, loadingCampaigns, loadingEvents]);

  return (
    <CatalogsContext.Provider value={memoValueCatalogs}>
      {children}
    </CatalogsContext.Provider>
  );
};

export default CatalogsProvider;
