import React, { useState, FC, createContext } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { STORAGE_KEY_THEME } from '@/constants/storage';
import { getItem } from '@/utils';
import { REALJOBS } from '@/constants/domains';
import getThemeByName from '../theme/base';
import { ThemeEnum } from './types';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ThemeContext = createContext({ themeName: '', setThemeName: (themeName: ThemeEnum): void => {} });

const ThemeProvider: FC = ({ children }) => {
  const themeByDomain = REALJOBS.includes(window.location.hostname) ? ThemeEnum.LIGHT : ThemeEnum.DARK;
  const [themeName, setThemeName] = useState(getItem(STORAGE_KEY_THEME) || themeByDomain);
  const theme = getThemeByName(themeName);

  return (
    <ThemeContext.Provider value={{ themeName, setThemeName }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
