import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  sidebarItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sidebarItemContent: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  sidebarItemIcon: {
    marginRight: '6px',
  },
  sidebarItemText: {
    width: '100%',
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  sidebarItemNotActive: {
    color: theme.palette.text.primary,
    opacity: 0.68,
  },
  sidebarItemLink: {
    textDecoration: 'none',
  },
  sidebarItemInactiveLinks: {
    color: 'rgb(255, 104, 89)',
  },
  sidebarItemActive: {
    color: '#1EB980',
  },
  sidebarItemExpandArrow: {
    fontSize: '1.2rem !important',
  },
  sidebarItemExpandArrowExpanded: {
    fontWeight: 'bold',
  },
  sidebarItemTextNone: {
    display: 'none',
  },
  containerListItem: {
    padding: theme.spacing(1),
  },
  containerList: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  containerPin: {
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
    zIndex: 1,
  },
  pinIcon: {
    opacity: 0.4,
    color: theme.palette.text.primary,
  },
  attach: {
    transform: 'rotate(90deg)',
    opacity: 0.87,
  },
}));

export default useStyles;
