import React, { FC } from 'react';
import useStyles from './styles';

interface IProps {
  value: number;
}

const LinearSimpleProgressUI: FC<IProps> = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.fillPercent} style={{ width: `${value}%` }} />
    </div>
  );
};

export default LinearSimpleProgressUI;
