import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  containerChart: {
    width: '100%',
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  dialog: {
    width: '80%',
    maxWidth: '80%',
    position: 'relative',
  },
  iconFullScreen: {
    position: 'absolute',
    left: '30px',
    zIndex: 1,
  },
  containerChartDialog: {
    width: '70%',
    margin: 'auto',
    position: 'relative',
  },
}));

export default useStyles;
