import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  innerLoading: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 1101,
  },
  transparentBg: {
    backgroundColor: 'transparent',
  },
}));

export default useStyles;
