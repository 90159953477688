import React, { FC, useContext, useState } from 'react';
import {
  useHistory, useLocation,
} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { Notification } from '@/ui';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  EDIT_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
} from '@/gql/query/notification';
import { Maybe, INotification, NotificationE } from '@/ui/Notification/types';
import { getRole } from '@/config/user';
import { useAuth0 } from '@auth0/auth0-react';
import { SIGN_IN, PERFORMANCE_TRENDS, PERFORMANCE_OVERVIEW } from '@/constants/routes';
import { REALJOBS } from '@/constants/domains';
import { UserContext } from '@/providers/UserProvider';
import { Header } from './Header';
import useStyles from './styles';
import { Sidebar } from './Sidebar';
import config from './config';
import { Navigation } from './Navigation';

const Layout: FC = ({ children }) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const [openM, setOpenM] = useState(true);
  const { data: cacheData } = useQuery(GET_NOTIFICATIONS);
  const [editNotification] = useMutation(EDIT_NOTIFICATION);
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);

  const { logout } = useAuth0();

  const handle = { route: handleRoute, signOut: handleSignOut };
  const menuItems = config(getRole(), open, pathname, handle, user?.email);

  return (
    <>
      {pathname !== SIGN_IN ? (
        <>
          <Header
            handleDrawer={handleDrawer}
            menuItems={menuItems}
          />
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(classes.drawerPaper, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <Sidebar
              items={menuItems}
              setOpen={setOpen}
              setOpenM={setOpenM}
              open={open}
              openM={openM}
              path={pathname}
            />
          </Drawer>
          <main className={clsx(classes.mainContent, { [classes.main]: !open, [classes.mainOpen]: open })}>
            {
              (pathname.includes('/trade') || pathname.includes('/traffic') || pathname.includes('/jobs') || pathname.includes('/io') || pathname.includes('/performance')) && (
                <Navigation className={clsx(
                  { [classes.navigation]: [PERFORMANCE_TRENDS, PERFORMANCE_OVERVIEW].includes(pathname) },
                )}
                />
              )
            }
            {children}
          </main>
          <footer className={classes.footer}>
            {
              cacheData?.getNotifications?.map((notification: Maybe<INotification>) => (
                <Notification
                  key={notification?.id}
                  isOpen={notification?.status}
                  onClose={handleCloseNotification(notification?.id)}
                  message={notification?.message}
                  type={notification?.type}
                  delay={notification?.type === NotificationE.Error ? 30000 : 3000}
                />
              ))
            }
            <p className={classes.containerFooterText}>
              {REALJOBS.includes(window.location.hostname) ? (
                <>
                  <span className={classes.footerText}>© 2024 Real jobs</span>
                  <span className={classes.footerText}>, LLC. All rights reserved.</span>
                </>
              ) : (
                <>
                  <span className={classes.footerText}>
                    © 2024 ptp.io, property of Capital Markets Placement. All rights reserved.
                  </span>
                </>
              )}
            </p>
          </footer>
        </>
      ) : (
        <main className={classes.mainAuth}>{ children }</main>
      )}
    </>
  );

  function handleCloseNotification(id: string | undefined) {
    return async () => {
      await editNotification({ variables: { id, status: false } });

      setTimeout(() => {
        deleteNotification({ variables: { id } });
      }, 1000);
    };
  }

  function handleDrawer() {
    setOpen(!open);
    setOpenM(!open);
  }

  function handleRoute(route: string) {
    return () => {
      history.push(route);
    };
  }

  async function handleSignOut() {
    logout({ returnTo: window.location.origin + SIGN_IN });
  }
};

export default Layout;
