import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    '& a': {
      borderRadius: '0px !important',
      border: 'none !important',
      backgroundColor: `${theme.palette.common.black} !important`,
      '& :nth-child(2), & :nth-child(3)': {
        display: 'none',
      },
      '& > *': {
        color: `${theme.palette.common.white} !important`,
      },
    },
  },
  datePickerXS: {
    '& a': {
      border: 'none !important',
      borderRadius: '0px !important',
      borderTopLeftRadius: '4px !important',
      borderTopRightRadius: '4px !important',
      fontSize: '13px !important',
      padding: '7px !important',
      backgroundColor: `${theme.palette.common.black} !important`,
      '& :nth-child(2), & :nth-child(3)': {
        display: 'none',
      },
      '& > *': {
        color: `${theme.palette.common.white} !important`,
      },
    },
  },
  datePickerMD: {
    width: '100%',
    '& a': {
      border: 'none !important',
      borderRadius: '0px !important',
      borderTopLeftRadius: '4px !important',
      borderTopRightRadius: '4px !important',
      fontSize: '14px !important',
      padding: '8px !important',
      backgroundColor: `${theme.palette.common.black} !important`,
      '& :nth-child(2), & :nth-child(3)': {
        display: 'none',
      },
      '& > *': {
        color: `${theme.palette.common.white} !important`,
      },
    },
  },
  containerDatePicker: {
    display: 'inline-block',
    position: 'relative',
  },
  iconDatePicker: {
    position: 'absolute',
    right: '4px',
    zIndex: 5,
    top: '5px',
  },
  iconDatePickerXS: {
    display: 'none',
  },
  iconMD: {
    width: 20,
    height: 20,
  },
  '@global': {
    '.rs-picker-menu': {
      backgroundColor: theme.palette.info.dark,
    },
    '.rs-calendar': {
      backgroundColor: theme.palette.info.dark,
    },
    '.rs-picker-toolbar': {
      borderTop: `1px solid ${theme.palette.success.contrastText}`,
    },
    '.rs-picker-daterange-menu .rs-calendar:first-child': {
      borderRight: `1px solid ${theme.palette.success.contrastText}`,
    },
    '.rs-picker-daterange-header': {
      borderBottom: `1px solid ${theme.palette.success.contrastText}`,
    },
    '.rs-picker-toolbar-option:not(:last-child)::before': {
      backgroundColor: theme.palette.success.contrastText,
    },
    '.rs-picker-default.rs-picker-disabled .rs-picker-toggle, .rs-picker-input.rs-picker-disabled .rs-picker-toggle, .rs-picker-default.rs-picker-disabled .rs-btn, .rs-picker-input.rs-picker-disabled .rs-btn': {
      backgroundColor: `${theme.palette.common.black} !important`,
    },
  },
}));

export default useStyles;
